import clsx from 'clsx';
import { useStore } from 'effector-react';
import { ChangeEvent, MouseEvent, useState } from 'react';
import { SlidePage } from 'src/components/base/App/App';
import { ScreenRollHandler } from 'src/components/base/ScreenRoller/ScreenRollHandler';
import { $menuActiveStore, setMenuActive } from 'src/store/menu';
import styles from './MenuOverlay.module.scss';
import { FormStatus } from 'src/components/screens/OutroScreen/OutroScreen';
import { Emails } from 'src/rest/Emails';

export function MenuOverlay() {
  const active = useStore($menuActiveStore);
  const [inputValue, setInputValue] = useState('');
  const [formStatus, setFormStatus] = useState<FormStatus>(FormStatus.Waiting);

  const inputOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (formStatus === FormStatus.Error) {
      setFormStatus(FormStatus.Waiting);
    }
    setInputValue(e.target.value);
  };
  const onSubmit = () => {
    if (inputValue.length > 0) {
      if (inputValue.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
        Emails.addNewMember(inputValue).then(() => {
          setFormStatus(FormStatus.Complete);
        });
      } else {
        setFormStatus(FormStatus.Error);
      }
    } else {
      setFormStatus(FormStatus.Error);
    }
  };

  const goToDiscord = (e: MouseEvent) => {
    setMenuActive({
      isOpen: false,
      type: 'menu'
    });
    e.preventDefault();
    e.stopPropagation();
    ScreenRollHandler.navigateTo(SlidePage.Outro);
  };

  return (
    <div className={clsx(styles.menu, !active.isOpen && styles.hidden)}>
      <div className={styles.menu__close}>
        <a
          href="#"
          onClick={(ev) => {
            ev.preventDefault();
            ev.stopPropagation();
            if (active.type === 'coming')
              setMenuActive({
                isOpen: false,
                type: 'coming'
              });
            else
              setMenuActive({
                isOpen: false,
                type: 'menu'
              });
          }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 40 40">
            <path
              fill="#fff"
              d="m13.6348 11.5146 14.14213562 14.14213562-1.41421356 1.41421357-14.14213562-14.14213563z"
            />
            <path
              fill="#fff"
              d="m12.2207 25.6567 14.14213562-14.14213562 1.41421357 1.41421356-14.14213563 14.14213562z"
            />
          </svg>
        </a>
      </div>
      {active.type === 'coming' && (
        <div className={styles.coming}>
          <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M10.9049 16.6318H23.9225C23.9225 16.6318 24.1434 17.9336 24.1434 19.8003C24.1434 21.667 23.9225 23.2634 23.9225 23.2634L22.4487 23.2634V17.3691H19.2557V23.2634L10.831 23.2634C10.831 23.2634 10.684 21.6668 10.684 19.8002C10.684 17.9335 10.9049 16.6318 10.9049 16.6318ZM17.5364 17.3691H12.6242V20.0709H17.5364V17.3691Z"
              fill="white"
            />
            <path
              d="M11.5456 10.123L11.518 10.1729C11.478 10.2455 11.4209 10.3509 11.3522 10.4819C11.2149 10.744 11.0318 11.108 10.8489 11.5155C10.6659 11.9234 10.4837 12.3732 10.3475 12.8073C10.2107 13.2432 10.1228 13.6554 10.1228 13.9903C10.1228 15.0741 10.4602 15.7852 10.6009 16.0347C10.6162 16.0618 10.6474 16.0811 10.6882 16.0811H13.0625C13.1188 16.0811 13.1679 16.0428 13.1816 15.9881L13.6272 14.2058L13.8654 14.2054L14.3167 15.9885C14.3305 16.043 14.3796 16.0811 14.4358 16.0811H16.7467C16.803 16.0811 16.8522 16.0428 16.8658 15.9881L17.3114 14.2058L17.5496 14.2054L18.001 15.9885C18.0148 16.043 18.0638 16.0811 18.12 16.0811H20.4309C20.4872 16.0811 20.5364 16.0428 20.55 15.9881L20.9956 14.2058L21.2338 14.2054L21.6852 15.9885C21.699 16.043 21.748 16.0811 21.8042 16.0811H24.1781C24.2192 16.0811 24.2507 16.0615 24.2659 16.0342C24.4053 15.7835 24.7377 15.0727 24.7377 13.9896C24.7377 13.6545 24.6498 13.2423 24.513 12.8065C24.3768 12.3723 24.1946 11.9226 24.0116 11.5149C23.8287 11.1075 23.6456 10.7437 23.5083 10.4817C23.4396 10.3508 23.3825 10.2454 23.3425 10.1729L23.3149 10.123H11.5456Z"
              fill="white"
            />
            <circle opacity="0.4" cx="17" cy="17" r="16.5" stroke="white" />
          </svg>

          <h2 className={styles.coming__text}>coming soon</h2>
        </div>
      )}
      {active.type === 'menu' && (
        <>
          {/*  {SlideIndexes.map((item) => {*/}
          {/*  const idx = item.index;*/}
          {/*  const onClick = (event: MouseEvent) => {*/}
          {/*    event.preventDefault();*/}
          {/*    setMenuActive({*/}
          {/*      isOpen: false,*/}
          {/*      type: 'menu'*/}
          {/*    });*/}
          {/*    setTimeout(() => {*/}
          {/*      ScreenRollHandler.navigateTo(idx);*/}
          {/*    }, 300);*/}
          {/*  };*/}
          {/*  return (*/}
          {/*      <a href="#" key={item.name} onClick={onClick}>*/}
          {/*        {item.name}*/}
          {/*      </a>*/}
          {/*  );*/}
          {/*})}*/}
          <a href="#" className={styles.social} onClick={goToDiscord} target="_blank">
            Discord
          </a>
          <a href="https://twitter.com/starfallarena" className={styles.social} target="_blank">
            Twitter
          </a>
        </>
      )}
      {active.type === 'form' && (
        <div className={styles.email}>
          {formStatus === FormStatus.Complete ? (
            <label>Thanks for submitting the form!</label>
          ) : (
            <>
              <label>SUBMIT YOUR EMAIL ADDRESS TO BE NOTIFIED OF UPDATES:</label>
              <div className={styles.email_form}>
                <input type="email" placeholder={'Your email'} value={inputValue} onChange={inputOnChange} />
                <button onClick={onSubmit}>Submit</button>
              </div>
              <div className={clsx(styles.email_error, formStatus === FormStatus.Error && styles.active)}>
                {inputValue.length > 0 ? 'Please enter a correct email address' : 'This field is required'}
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}
