import { createEvent, createStore, forward } from 'effector';
import { SoundSystem } from 'src/graphics/sound/SoundSystem';

const $soundActiveStore = createStore<boolean>(false);
const $soundSelectedStore = createStore<boolean>(false);

const setSoundEnabled = createEvent<boolean>();
forward({
  from: setSoundEnabled,
  to: $soundActiveStore
});
$soundActiveStore.on(setSoundEnabled, (_, value) => {
  SoundSystem.setBackgroundActive(value);
  return value;
});
$soundSelectedStore.on(setSoundEnabled, () => true);

export {
  $soundActiveStore, //
  $soundSelectedStore,
  setSoundEnabled
};
