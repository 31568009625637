import { BigNumber, ethers } from "ethers";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import styles from './BidInput.module.scss';

interface BidInputProps {
  value: string | null;
  onChange: (value: string | null) => void;
}

export const BidInput = ({ value, onChange, ...rest }: BidInputProps) => {
  const [state, setState] = useState('');
  const blockRef = useRef<HTMLDivElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);

  const encodeInput = (input: string) => {
    let t = input.trim();
    if (t.endsWith('.')) {
      t = t.substring(0, t.length - 1);
    }
    if (t === '' || t === '0') {
      return null;
    }
    const v = ethers.utils.parseEther(t);
    if (v.gt(0)) {
      return v.toString();
    }
    return null;
  }

  const decodeInput = (input: string | null) => {
    if (input !== null) {
      let val = ethers.utils.formatEther(BigNumber.from(input));
      if (val.endsWith('.0')) {
        val = val.substring(0, val.length - 2);
      }
      if (val === '0') {
        return null;
      }
      return val;
    }
    return null;
  }

  useEffect(() => {
    if (value !== encodeInput(state)) {
      setState(decodeInput(value) ?? '');
    }
  }, [value])

  const handleChange = (ev: ChangeEvent) => {
    const val = (ev.target as HTMLInputElement).value;
    const regex = /^(0|[1-9][0-9]*)(\.[0-9]*)?$/gim;
    if (val.match(regex) || val === '') {
      setState(val);
      onChange(encodeInput(val))
    }
  }

  const handleBlur = () => {
    const out = encodeInput(state);
    setState(decodeInput(out) ?? '');
    onChange(out?.toString() ?? null);
  }

  return <div className={styles.block} ref={blockRef}>
    <input ref={inputRef} type="text" inputMode="decimal" pattern="\d+(\.\d*)?" maxLength={20} value={state} onChange={handleChange} onBlur={handleBlur} placeholder={'0'} {...rest}/>
    <div className={styles.block__overlay}>
      <svg className={styles.icon} viewBox="0 0 19 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 15.0055L9.1875 0L18.375 15.0055L9.1875 20.3578L0 15.0055Z" fill="white"/>
        <path d="M9.18754 22.8772L18.0808 17.356L9.18754 30L0.0844727 17.356L9.18754 22.8772Z" fill="white"/>
      </svg>
      <span className={styles.spacer}>{state.length ? state : '0'}</span>
    </div>
  </div>;
};
