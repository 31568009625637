import clsx from 'clsx';
import { SlidePage } from 'src/components/base/App/App';
import { useScrollState } from 'src/hooks/common/useScrollState';
import { ScreenSize, useViewport } from 'src/hooks/common/useViewport';
import styles from './NavProgress.module.scss';

export function NavProgress() {
  const { offset, maxOffset, page } = useScrollState();
  const screen = useViewport();

  const strokeValue = offset / maxOffset;
  const strokeLength = 49 * 2 * Math.PI;
  const percent = Math.round(strokeValue * 100.0);

  return (
    <div className={clsx(styles.progress, offset > 0 && styles.right, page === SlidePage.Outro && styles.hidden)}>
      <div className={clsx(styles.progress__hint, percent > 0 && styles.hidden)}>
        {screen === ScreenSize.Desktop ? (
          <>
            scroll <br />
            down
          </>
        ) : (
          <svg viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.4">
              <path d="M24.75 19V32.5" stroke="white" strokeLinecap="square" strokeLinejoin="round" />
              <path d="M19 26.75L24.75 32.5L30.5 26.75" stroke="white" strokeLinecap="square" strokeLinejoin="round" />
            </g>
          </svg>
        )}
      </div>
      <div className={clsx(styles.progress__info, percent === 0 && styles.hidden)}>
        {screen === ScreenSize.Desktop && (
          <>
            progress <br />
          </>
        )}
        <span>{percent}%</span>
      </div>

      <svg className="inner" viewBox="0 0 100 100" fill="none">
        <g>
          <circle cx="50" cy="50" r="49" stroke="#fff" opacity="0.4" />
        </g>
        <g>
          <circle
            cx="50"
            cy="50"
            r="49"
            stroke="#FFF"
            strokeDasharray={`${strokeLength * strokeValue} ${strokeLength}`}
          />
        </g>
      </svg>
    </div>
  );
}
