import Left1 from 'src/assets/split/left_1.jpg';
import Left2 from 'src/assets/split/left_2.jpg';
import Left3 from 'src/assets/split/left_3.jpg';
import Left4 from 'src/assets/split/left_4.jpg';
import Right1 from 'src/assets/split/right_1.jpg';
import Right2 from 'src/assets/split/right_2.jpg';
import Right3 from 'src/assets/split/right_3.jpg';
import Right4 from 'src/assets/split/right_4.jpg';

export const LEFT_IMAGES = [Left1, Left2, Left3, Left4];
export const RIGHT_IMAGES = [Right1, Right2, Right3, Right4];
