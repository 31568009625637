import clsx from "clsx";
import { useMemo } from "react";
import { AuctionSideVector } from "../../visual/AuctionSideVector/AuctionSideVector";
import styles from './AuctionLeftPanel.module.scss'
import { ActiveBidScreen } from "./screens/ActiveBidScreen";
import { LogInScreen } from "./screens/LogInScreen";
import { PlaceBidScreen } from "./screens/PlaceBidScreen";
import { ResultScreen } from "./screens/ResultScreen";

enum ScreenType {
  LogIn,
  SubmitBid,
  ActiveBid,
  Result,
}

export function AuctionLeftPanel() {
  const screen = useMemo<ScreenType>(() => (
    ScreenType.SubmitBid // TODO: Detect screen for current state
  ), []);


  return <div className={styles.panel}>
    <div className={styles.panel__vector}>
      <AuctionSideVector />
    </div>
    <div className={clsx(styles.panel__vector, styles.right)}>
      <AuctionSideVector />
    </div>

    { screen === ScreenType.LogIn && <LogInScreen />}
    { screen === ScreenType.SubmitBid && <PlaceBidScreen /> }
    { screen === ScreenType.ActiveBid && <ActiveBidScreen /> }
    { screen === ScreenType.Result && <ResultScreen /> }


  </div>
}
