import { easeInOutQuad, easeOutCubic, timeSlice } from 'src/graphics/helpers/Easings';
import PlaneFragCode from 'src/graphics/shaders/shard_cloud.frag.glsl';
import PlaneVertCode from 'src/graphics/shaders/shard_cloud.vert.glsl';
import LineFragCode from 'src/graphics/shaders/shard_cloud_line.frag.glsl';
import LineVertCode from 'src/graphics/shaders/shard_cloud_line.vert.glsl';
import { ShardsTexture } from 'src/resources/ShardsTexture';
import { ShardsVideoTexture } from 'src/resources/ShardsVideoTexture';
import {
  BufferGeometry,
  DoubleSide,
  Euler,
  Float32BufferAttribute,
  LineSegments,
  MathUtils,
  Matrix4,
  Mesh,
  Quaternion,
  ShaderMaterial,
  Texture,
  Uint32BufferAttribute,
  Vector2,
  Vector3
} from 'three';

interface Shard {
  position: Vector3;
  rotation: Quaternion;
  size: number;
  time: number;
  textureIndex: number;
  textureType: ShardTexture;
  points: Vector2[];
  state: number;
  enterType?: ShardEnterType;
  displaceValue: number;
  primary?: boolean;
}

interface ShardFragState {
  matrix: Matrix4;
  opacity: number;
  texture: number;
  stroke: number;
  strokeOpacity: number;
}

export enum ShardTexture {
  None,
  Atlas,
  Video
}

export enum ShardEnterType {
  Outline,
  Displace
}

/**
 * Shard cloud renderer
 */
export class ShardCloud {
  /**
   * All shard instances
   * @type {Shard[]}
   * @private
   */
  private readonly shards: Shard[];

  /**
   * Uniform-bound instance values
   * @type {ShardFragState[]}
   * @private
   */
  private readonly state: ShardFragState[];

  /**
   * Planes container
   * @type {Mesh | null}
   * @private
   */
  private mesh: Mesh | null = null;

  /**
   * Planes shader material
   * @type {ShaderMaterial | null}
   * @private
   */
  private material: ShaderMaterial | null = null;

  /**
   * Planes geometry buffer
   * @type {BufferGeometry | null}
   * @private
   */
  private geometry: BufferGeometry | null = null;

  /**
   * Outlines container
   * @type {LineSegments | null}
   * @private
   */
  private outlineMesh: LineSegments | null = null;

  /**
   * Outlines shader material
   * @type {ShaderMaterial | null}
   * @private
   */
  private outlineMaterial: ShaderMaterial | null = null;

  /**
   * Outlines geometry buffer
   * @type {BufferGeometry | null}
   * @private
   */
  private outlineGeometry: BufferGeometry | null = null;

  /**
   * Constructor
   */
  public constructor() {
    this.shards = [];
    this.state = [];
  }

  /**
   * Add new shard instance
   * @param {Vector3} position
   * @param {Quaternion} rotation
   * @param {number} scale
   * @param {ShardTexture} texture
   * @param {number} textureIndex
   * @param {ShardEnterType} enterType
   * @param {boolean} primary
   * @returns {number}
   */
  public addShard(
    position: Vector3,
    rotation: Quaternion,
    scale: number,
    texture: ShardTexture,
    textureIndex: number = 0,
    enterType: ShardEnterType = ShardEnterType.Displace,
    primary: boolean = false
  ) {
    const id = this.shards.length;
    this.shards.push({
      position,
      rotation,
      size: scale,
      time: Math.random() * Math.PI * 2,
      state: 0,
      textureIndex,
      textureType: texture,
      points: this.generateShardPoints(),
      enterType,
      displaceValue: 0,
      primary
    });
    return id;
  }

  /**
   * Prepare all geometries and shaders for rendering
   */
  public compose() {
    for (let i = 0; i < this.shards.length; i++) {
      this.state.push({
        matrix: new Matrix4().identity(),
        opacity: 0,
        texture: this.shards[i].textureType,
        stroke: 0,
        strokeOpacity: 0
      });
    }

    // Making opaque meshes
    this.material = new ShaderMaterial({
      fragmentShader: PlaneFragCode,
      vertexShader: PlaneVertCode,
      side: DoubleSide,
      depthWrite: false,
      depthTest: true,
      transparent: true,
      defines: {
        TOTAL_SHARDS: this.shards.length
      },
      uniforms: {
        state: { value: this.getPlanesUniforms() },
        backgroundMap: {
          value: 0
        },
        textureMap: {
          value: 0
        },
        videoMap: {
          value: 0
        }
      }
    });
    this.geometry = this.buildPlanesGeometry();
    this.mesh = new Mesh(this.geometry, this.material);
    this.mesh.frustumCulled = false;

    // Making line stroke mesh
    this.outlineMaterial = new ShaderMaterial({
      fragmentShader: LineFragCode,
      vertexShader: LineVertCode,
      depthWrite: false,
      depthTest: false,
      transparent: true,
      defines: {
        TOTAL_SHARDS: this.shards.length
      },
      uniforms: {
        state: { value: this.getLinesUniforms() }
      }
    });
    this.outlineGeometry = this.buildOutlineGeometry();
    this.outlineMesh = new LineSegments(this.outlineGeometry, this.outlineMaterial);
    this.mesh.add(this.outlineMesh);
  }

  /**
   * Generate plane geometry buffer
   * @returns {BufferGeometry}
   * @private
   */
  private buildPlanesGeometry() {
    const positions: number[] = [];
    const normals: number[] = [];
    const uv: number[] = [];
    const index: number[] = [];

    for (let n = 0; n < this.shards.length; n++) {
      const shard = this.shards[n];

      // Making positions and calculating bounds
      let minX = Infinity;
      let maxX = -Infinity;
      let minY = Infinity;
      let maxY = -Infinity;
      for (let i = 0; i < 4; i++) {
        const v = shard.points[i];
        const vn = new Vector3(v.x * 0.2, v.y * 0.2, 1).normalize();
        positions.push(v.x, v.y, 0);
        normals.push(vn.x, vn.y, vn.z);
        index.push(n);
        minX = Math.min(minX, v.x);
        minY = Math.min(minY, v.y);
        maxX = Math.max(maxX, v.x);
        maxY = Math.max(maxY, v.y);
      }

      // Compute UV offsets in atlas
      const texOff = new Vector2();
      const texScale = new Vector2();
      if (shard.textureType === ShardTexture.Atlas) {
        const offsets = ShardsTexture.getOffset(shard.textureIndex);
        texOff.copy(offsets[0]);
        texScale.copy(offsets[1]);
      } else if (shard.textureType === ShardTexture.Video) {
        const offsets = ShardsVideoTexture.getOffset(shard.textureIndex);
        texOff.copy(offsets[0]);
        texScale.copy(offsets[1]);
      }

      // Calculating UV from positions
      const uvSize = Math.max(maxX - minX, maxY - minY);
      const uvX = minX + (maxX - minX) * 0.5 - uvSize * 0.5;
      const uvY = minY + (maxY - minY) * 0.5 - uvSize * 0.5;
      for (let i = 0; i < 4; i++) {
        const v = shard.points[i];
        uv.push(
          ((v.x - uvX) / uvSize) * texScale.x + texOff.x, //
          (1.0 - (v.y - uvY) / uvSize) * texScale.y + texOff.y
        );
      }
    }

    // Building geometry buffer
    const geom = new BufferGeometry();
    geom.setAttribute('position', new Float32BufferAttribute(positions, 3, false));
    geom.setAttribute('normal', new Float32BufferAttribute(normals, 3, true));
    geom.setAttribute('uv', new Float32BufferAttribute(uv, 2, false));
    geom.setAttribute('index', new Uint32BufferAttribute(index, 1, false));
    return geom;
  }

  /**
   * Build geometry for appear lines
   * @returns {BufferGeometry}
   * @private
   */
  private buildOutlineGeometry() {
    const positions: number[] = [];
    const index: number[] = [];
    const gamma: number[] = [];
    const indices = [0, 1, 3, 2, 4];

    for (let n = 0; n < this.shards.length; n++) {
      const shard = this.shards[n];

      // Making positions and calculating bounds
      let pos = 0;
      for (const i of indices) {
        const v = shard.points[i % 4];
        positions.push(v.x, v.y, 0);
        index.push(n);
        gamma.push(pos / 4);
        pos++;
      }
    }

    // Building geometry buffer
    const geom = new BufferGeometry();
    geom.setAttribute('position', new Float32BufferAttribute(positions, 3, false));
    geom.setAttribute('gamma', new Float32BufferAttribute(gamma, 1, false));
    geom.setAttribute('index', new Uint32BufferAttribute(index, 1, false));
    return geom;
  }

  /**
   * Mesh getter
   * @returns {Mesh}
   */
  public get entity() {
    return this.mesh!;
  }

  /**
   * Update single shard position/rotation
   * @param {number} index
   * @param {Vector3} position
   * @param {Quaternion} rotation
   */
  public updateShardPosition(index: number, position: Vector3, rotation?: Quaternion) {
    const shard = this.shards[index];
    if (shard) {
      shard.position = position;
      if (rotation) {
        shard.rotation = rotation;
      }
    }
  }

  /**
   * Update shard state (0 - start of appear, 1 - visible, 2 - end of disappear)
   * @param {number} index
   * @param {number} value
   */
  public updateShardState(index: number, value: number) {
    const time = MathUtils.clamp(value, 0, 2);
    const shard = this.shards[index];
    const state = this.state[index];
    if (shard && state) {
      if (time >= 1) {
        const d = timeSlice(value, 1, 2);
        state.strokeOpacity = 0;
        state.stroke = 0;
        state.opacity = 1.0 - easeOutCubic(d);
        shard.displaceValue = easeInOutQuad(timeSlice(d, 0, 0.1));
      } else {
        const d = timeSlice(value, 0, 1);
        switch (shard.enterType) {
          case ShardEnterType.Outline:
            state.strokeOpacity = timeSlice(d, 0.7, 0.5);
            state.stroke = timeSlice(d, 0, 0.5);
            state.opacity = easeOutCubic(timeSlice(d, 0.5, 1));
            break;
          case ShardEnterType.Displace:
            state.stroke = 0;
            state.strokeOpacity = 0;
            state.opacity = d;
            shard.displaceValue = 1.0 - easeOutCubic(d);
            break;
        }
      }
    }
  }

  /**
   * Update renderer logic
   * @param {Vector3} cameraPosition
   * @param {number} delta
   */
  public update(cameraPosition: Vector3, delta: number) {
    // Updating shard logic
    for (const shard of this.shards) {
      shard.time = (shard.time + 0.007 * delta) % (Math.PI * 2);
    }

    // Sorting indices and lines
    this.sortByDistance(cameraPosition);
    this.updateLines();

    // Updating matrices
    for (let i = 0; i < this.state.length; i++) {
      const shard = this.shards[i];
      const state = this.state[i];

      const position = shard.position.clone();
      const rotation = new Quaternion()
        .setFromEuler(new Euler(Math.sin(shard.time * 2) * 0.05, Math.cos(shard.time) * 0.05, 0))
        .multiply(shard.rotation);
      const scale = shard.size;
      if (shard.displaceValue > 0) {
        position.add(
          position
            .clone()
            .setZ(0)
            .normalize()
            .multiplyScalar(30 * shard.displaceValue)
        );
      }

      state.matrix = new Matrix4().compose(position, rotation, new Vector3().setScalar(scale));
    }

    // Updating uniforms
    if (this.material) {
      this.material.uniforms.state.value = this.getPlanesUniforms();
    }
    if (this.outlineMaterial) {
      this.outlineMaterial.uniforms.state.value = this.getLinesUniforms();
    }
  }

  /**
   * Update texture bindings
   * @param {Texture} background
   * @param {Texture} atlas
   * @param {Texture} videos
   */
  public updateTextures(background: Texture, atlas: Texture, videos: Texture) {
    if (this.material) {
      this.material.uniforms.backgroundMap.value = background;
      this.material.uniforms.textureMap.value = atlas;
      this.material.uniforms.videoMap.value = videos;
    }
  }

  /**
   * Make shard quad points
   * @returns {Vector2[]}
   * @private
   */
  private generateShardPoints() {
    return [
      new Vector2(-0.5, 0.5), //
      new Vector2(0.5, 0.5),
      new Vector2(-0.5, -0.5),
      new Vector2(0.5, -0.5)
    ].map((v) => v.add(new Vector2().random().subScalar(0.5).multiplyScalar(0.3)));
  }

  /**
   * Sorting index array by distance
   * @param {Vector3} camera
   * @private
   */
  private sortByDistance(camera: Vector3) {
    const list = this.shards
      .map((shard, index) => {
        return {
          offset: index * 4,
          distance: shard.position.clone().sub(camera).lengthSq() + (shard.primary ? -10000 : 0)
        };
      })
      .sort((a, b) => b.distance - a.distance);
    if (this.geometry) {
      const index: number[] = [];
      for (const entry of list) {
        const o = entry.offset;
        index.push(o, o + 1, o + 2, o + 1, o + 3, o + 2);
      }
      this.geometry.setIndex(index);
    }
  }

  /**
   * Update lines index buffers
   * @private
   */
  private updateLines() {
    const list = this.shards
      .map((shard, index) => {
        return {
          offset: index * 5,
          stroke: this.state[index].stroke,
          opacity: this.state[index].strokeOpacity
        };
      })
      .filter((en) => en.stroke > 0 && en.opacity > 0);
    if (this.outlineGeometry) {
      const index: number[] = [];
      for (const en of list) {
        for (let i = 0; i < 4; i++) {
          const d = i / 4;
          if (en.stroke >= d) {
            index.push(en.offset + i, en.offset + i + 1);
          }
        }
      }
      this.outlineGeometry.setIndex(index);
    }
  }

  /**
   * Get uniforms for plane pass
   * @returns {{texture: number, matrix: Matrix4, opacity: number}[]}
   * @private
   */
  private getPlanesUniforms() {
    return this.state.map((state) => {
      return {
        matrix: state.matrix,
        texture: state.texture,
        opacity: state.opacity
      };
    });
  }

  /**
   * Get uniforms for outlines pass
   * @returns {{matrix: Matrix4, stroke: number, strokeOpacity: number}[]}
   * @private
   */
  private getLinesUniforms() {
    return this.state.map((state) => {
      return {
        matrix: state.matrix,
        stroke: state.stroke,
        strokeOpacity: state.strokeOpacity
      };
    });
  }
}
