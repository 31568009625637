import clsx from "clsx";
import { useStore } from "effector-react";
import { MouseEvent, ReactNode, useRef } from "react";
import CoinbaseIcon from '../../../assets/vector/coinbase.svg'
import MetamaskIcon from '../../../assets/vector/metamask.svg'
import NoWalletIcon from '../../../assets/vector/no_wallet.svg'
import OtherWalletIcon from '../../../assets/vector/other_wallets.svg'
import { $modalActiveStore, setModalState } from "../../../store/auction/modal";
import styles from './WalletModal.module.scss';

interface ButtonProps {
  title: string,
  children: ReactNode,
  gray?: boolean,
  onClick: () => void
}

const Button = ({ title, children, onClick, gray }: ButtonProps) => {
  return <button className={clsx(styles.button, gray && styles.gray)} onClick={onClick}>
    <span>{title}</span>
    {children}
  </button>
}

export const WalletModal = () => {
  const active = useStore($modalActiveStore);
  const wrapRef = useRef<HTMLDivElement>(null);

  const handleClick = (ev: MouseEvent) => {
    if (ev.target === wrapRef.current) {
      setModalState(false);
    }
  }

  const close = () => {
    setModalState(false);
  }

  const connectMetamask = () => {
    // TODO
  }

  const connectCoinbase = () => {
    // TODO
  }

  const connectOther = () => {
    // TODO
  }

  const noWallet = () => {
    // TODO
  }

  return <div className={clsx(styles.wrapper, active && styles.active)} ref={wrapRef} onClick={handleClick}>
    <div className={styles.modal}>
      <h3>Connect wallet</h3>
      <Button title={'MetaMask'} onClick={connectMetamask}><MetamaskIcon /></Button>
      <Button title={'Coinbase Wallet'} onClick={connectCoinbase}><CoinbaseIcon /></Button>
      <Button title={'Other Wallets'} onClick={connectOther}><OtherWalletIcon /></Button>
      <Button title={'I don’t have a wallet'} onClick={noWallet} gray><NoWalletIcon /></Button>

      <button className={styles.modal__corner}>
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13.9995 26.4799C20.892 26.4799 26.4795 20.8924 26.4795 13.9999C26.4795 7.10738 20.892 1.5199 13.9995 1.5199C7.10702 1.5199 1.51953 7.10738 1.51953 13.9999C1.51953 20.8924 7.10702 26.4799 13.9995 26.4799Z" stroke="white" strokeOpacity="0.3" strokeWidth="2" strokeMiterlimit="10" strokeLinecap="round"/>
          <path d="M13.238 16.8115V16.0471C13.238 15.3004 13.4054 14.9093 13.797 14.368L15.0471 12.652C15.6992 11.7571 15.9228 11.3281 15.9228 10.6937C15.9228 9.63083 15.1771 8.95951 14.1137 8.95951C13.0695 8.95951 12.3982 9.59339 12.1746 10.7311C12.1559 10.8429 12.0815 10.8991 11.9692 10.8804L10.7945 10.675C10.6822 10.6563 10.6266 10.5819 10.6448 10.4696C10.925 8.67923 12.212 7.50403 14.1516 7.50403C16.1651 7.50403 17.5264 8.82795 17.5264 10.6745C17.5264 11.5699 17.2103 12.2225 16.5759 13.0992L15.3263 14.8152C14.9535 15.3186 14.8412 15.5796 14.8412 16.2135V16.8099C14.8412 16.9217 14.7668 16.9961 14.655 16.9961H13.4242C13.3124 16.9982 13.238 16.9233 13.238 16.8115ZM13.1449 18.6024C13.1449 18.4906 13.2193 18.4162 13.3316 18.4162H14.7491C14.8609 18.4162 14.9353 18.4911 14.9353 18.6024V20.2061C14.9353 20.3184 14.8604 20.3922 14.7491 20.3922H13.3316C13.2188 20.3922 13.1449 20.3179 13.1449 20.2061V18.6024Z" fill="white" fillOpacity="0.3"/>
        </svg>
        <div className={styles.hint}>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.
        </div>
      </button>
      <button className={clsx(styles.modal__corner, styles.right)} onClick={close}>
        <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <line x1="1.29289" y1="19.2635" x2="18.9706" y2="1.58581" stroke="white" strokeWidth="2"/>
          <line x1="1.70711" y1="1.29289" x2="19.3848" y2="18.9706" stroke="white" strokeWidth="2"/>
        </svg>
      </button>
    </div>
  </div>;
};
