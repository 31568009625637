import { AuctionRightPanel } from "src/components/auction/AuctionRightPanel/AuctionRightPanel";
import { AuctionHeader } from "../../auction/AuctionHeader/AuctionHeader";
import { AuctionLeftPanel } from "../../auction/AuctionLeftPanel/AuctionLeftPanel";
import { WalletModal } from "../../auction/WalletModal/WalletModal";
import { AuctionGraphics } from "../../visual/AuctionGraphics/AuctionGraphics";
import styles from './Auction.module.scss';

export function Auction() {
  return <>
    <AuctionGraphics />
    <div className={styles.page}>
      <AuctionHeader />
      <div className={styles.page__wrapper}>
        <div className={styles.column}><AuctionLeftPanel /></div>
        <div className={styles.column}><AuctionRightPanel /></div>
      </div>
    </div>
    <WalletModal />
  </>
}
