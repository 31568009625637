import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useAdaptedValue } from 'src/hooks/common/useAdaptedValue';
import styles from './SideLine.module.scss';

interface LineProps {
  mirror?: boolean;
}

export function SideLine({ mirror }: LineProps) {
  const [height, setHeight] = useState(window.innerHeight);

  useEffect(() => {
    const handler = () => {
      setHeight(window.innerHeight);
    };
    window.addEventListener('resize', handler);
    handler();
    return () => {
      window.removeEventListener('resize', handler);
    };
  }, []);

  const offset_top = useAdaptedValue(40, 30, 20);
  const corner_bend = useAdaptedValue(15, 10, 7);
  const offset_left = useAdaptedValue(60, 20, 10);
  const center = height / 2;
  const center_height = 30;
  const center_corner = 10;
  const dot_offset = 1;

  const linePath = `
    M ${offset_left + corner_bend * 2} ${offset_top}
    L ${offset_left + corner_bend} ${offset_top}
    L ${offset_left} ${offset_top + corner_bend}
    L ${offset_left} ${center - center_height}
    L ${offset_left + center_corner} ${center - center_height + center_corner}
    L ${offset_left + center_corner} ${center + center_height - center_corner}
    L ${offset_left} ${center + center_height}
    L ${offset_left} ${height - offset_top - corner_bend}
    L ${offset_left + corner_bend} ${height - offset_top}
    L ${offset_left + corner_bend * 2} ${height - offset_top}
  `;
  const dotPath = `
    M ${offset_left + dot_offset} ${center - 15}
    L ${offset_left + dot_offset + 2} ${center - 13}
    L ${offset_left + dot_offset + 2} ${center + 13}
    L ${offset_left + dot_offset} ${center + 15}
  `;

  return (
    <svg className={clsx(styles.line, mirror && styles.mirror)}>
      <path d={linePath} stroke={'#fff'} fill={'none'} opacity={0.4} strokeWidth={1} />
      <path d={dotPath} stroke={'none'} fill={'#00FF19'} />
    </svg>
  );
}
