import { combine, createEvent, createStore } from 'effector';

const $loadingStepsStore = createStore<number>(0);
const $loadingFinishedStepsStore = createStore<number>(0);

const $loadedStore = combine(
  $loadingStepsStore, //
  $loadingFinishedStepsStore,
  (steps, finishedSteps) => {
    return steps > 0 && steps === finishedSteps;
  }
);

const $loadingProgressStore = combine(
  $loadingStepsStore, //
  $loadingFinishedStepsStore,
  (steps, finishedSteps) => {
    if (steps > 0) {
      return finishedSteps / steps;
    }
    return 0;
  }
);

const addLoadingSteps = createEvent<number | undefined>();
const completeLoadingSteps = createEvent<number | undefined>();

$loadingStepsStore.on(addLoadingSteps, (state, value) => {
  if (value && value > 1) {
    return state + value;
  }
  return state + 1;
});
$loadingFinishedStepsStore.on(completeLoadingSteps, (state, value) => {
  if (value && value > 1) {
    return state + value;
  }
  return state + 1;
});

export {
  $loadedStore, //
  $loadingProgressStore,
  addLoadingSteps,
  completeLoadingSteps
};
