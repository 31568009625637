import styles from './AuctionHeader.module.scss';

export const AuctionHeader = () => {
  const auctionEnds = '5 hours'; // TODO: Store
  const activeWallet = '3123123123123123'; // TODO: Store

  const walletClick = () => {
    // TODO
  }

  return <div className={styles.block}>
    <div className={styles.block__wrapper}>
      {activeWallet ?
        <div>
          <button className={styles.wallet} onClick={walletClick}>
            <span>{activeWallet}</span>
          </button>
        </div> :
        <div>
          <span>Starfall</span> — Bucket Auction
        </div>
      }

      <div>Auction<b> ends in {auctionEnds}</b></div>
      <svg viewBox="0 0 35 34" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M16.0012 0.36158C15.7874 0.967667 15.645 1.59661 15.5769 2.23567C14.6722 9.42577 8.38118 15.3094 1.0534 15.8235C0.159904 15.8853 0.117758 15.9359 0.039086 17.0289C-0.0130286 17.4281 -0.0130286 17.8323 0.039086 18.2315C0.0840416 18.2652 5.55178 18.3551 12.1884 18.4338L24.2562 18.5743L22.6293 19.8499C21.5773 20.7138 20.6178 21.6846 19.7662 22.7467C19.0891 23.629 18.4484 24.2977 18.3417 24.2331C18.2349 24.1684 18.2096 23.5053 18.2799 22.7636C18.3613 21.901 18.2799 21.2688 18.0776 21.0103C17.6758 20.5242 16.3243 20.4736 16.0433 20.9372C15.7904 21.3475 15.7118 33.3815 15.9562 33.7833C16.0546 33.9435 16.6025 34.0334 17.1728 33.9884L18.2096 33.9013L18.4035 32.3026C19.0666 26.9276 22.368 22.2297 27.049 20.0044C29.0158 19.0688 31.14 18.4647 32.4718 18.4591C33.6519 18.4591 34.0986 17.9674 33.9863 16.7957L33.902 15.905L21.8033 15.7701L9.70456 15.6381L11.2949 14.4159C12.3415 13.5772 13.3016 12.6359 14.1608 11.6061C14.8632 10.7239 15.5207 10.0355 15.6247 10.072C15.7286 10.1085 15.8213 10.7773 15.8326 11.5612C15.8438 12.3451 15.9394 13.129 16.0461 13.3088C16.3524 13.8061 17.7544 13.7022 18.041 13.1571C18.3276 12.612 18.4035 0.535784 18.1253 0.372819C17.7918 0.247998 17.4476 0.153948 17.097 0.0918461C16.4311 -0.0851669 16.1978 -0.0121139 16.0012 0.36158Z" fill="white"/>
      </svg>
    </div>
  </div>;
};
