import { useEffect, useRef, useState } from "react";
import { Vector2 } from "three";
import styles from './AuctionSideVector.module.scss';

export const AuctionSideVector = () => {
  const [size, setSize] = useState<Vector2>(new Vector2(0, 0));
  const blockRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (blockRef.current) {
      const block = blockRef.current;
      const observer = new ResizeObserver((items) => {
        const en = items.find(e => e.target === block);
        if (en) {
          const {width, height} = block.getBoundingClientRect();
          const newSize = new Vector2(width, height);
          setSize((prev) => {
            if (!prev.equals(newSize)) {
              return newSize;
            }
            return prev;
          })
        }
      });
      observer.observe(block);
      return () => {
        observer.disconnect();
      }
    }
  }, []);

  const width = size.x;
  const height = size.y;
  const offset_top = 1;
  const offset_left = 1;
  const corner_bend = width / 2;
  const center = height / 2;
  const center_height = 30;
  const center_corner = 10;
  const dot_offset = 1;

  const linePath = `
    M ${corner_bend * 2} ${offset_top}
    L ${corner_bend} ${offset_top}
    L ${offset_left} ${offset_top + corner_bend}
    L ${offset_left} ${center - center_height}
    L ${offset_left + center_corner} ${center - center_height + center_corner}
    L ${offset_left + center_corner} ${center + center_height - center_corner}
    L ${offset_left} ${center + center_height}
    L ${offset_left} ${height - offset_top - corner_bend}
    L ${offset_left + corner_bend} ${height - offset_top}
    L ${offset_left + corner_bend * 2} ${height - offset_top}
  `;
  const dotPath = `
    M ${offset_left + dot_offset} ${center - 15}
    L ${offset_left + dot_offset + 2} ${center - 13}
    L ${offset_left + dot_offset + 2} ${center + 13}
    L ${offset_left + dot_offset} ${center + 15}
  `;

  return <div className={styles.block} ref={blockRef}>
    <svg viewBox={`0 0 ${size.width} ${size.height}`}>
      <path d={linePath} stroke={'#fff'} fill={'none'} opacity={0.4} strokeWidth={1} />
      <path d={dotPath} stroke={'none'} fill={'#00FF19'} />
    </svg>
  </div>;
};
