import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useScreenState } from 'src/hooks/common/useScreenState';
import { ScreenSize, useViewport } from 'src/hooks/common/useViewport';
import { LEFT_IMAGES, RIGHT_IMAGES } from './components/LoreImages';
import { SideSlider } from './components/SideSlider/SideSlider';
import styles from './LoreScreen.module.scss';

enum ActiveSlide {
  Left = -1,
  None,
  Right
}

const LEFT_TITLE = [
  'CHOOSE YOUR FACTION', //
  'shape',
  'the',
  'future'
];
const LEFT_DESCRIPTION = [
  'A sequence of unimaginably many games.',
  'Whichever side wins more of these games,',
  'will have their decision for',
  'the future play out'
];
const RIGHT_TITLE = [
  'EXPLORE NEW UNIVERSES', //
  'UNCOVER',
  'HIDDEN',
  'TIMELINES'
];
const RIGHT_DESCRIPTION = [
  'A sequence of unimaginably many games.',
  'Whichever side wins more of these games,',
  'will have their decision for',
  'the future play out'
];

export function LoreScreen() {
  const desktop = useViewport() === ScreenSize.Desktop;
  const { active: screenActive, scroll: screenScroll } = useScreenState(3);
  const [active, setActive] = useState(ActiveSlide.None);
  const [hint, setHint] = useState(0);
  const mouseActive = screenActive && Math.abs(screenScroll) < 0.3;

  useEffect(() => {
    if (!mouseActive) {
      setActive(ActiveSlide.None);
      return;
    }
    const moveHandler = (event: MouseEvent) => {
      if (!desktop) return;
      const full = window.innerWidth;
      const half = full / 2;
      const gap = full * 0.1;
      const pos = event.clientX;
      if (pos < half - gap) {
        setActive(ActiveSlide.Left);
      } else if (pos > half + gap) {
        setActive(ActiveSlide.Right);
      } else {
        setActive(ActiveSlide.None);
      }
    };
    const clickHandler = (event: MouseEvent) => {
      if (desktop) return;
      const state = event.clientY >= window.innerHeight / 2 ? ActiveSlide.Right : ActiveSlide.Left;
      setActive((prevState) => {
        if (state === prevState) {
          return ActiveSlide.None;
        }
        return state;
      });
    };

    if (desktop) {
      window.addEventListener('mousemove', moveHandler, {
        passive: true
      });
    } else {
      window.addEventListener('click', clickHandler, {
        passive: true
      });
    }
    return () => {
      window.removeEventListener('mousemove', moveHandler);
      window.removeEventListener('click', clickHandler);
    };
  }, [desktop, mouseActive]);

  useEffect(() => {
    if (screenActive && active === ActiveSlide.None) {
      const interval = setInterval(() => {
        setHint((prev) => {
          return (prev + 1) % 4;
        });
      }, 700);
      return () => {
        clearInterval(interval);
        setHint(0);
      };
    }
  }, [screenActive, active]);

  return (
    <div
      className={clsx(
        styles.screen,
        hint === 2 && styles.leftHint,
        hint === 3 && styles.rightHint,
        active === ActiveSlide.Left && styles.left,
        active === ActiveSlide.Right && styles.right
      )}
    >
      <div className={styles.screen__inner}>
        <SideSlider
          title={LEFT_TITLE}
          description={LEFT_DESCRIPTION}
          images={LEFT_IMAGES}
          active={active === ActiveSlide.Left}
        />
      </div>
      <div className={clsx(styles.screen__inner, styles.clipped)}>
        <SideSlider
          title={RIGHT_TITLE}
          description={RIGHT_DESCRIPTION}
          images={RIGHT_IMAGES}
          active={active === ActiveSlide.Right}
          right
        />
      </div>
      <div className={clsx(styles.screen__helper, active !== ActiveSlide.None && styles.hidden)}>
        <div className={styles.chevron}>
          <svg width="39" height="22" viewBox="0 0 39 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M38 1L28 11L38 21" stroke="white" strokeWidth="2" />
            <path d="M26 1L16 11L26 21" stroke="white" strokeWidth="2" />
            <path d="M12 1L1.99997 11L12 21" stroke="white" strokeWidth="2" />
          </svg>
        </div>
        <div className={clsx(styles.chevron, styles.invert)}>
          <svg width="39" height="22" viewBox="0 0 39 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M38 1L28 11L38 21" stroke="white" strokeWidth="2" />
            <path d="M26 1L16 11L26 21" stroke="white" strokeWidth="2" />
            <path d="M12 1L1.99997 11L12 21" stroke="white" strokeWidth="2" />
          </svg>
        </div>
      </div>
    </div>
  );
}
