import { ShardCloud, ShardEnterType, ShardTexture } from 'src/graphics/entities/ShardCloud';
import { easeOutCubic, framedSlice, timeSlice } from 'src/graphics/helpers/Easings';
import { ShardsTexture } from 'src/resources/ShardsTexture';
import { ShardsVideoTexture } from 'src/resources/ShardsVideoTexture';
import {
  Euler,
  MathUtils,
  PerspectiveCamera,
  Quaternion,
  Scene,
  Texture,
  Vector2,
  Vector3,
  WebGLRenderer
} from 'three';
import { PartialScene } from './PartialScene';

interface ShardEntry {
  shard: number;
  offset: number;
}

interface TransformPoint {
  position: Vector3;
  rotation: Euler;
}

/**
 * Class for gameplay slides scene
 */
export class GameplayScene extends PartialScene {
  /**
   * External slide id
   * @type {number}
   * @private
   */
  private static slide: number = 0;

  /**
   * Internal scene
   * @type {Scene}
   * @protected
   */
  private readonly scene: Scene;

  /**
   * Scene camera
   * @type {PerspectiveCamera}
   * @private
   */
  private readonly camera: PerspectiveCamera;

  /**
   * Circular shard entries
   * @type {ShardEntry[]}
   * @private
   */
  private readonly shards: ShardEntry[];

  /**
   * Slides shards
   * @type {ShardEntry[]}
   * @private
   */
  private readonly slides: ShardEntry[];

  /**
   * Transformation path for slides
   * @type {TransformPoint[]}
   * @private
   */
  private points: TransformPoint[];

  /**
   * Shard cloud renderer
   * @type {ShardCloud}
   * @private
   */
  private cloud: ShardCloud;

  /**
   * Internal slide offset for damping
   * @type {number}
   * @private
   */
  private offset: number = 0;

  /**
   * Set slide externally
   * @param {number} index
   */
  public static setSlide(index: number) {
    this.slide = index;
  }

  /**
   * Scene constructor
   */
  public constructor() {
    super();
    this.scene = new Scene();
    this.camera = new PerspectiveCamera(60, 1, 0.1, 500);
    this.shards = [];
    this.slides = [];
    this.cloud = new ShardCloud();

    const totalShards = 30;
    const positions: Vector3[] = [];
    const offsetX = 20;
    const offsetY = 14;

    // Create positions for circular shards
    for (let i = 0; i < totalShards; i++) {
      const pos = new Vector3(
        Math.sin((i / (totalShards - 1)) * Math.PI * 2) * offsetX, //
        Math.cos((i / (totalShards - 1)) * Math.PI * 2) * offsetY,
        MathUtils.lerp(-15, -25, Math.random())
        // -25
      ).add(
        new Vector3(
          Math.random() - 1, //
          0,
          0
        )
      );
      positions.push(pos);
    }

    // Make shards from positions
    for (const pos of positions) {
      let size = 100;
      for (const otherPos of positions) {
        if (!pos.equals(otherPos)) {
          size = Math.min(size, pos.distanceTo(otherPos));
        }
      }
      const rotation = new Euler(
        (Math.random() - 0.5) * 2 * 0.5, //
        (Math.random() - 0.5) * 2 * 0.4,
        (Math.random() - 0.5) * 2 * 0.4
      );
      const shard = this.cloud.addShard(
        pos,
        new Quaternion().setFromEuler(rotation),
        size * (Math.random() * 0.2 + 0.8) * 0.8,
        ShardTexture.None,
        0,
        ShardEnterType.Displace
      );
      this.shards.push({
        shard,
        offset: Math.random()
      });
    }

    // Generate slide shards
    for (let i = 0; i < 3; i++) {
      const shard = this.cloud.addShard(
        new Vector3(0, 0, -15),
        new Quaternion().identity(),
        15,
        ShardTexture.Video,
        i,
        ShardEnterType.Outline,
        true
      );
      this.slides.push({
        shard,
        offset: 0
      });
    }

    // Compose shard renderer
    this.cloud.compose();

    // Creating path points
    this.points = [
      {
        position: new Vector3(25, 3, -30),
        rotation: new Euler(0.3, -0.5, 0)
      },
      {
        position: new Vector3(0, 0, -15),
        rotation: new Euler(0, 0, 0)
      },
      {
        position: new Vector3(-25, -1, -13),
        rotation: new Euler(-0.15, 0.1, 0)
      }
    ];
  }

  /**
   * Enable scene rendering
   */
  public enter() {
    this.scene.add(this.cloud.entity);
  }

  /**
   * Update scene logic
   * @param {number} delta
   * @param {number} time
   */
  public update(delta: number, time: number): void {
    this.scene.position.set(0, 0, 0);
    this.scene.rotation.set(0, 0, 0);
    this.scene.applyMatrix4(this.matrix);
    this.offset = MathUtils.damp(this.offset, GameplayScene.slide, 0.05, delta);

    let idx = 0;
    for (const slide of this.slides) {
      const frameDelta = MathUtils.clamp((this.offset - idx) * 0.5 + 0.5, 0, 1) * (this.points.length - 1);
      const frame = Math.min(Math.floor(frameDelta), this.points.length - 2);
      const lerp = frameDelta - frame;
      const appear = (1 - Math.abs(MathUtils.clamp(this.offset - idx, -1, 1))) * (1.0 - easeOutCubic(Math.abs(time)));

      const pos = this.points[frame].position
        .clone() //
        .lerp(this.points[frame + 1].position, lerp)
        .add(new Vector3(2 * this.camera.aspect, 0, 0));
      const rot = new Quaternion()
        .setFromEuler(this.points[frame].rotation)
        .slerp(new Quaternion().setFromEuler(this.points[frame + 1].rotation), lerp);

      this.cloud.updateShardState(slide.shard, MathUtils.clamp(appear, 0.0, 2.0));
      this.cloud.updateShardPosition(slide.shard, pos, rot);
      idx++;
    }

    for (const entry of this.shards) {
      const t = Math.max(time, 0) + framedSlice(timeSlice(time, -0.5, 0), entry.offset, 0.7);
      this.cloud.updateShardState(entry.shard, t);
    }

    this.cloud.update(new Vector3(), delta);
  }

  /**
   * Render scene
   * @param {WebGLRenderer} renderer
   */
  public render(renderer: WebGLRenderer): void {
    renderer.render(this.scene, this.camera);
  }

  /**
   * Exit scene
   */
  public leave() {
    this.scene.remove(this.cloud.entity);
  }

  /**
   * Release scene resources
   */
  public dispose(): void {}

  /**
   * Resize handler
   * @param {Vector2} size
   */
  public resize(size: Vector2): void {
    this.camera.zoom = this.getZoom(size);
    this.camera.aspect = size.x / size.y;
    this.camera.updateProjectionMatrix();
  }

  /**
   * Update scene background texture
   * @param {Texture} background
   */
  public setBackground(background: Texture) {
    super.setBackground(background);
    this.cloud.updateTextures(background, ShardsTexture.texture, ShardsVideoTexture.texture);
  }
}
