import LogoFile from 'src/assets/models/logo.msh';
import { GeometryBuffers, loadCompressedGeometry } from 'src/graphics/helpers/CompressedGeometry';
import { BufferGeometry } from 'three';

/**
 * Scene models loader and container
 */
export class SceneModels {
  /**
   * Logo buffers
   * @type {GeometryBuffers}
   * @private
   */
  private static logoMesh: GeometryBuffers;

  /**
   * Fetch all models
   * @returns {Promise<void>}
   */
  public static async load() {
    const [
      logo //
    ] = await Promise.all([
      loadCompressedGeometry(LogoFile) //
    ]);

    this.logoMesh = logo;
  }

  /**
   * Logo geometry
   * @returns {BufferGeometry}
   */
  public static get logo() {
    return this.buildGeometry(this.logoMesh);
  }

  /**
   * Build geometry from buffers
   * @param {GeometryBuffers} buffers
   * @returns {BufferGeometry}
   * @private
   */
  private static buildGeometry(buffers: GeometryBuffers) {
    const geom = new BufferGeometry();
    if (buffers.position) geom.setAttribute('position', buffers.position);
    if (buffers.normal) geom.setAttribute('normal', buffers.normal);
    if (buffers.uv) geom.setAttribute('uv', buffers.uv);
    if (buffers.indices) geom.setIndex(buffers.indices);
    return geom;
  }
}
