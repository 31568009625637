import { setModalState } from "../../../../store/auction/modal";
import { Button } from "../../../base/Button/Button";

export const LogInScreen = () => {

  const openModal = () => {
    setModalState(true);
  }

  return <>
    <h2>To place a Bid, log in</h2>
    <Button onClick={openModal}>Connect wallet</Button>
    <p><i>Pro tip: a bid below the current price when
      the auction ends will be refunded!</i></p>
  </>
}
