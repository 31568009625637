import { useState } from "react";
import { BidInput } from "../../base/BidInput/BidInput";
import { Button } from "../../base/Button/Button";


export const ControlBid = () => {
  const [bid, setBid] = useState<string | null>(null);
  const lowestBid = 0.157; // TODO: minimal bid

  const placeBid = () => {
    // TODO: Bid placement
  }

  return <>
    <BidInput value={bid} onChange={setBid} />
    <Button onClick={placeBid}>Add a Bid</Button>
    <p>
      Current lowest winning price: <span>{lowestBid.toString()}&nbsp;ETH.</span><br/>
      <i>
        Pro tip: a bid below the current price when
        the&nbsp;auction ends will be refunded!
      </i>
    </p>
  </>;
};
