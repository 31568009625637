import styles from './BidInfo.module.scss';

export interface BidInfoDef {
  hash: string;
  rank: number;
  bid: string;
  refund: string;
  boxes: number;
}

export const BidInfo = ({ def }: { def: BidInfoDef }) => {
  return <>
    <div className={styles.block}>
      <div className={styles.block__content}>
        <div className={styles.item}>
          <b>{def.rank}</b>
          <span>rank</span>
        </div>
        <div className={styles.item}>
          <b>{def.bid}</b>
          <span>eth</span>
        </div>
        <div className={styles.item}>
          <b className={def.boxes > 0 ? styles.good : styles.bad}>{def.boxes}</b>
          <span>boxes</span>
        </div>
        <div className={styles.item}>
          <b>{def.refund}</b>
          <span>refund</span>
        </div>
      </div>
    </div>
  </>;
};
