import BackgroundMusic from 'src/assets/sounds/background.mp3';
import { MathUtils } from 'three';

const SOUND_BUFFERS: string[] = [
  //
];

interface SoundState {
  source: AudioBufferSourceNode | MediaElementAudioSourceNode;
  gain: GainNode;
  gainValue: number;
  tag?: HTMLAudioElement;
}

export class SoundSystem {
  private static context: AudioContext;

  private static audioData: ArrayBuffer[] = [];

  private static backgroundActive: boolean = false;

  private static backgroundState: SoundState;

  public static async load() {
    // addLoadingSteps(SOUND_BUFFERS.length);
    this.audioData = await Promise.all(
      SOUND_BUFFERS.map((path) =>
        (async () => {
          const response = await fetch(path);
          const buffer = await response.arrayBuffer();
          // completeLoadingSteps();
          return buffer;
        })()
      )
    );
    document.addEventListener('visibilitychange', () => {
      if (this.backgroundState && this.backgroundActive) {
        if (document.hidden) {
          this.backgroundState.tag?.pause();
        } else {
          this.backgroundState.tag?.play();
        }
      }
    });
  }

  public static setBackgroundActive(active: boolean) {
    if (active !== this.backgroundActive) {
      if (active && !this.context) {
        this.context = new AudioContext();
        this.backgroundState = this.createSoundStateFromPath(BackgroundMusic);
      }
      this.backgroundActive = active;
    }
  }

  public static update(delta: number) {
    if (this.backgroundState) {
      const active = this.backgroundActive;
      this.backgroundState.gainValue = MathUtils.clamp(
        this.backgroundState.gainValue + (active ? 0.05 : -0.05) * delta,
        0,
        1
      );
      this.backgroundState.gain.gain.value = this.backgroundState.gainValue * 0.2;
    }
  }

  private static async createSoundState(index: number): Promise<SoundState> {
    const buffer = await this.context.decodeAudioData(this.audioData[index]);

    const sourceNode = this.context.createBufferSource();
    sourceNode.buffer = buffer;
    sourceNode.loop = true;
    sourceNode.start();

    const gainNode = this.context.createGain();
    gainNode.gain.value = 0;

    sourceNode.connect(gainNode);
    gainNode.connect(this.context.destination);

    return {
      source: sourceNode,
      gain: gainNode,
      gainValue: 0
    };
  }

  private static createSoundStateFromPath(path: string): SoundState {
    const elem = document.createElement('audio');
    elem.loop = true;
    elem.src = path;
    elem.play();
    document.body.append(elem);

    const sourceNode = this.context.createMediaElementSource(elem);
    const gainNode = this.context.createGain();
    gainNode.gain.value = 0;

    sourceNode.connect(gainNode);
    gainNode.connect(this.context.destination);

    return {
      source: sourceNode,
      gain: gainNode,
      gainValue: 0,
      tag: elem
    };
  }
}
