import clsx from 'clsx';
import { useEffect, useRef } from 'react';
import styles from './SideSlider.module.scss';
import { SideSliderHandler } from './SideSliderHandler';

interface SideSliderProps {
  description: string[];
  title: string[];
  images: string[];
  active: boolean;
  right?: boolean;
}

export function SideSlider({ title, description, images, active, right }: SideSliderProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const handlerRef = useRef<SideSliderHandler | null>(null);

  useEffect(() => {
    if (!canvasRef.current) return;
    const handler = new SideSliderHandler(canvasRef.current!, images);
    handlerRef.current = handler;

    return () => {
      handler.dispose();
      handlerRef.current = null;
    };
  }, [images]);

  useEffect(() => {
    handlerRef.current?.setActive(active);
  }, [active]);

  return (
    <div className={clsx(styles.slide, right && styles.right)}>
      <div className={clsx(styles.slide__image, active && styles.active)}>
        <canvas ref={canvasRef} />
        <em />
      </div>
      {/*<div className={clsx(styles.slide__text, !active && styles.hidden)}>*/}
      {/*  {description.map((item) => (*/}
      {/*    <div key={item}>{item}</div>*/}
      {/*  ))}*/}
      {/*</div>*/}
      <div className={styles.slide__title}>
        {title.map((row, idx) => {
          return <div key={row}>{row}</div>;
        })}
      </div>
    </div>
  );
}
