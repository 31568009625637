import CometFragCode from 'src/graphics/shaders/comets.frag.glsl';
import CometVertCode from 'src/graphics/shaders/comets.vert.glsl';
import { SpaceTexture } from 'src/resources/SpaceTexture';
import {
  AdditiveBlending,
  Euler,
  InstancedMesh,
  MathUtils,
  Matrix4,
  Object3D,
  PlaneGeometry,
  Quaternion,
  ShaderMaterial,
  Vector3
} from 'three';

interface Comet {
  offset: number;
  time: number;
  timeMultiplier: number;
  scale: number;
}

/**
 * Comet rendering class
 */
export class Comets {
  /**
   * Total comet count
   * @type {number}
   * @private
   */
  private readonly MAX_COMETS = 20;

  /**
   * Internal comet instances
   * @type {Comet[]}
   * @private
   */
  private readonly entries: Comet[];

  /**
   * Overall timer for trail FX
   * @type {number}
   * @private
   */
  private time: number;

  /**
   * Instanced mesh with all comet billboards
   * @type {InstancedMesh}
   * @private
   */
  private readonly mesh: InstancedMesh;

  /**
   * Comet shader material
   * @type {ShaderMaterial}
   * @private
   */
  private readonly material: ShaderMaterial;

  /**
   * Initialize renderer
   * @param {Object3D} parent
   */
  public constructor(parent: Object3D) {
    this.time = 0;
    this.entries = [];
    for (let i = 0; i < this.MAX_COMETS; i++) {
      this.randomizeEntry(i);
      this.entries[i].time = Math.random();
    }

    const geom = new PlaneGeometry(1, 1);
    geom.translate(0, -0.5, 0);
    geom.scale(1.3, 15, 1);
    geom.scale(0.8, 0.8, 0.8);
    geom.rotateX(Math.PI * 0.5);

    this.material = new ShaderMaterial({
      fragmentShader: CometFragCode,
      vertexShader: CometVertCode,
      depthTest: false,
      depthWrite: false,
      blending: AdditiveBlending,
      uniforms: {
        map: {
          value: SpaceTexture.comet
        },
        time: {
          value: 0
        }
      }
    });
    this.mesh = new InstancedMesh(geom, this.material, this.MAX_COMETS);
    this.mesh.frustumCulled = false;
    parent.add(this.mesh);
  }

  /**
   * Update comet logic
   * @param {number} delta
   */
  public update(delta: number) {
    this.time = (this.time + delta * 0.004) % 1024.0;
    this.material.uniforms.time.value = this.time;

    for (let i = 0; i < this.MAX_COMETS; i++) {
      let en = this.entries[i];
      en.time += 0.0004 * delta * en.timeMultiplier;
      if (en.time > 1) {
        this.randomizeEntry(i);
        en = this.entries[i];
      }
      // en.time = 0.4;

      const position = this.getPosition(en.time, en.offset);
      const positionNext = this.getPosition(en.time + 0.1, en.offset);
      const rotationMat = new Matrix4().lookAt(position, positionNext, position.clone().normalize());
      rotationMat.setPosition(0, 0, 0);

      const mat = new Matrix4().compose(
        position,
        new Quaternion().setFromRotationMatrix(rotationMat),
        new Vector3().setScalar(MathUtils.lerp(0.1, 0.8, en.scale) * 0.5)
      );
      this.mesh.setMatrixAt(i, mat);
    }
    this.mesh.instanceMatrix.needsUpdate = true;
  }

  /**
   * Reset entry with random values
   * @param {number} index
   * @private
   */
  private randomizeEntry(index: number) {
    this.entries[index] = {
      scale: Math.random(),
      offset: Math.random() * 2 - 1,
      time: 0,
      timeMultiplier: Math.random() * 0.2 + 0.3
    };
  }

  /**
   * Calculate position from time and vertical offset
   * @param {number} time
   * @param {number} offset
   * @returns {Vector3}
   * @private
   */
  private getPosition(time: number, offset: number) {
    const start = new Vector3(-35, offset * 40, -30);
    const end = new Vector3(55, offset * 30, 0);
    return start.clone().lerp(end, time).multiplyScalar(1).applyEuler(new Euler(0, 0, -0.7));
  }
}
