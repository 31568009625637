export class Emails {
  private static URL_EMAILS: string = '/form.php ';
  public static async addNewMember(email: string) {
    const fd = new FormData();
    fd.append('email', email);

    await fetch(this.URL_EMAILS, {
      method: 'POST',
      body: fd
    });
  }
}
