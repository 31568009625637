import clsx from "clsx";
import { useMemo } from "react";
import BoxSrc from 'src/assets/images/box.jpg';
import styles from '../AuctionLeftPanel.module.scss';

export const ResultScreen = () => {
  const boxes = useMemo<number>(() => 130, []);
  const refund = '0.01';

  return <>
    {boxes > 0 ? <h1>You win 🤩</h1> : <h1>You didn’t win 😔</h1>}
    <div className={clsx(styles.boxes, boxes === 0 && styles.bad)}>
      <img src={BoxSrc} alt=""/>
      <div>
        <span>+{boxes}</span>
        boxes
      </div>
    </div>
    { boxes > 0 ? <p>
      Congratulations. We have sent <br/> the winning boxes and refund: <span>{refund}&nbsp;ETH</span> to you address. Check your Metamask.
    </p> : <p>
      We have sent refund: <span>{refund}&nbsp;ETH</span>, to your address. <br/> Check your Metamask.
    </p> }
  </>
}
