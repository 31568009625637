import { Children, ReactNode, useEffect, useRef } from 'react';
import styles from './ScreenRoller.module.scss';
import { ScreenRollHandler } from './ScreenRollHandler';

interface RollerProps {
  children: ReactNode;
  blocked: boolean;
}

export function ScreenRoller({ children, blocked }: RollerProps) {
  const handlerRef = useRef<ScreenRollHandler | null>(null);

  useEffect(() => {
    if (handlerRef.current) {
      handlerRef.current!.setBlocked(blocked);
      handlerRef.current!.start();
    }
    return () => {
      if (handlerRef.current) {
        handlerRef.current?.detach();
        handlerRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (handlerRef.current) {
      handlerRef.current.setBlocked(blocked);
    }
  }, [blocked]);

  const items = Children.toArray(children).map((child, index) => {
    const setRef = (elem: HTMLDivElement) => {
      if (elem) {
        if (handlerRef.current === null) {
          handlerRef.current = new ScreenRollHandler();
        }
        handlerRef.current!.setChild(index, elem);
      }
    };
    return (
      <div ref={setRef} key={index} className={styles.roller__slide}>
        {child}
      </div>
    );
  });

  return <div className={styles.roller}>{items}</div>;
}
