import clsx from 'clsx';
import { ChangeEvent, useState } from 'react';
import { ScreenSize, useViewport } from 'src/hooks/common/useViewport';
import { Emails } from 'src/rest/Emails';
import { setMenuActive } from 'src/store/menu';
import styles from './OutroScreen.module.scss';

export enum FormStatus {
  Waiting,
  Error,
  Complete
}

const DISCORD_LINK = 'https://discord.gg/starfallarena';
// const DISCORD_LINK = null;

export function OutroScreen() {
  const screen = useViewport();
  const [inputValue, setInputValue] = useState('');
  const [formStatus, setFormStatus] = useState<FormStatus>(FormStatus.Waiting);

  const inputOnChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (formStatus === FormStatus.Error) {
      setFormStatus(FormStatus.Waiting);
    }
    setInputValue(e.target.value);
  };
  const onSubmit = () => {
    if (inputValue.length > 0) {
      if (inputValue.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)) {
        Emails.addNewMember(inputValue).then(() => {
          setFormStatus(FormStatus.Complete);
        });
      } else {
        setFormStatus(FormStatus.Error);
      }
    } else {
      setFormStatus(FormStatus.Error);
    }
  };

  const icon = (
    <em>
      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 34 34">
        <path
          fill="#fff"
          d="M22.5645 12.0188c-1.043-.4771-2.1508-.8247-3.307-1.0188-.1456.2507-.3073.5903-.4205.8571-1.2298-.1779-2.4508-.1779-3.6636 0-.1132-.2668-.283-.6064-.4205-.8571-1.1643.1941-2.2721.5417-3.3078 1.0188-2.09422 3.0887-2.66022 6.1047-2.37722 9.0802 1.39072 1.0107 2.73302 1.6252 4.05172 2.0295.3235-.4366.6145-.9056.8652-1.3988-.4771-.1779-.9299-.3962-1.3665-.655.1132-.0808.2264-.1697.3315-.2587 2.636 1.2048 5.491 1.2048 8.0946 0 .1132.089.2183.1779.3315.2587-.4366.2588-.8894.4771-1.3665.655.2507.4932.5418.9622.8652 1.3988 1.3179-.4043 2.6683-1.0188 4.0517-2.0295.3477-3.4445-.5506-6.4362-2.3618-9.0802Zm-8.2159 7.2447c-.7924 0-1.4392-.7196-1.4392-1.6009s.6307-1.601 1.4392-1.601c.8005 0 1.4554.7197 1.4393 1.601 0 .8813-.6388 1.6009-1.4393 1.6009Zm5.3132 0c-.7925 0-1.4401-.7196-1.4401-1.6009s.6315-1.601 1.4401-1.601c.8004 0 1.4554.7197 1.4392 1.601 0 .8813-.6307 1.6009-1.4392 1.6009Z"
        />
      </svg>
    </em>
  );

  return (
    <div className={styles.screen}>
      <div className={styles.screen__discord}>
        <b>
          {DISCORD_LINK ? (
            <>
              <a href={'https://discord.gg/starfallarena'} target={'_blank'} rel="noreferrer">
                {icon}
              </a>
              <a href={'https://discord.gg/starfallarena'} target={'_blank'} rel="noreferrer">
                <span>
                  Join <br />
                  Discord
                </span>
              </a>
            </>
          ) : (
            <>
              {icon}
              <span>
                Discord <br />
                Coming Soon
              </span>
            </>
          )}
        </b>
        <div className={styles.screen__email}>
          {screen === ScreenSize.Mobile ? (
            <>
              <button
                onClick={() => {
                  setMenuActive({ isOpen: true, type: 'form' });
                }}
              >
                <span>Subscribe</span>
                <span>for updates</span>
              </button>
            </>
          ) : (
            <>
              {formStatus === FormStatus.Complete ? (
                <label>Thanks for submitting the form!</label>
              ) : (
                <>
                  <label>ENTER YOUR EMAIL TO GET NOTIFIED ABOUT UPDATES:</label>
                  <div className={styles.screen__email_form}>
                    <input type="email" placeholder={'Your email'} value={inputValue} onChange={inputOnChange} />
                    <button onClick={onSubmit}>Enter</button>
                  </div>
                  <div className={clsx(styles.screen__email_error, formStatus === FormStatus.Error && styles.active)}>
                    {inputValue.length > 0 ? 'Please enter a correct email address' : 'This field is required'}
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
      <div className={styles.screen__footer}>
        <div className={styles.block}>
          All rights reserved<br/>
          Bell Curve Labs Inc.
          Starfall Labs PTE. LTD.
          {/*Bell Curve Labs. {screen === ScreenSize.Mobile && <br />} All rights reserved*/}
        </div>
        <div className={styles.block}>
          <a href="https://twitter.com/starfallarena" target="_blank" rel="noreferrer">
            Twitter
          </a>
        </div>
        {/*<div className={styles.block}>*/}
        {/*  <a href="https://redcollar.co" target="_blank">*/}
        {/*    <svg width="30" height="14" viewBox="0 0 30 14" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
        {/*      <path*/}
        {/* eslint-disable-next-line max-len */}
        {/*        d="M14.9612 6.62873L28.2732 0.326172L29.9813 2.03434L19.6734 13.2847L14.9612 8.63142L10.3079 13.2847L0 2.03434L1.70817 0.326172L14.9612 6.62873Z"*/}
        {/*        fill="white"*/}
        {/*      />*/}
        {/*    </svg>*/}
        {/*    Made by Red Collar*/}
        {/*  </a>*/}
        {/*</div>*/}
      </div>
    </div>
  );
}
