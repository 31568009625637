import { ShardCloud, ShardEnterType, ShardTexture } from 'src/graphics/entities/ShardCloud';
import { framedSlice } from 'src/graphics/helpers/Easings';
import { ShardsTexture } from 'src/resources/ShardsTexture';
import { ShardsVideoTexture } from 'src/resources/ShardsVideoTexture';
import { Euler, PerspectiveCamera, Quaternion, Scene, Texture, Vector2, Vector3, WebGLRenderer } from 'three';
import { PartialScene } from './PartialScene';

interface ShardEntry {
  offset: number;
  position: Vector3;
  displace: Vector3[];
  id: number;
}

interface PositionEntry {
  pos: Vector3;
  rot: Euler;
  size: number;
  accent?: boolean;
}

const DESKTOP_POSITIONS: PositionEntry[] = [
  {
    pos: new Vector3(-0.65, 0.3, -2.1),
    rot: new Euler(0, -0.2, 0.5),
    size: 0.55
  },
  {
    pos: new Vector3(0.7, 0.5, -2),
    rot: new Euler(-0.2, 0.1, 0.5),
    size: 1.5,
    accent: true
  },
  {
    pos: new Vector3(-0.8, -0.85, -3.5),
    rot: new Euler(0.1, -0.2, 0.5),
    size: 1.4,
    accent: true
  },
  {
    pos: new Vector3(-4.4, -1.8, -4.5),
    rot: new Euler(0, 0, 0),
    size: 1.6
  },
  {
    pos: new Vector3(3, -2, -5.3),
    rot: new Euler(0.3, -0.9, -0.7),
    size: 1
  }
];

const MOBILE_POSITIONS: PositionEntry[] = [
  {
    pos: new Vector3(-0.5, 1.4, -2.8),
    rot: new Euler(0, -0.2, 0.7),
    size: 1.3
  },
  {
    pos: new Vector3(0.8, 0.5, -4),
    rot: new Euler(-0.2, -0.4, 0.6),
    size: 1.5,
    accent: true
  },
  {
    pos: new Vector3(0.1, -1.5, -3.5),
    rot: new Euler(0.1, -0.2, 0.6),
    size: 1.4,
    accent: true
  },
  {
    pos: new Vector3(-3.2, -2.1, -4),
    rot: new Euler(0, 0, 0),
    size: 1.6,
    accent: true
  },
  {
    pos: new Vector3(-0.7, -0.3, -4.3),
    rot: new Euler(0, 0, 0.5),
    size: 1
  }
];

/**
 * Class for prologue scene
 */
export class IndexScene extends PartialScene {
  /**
   * Flag that shards can be animated
   * @type {boolean}
   * @private
   */
  private static canAnimate: boolean = false;

  /**
   * Active slide
   * @type {number}
   * @private
   */
  private static slide: number = 0;

  /**
   * Internal scene
   * @type {Scene}
   * @protected
   */
  private readonly scene: Scene;

  /**
   * Camera for scene
   * @type {PerspectiveCamera}
   * @private
   */
  private readonly camera: PerspectiveCamera;

  /**
   * All shards in scene
   * @type {ShardEntry[]}
   * @private
   */
  private readonly shards: ShardEntry[];

  /**
   * Shard cloud renderer
   * @type {ShardCloud}
   * @protected
   */
  private readonly cloud: ShardCloud;

  /**
   * Counter for shards appear
   * @type {number}
   * @private
   */
  private appear: number = 0;

  /**
   * Toggle animation flag from React
   * @param {boolean} state
   */
  public static toggleAnimation(state: boolean) {
    this.canAnimate = state;
  }

  /**
   * Scene constructor
   */
  public constructor() {
    super();
    this.scene = new Scene();
    this.camera = new PerspectiveCamera(60, 1, 0.1, 500);
    this.cloud = new ShardCloud();
    this.shards = [];

    // Generate texture quota
    const activeVideo = 2;
    const texQuota: { id: number; type: ShardTexture }[] = [];
    for (let i = 0; i < Math.max(ShardsTexture.count, ShardsVideoTexture.count); i++) {
      if (i < ShardsTexture.count) {
        texQuota.push({
          id: i,
          type: ShardTexture.Atlas
        });
      }
    }
    for (let i = texQuota.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [texQuota[i], texQuota[j]] = [texQuota[1], texQuota[3]];
    }

    // Create shard instances
    const positions = DESKTOP_POSITIONS;
    for (const en of positions) {
      // Take texture from texture lookup
      let tex = 0;
      let texType = ShardTexture.None;
      if (positions.indexOf(en) === 1) {
        tex = activeVideo;
        texType = ShardTexture.Video;
      } else if (en.accent && texQuota.length > 0) {
        const ent = texQuota.shift()!;
        tex = ent.id;
        texType = ent.type;
      }

      // Create shard instance
      en.rot.order = 'ZXY';
      this.shards.push({
        id: this.cloud.addShard(
          en.pos,
          new Quaternion().setFromEuler(en.rot),
          en.size,
          texType,
          tex,
          ShardEnterType.Outline
        ),
        offset: Math.random(),
        position: en.pos,
        displace: Array(3)
          .fill(0)
          .map(() => new Vector3().randomDirection().multiplyScalar(0.1 + Math.random() * 0.9))
      });

      console.debug(this.shards);
    }

    // After shards generation, compile cloud
    this.cloud.compose();
  }

  /**
   * Enable scene for rendering
   */
  public enter() {
    this.scene.add(this.cloud.entity);
  }

  /**
   * Update scene logic
   * @param {number} delta
   * @param {number} time
   */
  public update(delta: number, time: number): void {
    this.scene.position.set(0, 0, 0);
    this.scene.rotation.set(0, 0, 0);
    this.scene.applyMatrix4(this.matrix);

    if (IndexScene.canAnimate) {
      this.appear = Math.min(this.appear + 0.01 * delta, 1.0);
    }

    for (const entry of this.shards) {
      const t = time + framedSlice(this.appear, entry.offset, 0.7);
      this.cloud.updateShardState(entry.id, t);
    }
    this.cloud.update(new Vector3(), delta);
  }

  /**
   * Render scene
   * @param {WebGLRenderer} renderer
   */
  public render(renderer: WebGLRenderer): void {
    renderer.render(this.scene, this.camera);
  }

  /**
   * Disable rendering for this scene
   */
  public leave() {
    this.scene.remove(this.cloud.entity);
  }

  /**
   * Release resources
   */
  public dispose(): void {}

  /**
   * Resize handler
   * @param {Vector2} size
   */
  public resize(size: Vector2): void {
    this.camera.zoom = this.getZoom(size);
    this.camera.aspect = size.x / size.y;
    this.camera.updateProjectionMatrix();

    // Reposition shards
    const positions = this.camera.aspect < 1 ? MOBILE_POSITIONS : DESKTOP_POSITIONS;
    for (let i = 0; i < positions.length; i++) {
      positions[i].rot.order = 'ZXY';
      this.cloud.updateShardPosition(i, positions[i].pos, new Quaternion().setFromEuler(positions[i].rot));
    }
  }

  /**
   * Update scene background texture
   * @param {Texture} background
   */
  public setBackground(background: Texture) {
    super.setBackground(background);
    this.cloud.updateTextures(background, ShardsTexture.texture, ShardsVideoTexture.texture);
  }
}
