import { ScreenRollHandler } from 'src/components/base/ScreenRoller/ScreenRollHandler';
import { Comets } from 'src/graphics/entities/Comets';
import { MovingStars } from 'src/graphics/entities/MovingStars';
import { Skybox } from 'src/graphics/entities/Skybox';
import { Stars } from 'src/graphics/entities/Stars';
import { CameraDisplace } from 'src/graphics/helpers/CameraDisplace';
import { quadPass } from 'src/graphics/helpers/QuadPass';
import { SoundSystem } from 'src/graphics/sound/SoundSystem';
import { ShardsVideoTexture } from 'src/resources/ShardsVideoTexture';
import {
  ClampToEdgeWrapping,
  CubicBezierCurve3,
  Euler,
  LinearFilter,
  MathUtils,
  Matrix4,
  PerspectiveCamera,
  Quaternion,
  RGBAFormat,
  Vector2,
  Vector3,
  WebGLRenderer,
  WebGLRenderTarget
} from 'three';
import { GraphicsScene } from './GraphicsScene';
import { GameplayScene } from './partials/GameplayScene';
import { IndexScene } from './partials/IndexScene';
import { OutroScene } from './partials/OutroScene';
import { PartialScene } from './partials/PartialScene';
import { PrologueScene } from './partials/PrologueScene';

/**
 * Main scene container
 */
export class LandingScene extends GraphicsScene {
  /**
   * Scene base camera
   * @type {PerspectiveCamera}
   * @private
   */
  private readonly camera: PerspectiveCamera;

  /**
   * Skybox
   * @type {Skybox}
   * @private
   */
  private readonly space: Skybox;

  /**
   * Star points container
   * @type {Stars}
   * @private
   */
  private readonly stars: Stars;

  /**
   * Star batch container
   * @type {MovingStars}
   * @private
   */
  private readonly movingStars: MovingStars;

  /**
   * Flying comets
   * @type {Comets}
   * @private
   */
  private readonly comets: Comets;

  /**
   * Internal partial slide scenes
   * @type {PartialScene[]}
   * @private
   */
  private readonly scenes: (PartialScene | null)[] = [];

  /**
   * Offset, damped from scroll
   * @type {number}
   * @private
   */
  private offset: number = 0;

  /**
   * Currently active scene IDs
   * @type {number[]}
   * @private
   */
  private activeScenes: number[] = [];

  /**
   * Camera movement path
   * @type {CubicBezierCurve3}
   * @private
   */
  private readonly path: CubicBezierCurve3;

  /**
   * Buffer for space render
   * @type {WebGLRenderTarget}
   * @private
   */
  private readonly spaceBuffer: WebGLRenderTarget;

  /**
   * Buffer for next scene
   * @type {WebGLRenderTarget}
   * @private
   */
  private readonly nextSceneBuffer: WebGLRenderTarget;

  /**
   * Current mouse position, damped from CameraDisplace
   * @type {Vector2}
   * @private
   */
  private mousePos: Vector2 = new Vector2(0, 0);

  /**
   * Class that handles mouse movement and gyroscope events
   * @type {CameraDisplace}
   * @private
   */
  private cameraDisplace: CameraDisplace;

  /**
   * Scene initialization
   */
  public constructor() {
    super();
    this.activeScenes = [];
    this.camera = new PerspectiveCamera(50, 1, 0.1, 500);

    this.space = new Skybox();
    this.stars = new Stars(this.space.entity);
    this.movingStars = new MovingStars();
    this.comets = new Comets(this.space.entity);

    this.spaceBuffer = new WebGLRenderTarget(100, 100, {
      wrapS: ClampToEdgeWrapping,
      wrapT: ClampToEdgeWrapping,
      magFilter: LinearFilter,
      minFilter: LinearFilter,
      generateMipmaps: false,
      format: RGBAFormat,
      depthBuffer: false,
      stencilBuffer: false
    });
    this.nextSceneBuffer = new WebGLRenderTarget(100, 100, {
      wrapS: ClampToEdgeWrapping,
      wrapT: ClampToEdgeWrapping,
      magFilter: LinearFilter,
      minFilter: LinearFilter,
      generateMipmaps: false,
      format: RGBAFormat,
      depthBuffer: true,
      stencilBuffer: false
    });

    this.scenes = [
      new IndexScene(), //
      new PrologueScene(),
      new GameplayScene(),
      null, // Lore
      null, // Competition
      new OutroScene()
    ];

    this.path = new CubicBezierCurve3(
      new Vector3(5, 0, 0).multiplyScalar(1),
      new Vector3(20, 9, -100).multiplyScalar(1),
      new Vector3(50, 20, -150).multiplyScalar(1),
      new Vector3(100, 8, -200).multiplyScalar(1)
    );

    this.mousePos.set(0, 0);
    this.cameraDisplace = new CameraDisplace();
    this.cameraDisplace.bind();
  }

  /**
   * Scene logic update
   * @param {number} delta
   */
  public update(delta: number): void {
    // Update sound system
    SoundSystem.update(delta);

    // Damp offset and "mouse" coords
    const targetOffset = ScreenRollHandler.getNormalizedScroll();
    const targetMouse = this.cameraDisplace.state;
    this.offset = MathUtils.damp(this.offset, targetOffset, 0.1, delta);
    if (Math.abs(this.offset - targetOffset) < 0.0001) {
      this.offset = targetOffset;
    }
    this.mousePos.set(
      MathUtils.damp(this.mousePos.x, targetMouse.x, 0.05, delta),
      MathUtils.damp(this.mousePos.y, targetMouse.y, 0.05, delta)
    );

    // Calculate current camera position from path
    const [basePosition, baseRotation] = this.pathAtTime(this.offset);
    baseRotation.multiply(new Quaternion().setFromEuler(new Euler(this.mousePos.y * 0.005, this.mousePos.x * 0.005)));
    basePosition.add(new Vector3(this.mousePos.x * 0.1, -this.mousePos.y * 0.1, 0).applyQuaternion(baseRotation));

    // Update camera entity
    this.camera.position.copy(basePosition);
    this.camera.quaternion.copy(baseRotation);
    const cameraMat = this.camera.matrix.clone().invert();

    // Position space onto camera coords
    this.space.entity.position.copy(this.camera.position);
    this.space.entity.updateMatrix();
    this.stars.update(delta);
    this.movingStars.update(this.offset % 1.0, this.camera.position);
    this.comets.update(delta);

    // Calculate currently active scenes
    const activeScene = Math.floor(this.offset);
    const newScenes = [activeScene];
    if (activeScene < this.scenes.length - 1 && this.offset - activeScene > 0) {
      newScenes.unshift(activeScene + 1);
    }
    for (const newId of newScenes) {
      if (!this.activeScenes.includes(newId)) {
        this.scenes[newId]?.enter();
      }
    }
    for (const oldId of this.activeScenes) {
      if (!newScenes.includes(oldId)) {
        this.scenes[oldId]?.leave();
      }
    }
    this.activeScenes = newScenes;

    // Update scene positioning based on curve
    for (const id of this.activeScenes) {
      const [scenePosition, sceneRotation] = this.pathAtTime(id);
      const mat = new Matrix4().compose(scenePosition, sceneRotation, new Vector3(1, 1, 1));
      mat.multiply(cameraMat);

      this.scenes[id]?.setBaseMatrix(mat);
      this.scenes[id]?.update(delta, this.offset - id);
    }

    // Update gradients
    this.space.update(this.offset);
  }

  /**
   * Scene rendering
   * @param {WebGLRenderer} renderer
   */
  public render(renderer: WebGLRenderer): void {
    const DEBUG_SPACE = false;
    ShardsVideoTexture.update(renderer);
    const [currentScene, nextScene] = [...this.activeScenes].sort();

    if (DEBUG_SPACE) {
      renderer.setViewport(0, 0, this.nextSceneBuffer.width, this.nextSceneBuffer.height);
      renderer.setRenderTarget(null);
      renderer.setClearColor(0x0, 1);
      renderer.clear(true, true, false);
      renderer.render(this.space.entity, this.camera);
      return;
    }

    // Save space into buffer
    renderer.info.autoReset = false;
    renderer.info.reset();
    renderer.autoClear = false;
    renderer.setViewport(0, 0, this.nextSceneBuffer.width, this.nextSceneBuffer.height);
    renderer.setRenderTarget(this.spaceBuffer);
    renderer.setClearColor(0x0, 1);
    renderer.clear(true, true, false);
    renderer.render(this.space.entity, this.camera);
    this.movingStars.render(renderer, this.camera);
    renderer.setRenderTarget(null);

    // If we have next scene - we need to draw it into separate buffer
    if (nextScene !== undefined) {
      renderer.setRenderTarget(this.nextSceneBuffer);
      renderer.setClearColor(0x0, 0);
      renderer.clear(true, true, false);
      quadPass(renderer, this.spaceBuffer.texture);
      this.scenes[nextScene]?.setBackground(this.spaceBuffer.texture);
      this.scenes[nextScene]?.render(renderer);
    }

    // Render active scene directly to screen
    const background = nextScene !== undefined ? this.nextSceneBuffer.texture : this.spaceBuffer.texture;
    renderer.setRenderTarget(null);
    renderer.setClearColor(0x0, 1);
    renderer.clear(true, true, false);
    quadPass(renderer, background);
    this.scenes[currentScene]?.setBackground(background);
    this.scenes[currentScene]?.render(renderer);
  }

  /**
   * Resize event handler
   * @param {Vector2} size
   */
  public resize(size: Vector2): void {
    this.camera.aspect = size.x / size.y;
    this.camera.zoom = 1;
    this.camera.updateProjectionMatrix();
    this.spaceBuffer.setSize(size.x, size.y);
    this.nextSceneBuffer.setSize(size.x, size.y);
    for (const scene of this.scenes) {
      scene?.resize(size);
    }
  }

  /**
   * Resources disposal
   */
  public dispose(): void {
    this.cameraDisplace.unbind();
  }

  /**
   * Get position and rotation from path
   * @param {number} frame
   * @returns {readonly [Vector3, Quaternion]}
   * @private
   */
  private pathAtTime(frame: number) {
    const total = this.scenes.length - 1;
    const delta = frame / total;
    const pos = this.path.getPoint(delta);

    return [
      pos,
      new Quaternion().setFromRotationMatrix(
        new Matrix4().lookAt(new Vector3(0, 0, 0), this.path.getTangent(delta), new Vector3(0, 1, 0))
      )
    ] as const;
  }
}
