import React, { useEffect } from 'react';
import css from './Terms.module.scss';

export const Terms = () => {
  const root = document.getElementById('root');

  useEffect(() => {
    if (root) {
      root.style.overflow = 'auto';

      return () => {
        root.style.overflow = 'hidden';
      };
    }
  }, []);
  return (
    <div className={css.wrapper}>
      <h1>Terms of Use</h1>
      <p>
          These following Terms of Service constitute a legally binding agreement made between you, whether personally
          or on behalf of an entity (“you”) and Starfall Labs PTE. LTD. (“we”, “us”, or “our”) regarding the access
          to and the use of the website (www.playstarfall.com) and the Starfall Arena apps (mobile app, PC app) as well
          as any other asset from social media channels, social networks, communities or mobile applications related,
          linked, or otherwise connected thereto (collectively, the “Site” and the “App”). Starfall Arena is a
          distributed application that is going to launch and run on the Arbitrum Nova (the “Blockchain”), using
          a specially-developed system called Smart Contract (the “Smart Contract”) to enable users to battle as well
          as own and trade heroes with different rarities. These assets can then be visualized on a website that the
          user can interact with the Site. The Smart Contract and the Site are collectively referred to in these Terms
          as the “App”. By using the App, users can manage their assets and use the Smart Contract to battle and trade
          with other App users.
      </p>
      <p>
          WE ARE ONLY WILLING TO DISTRIBUTE THE APP, THE SMART CONTRACT, AND THE SITE AVAILABLE TO YOU IF YOU
          ACCEPT ALL OF THESE TERMS. BY USING THE APP, THE SMART CONTRACT, THE SITE, OR ANY ASSET-RELATED,
          OR BY CLICKING “I ACCEPT” BELOW AND IMPLYING YOUR ACCEPTANCE CHECKING IN THE TICK BOX, YOU ARE
          CONFIRMING THAT YOU HAVE READ, UNDERSTAND, AND AGREE TO BE BOUND BY ALL OF THESE TERMS OF USE. IF YOU
          DO NOT AGREE AND/OR ACCEPT ALL OF THESE TERMS OF USE, THEN YOU ARE NOT ELIGIBLE FOR USING THE APP, THE
          SITE, AND THE SMART CONTRACT AND YOU MUST STOP ANY FORM OF USING OUR ASSETS IMMEDIATELY.
      </p>
      <p>
          Supplemental terms and conditions or any type of additional documents that may be publicized on the Site,
          the App, and the Smart Contract at some points in the future will be eventually integrated with existing
          terms of use. We reserve our rights in our sole discretion to make any type of changes or modifications to
          these Terms of Use at any time and for any reason. You will be notified of any changes and are able to access
          them by updating the most up-to-date of these Terms of Use. However, it is not legally compulsory for us to
          notify and release changelogs pointing out such changes specifically. It is your responsibility to
          periodically review these Terms of Use to stay fully informed of updates. You will be subjected to
          and deemed as having awareness and acceptance of the changes in any revised Terms of Use by proceeding
          to use of the Site, the App, and the Smart Contract after such revised terms come into effect on a
          publicized date.
      </p>
      <p>
          Information on the Site, the App, and the Smart Contract are not intended for distributing to or being
          used by any single person or entity in any jurisdiction or country where such distribution or use would
          be against its existing laws or regulations as well as any circumstance would subject us to any registration
          requirements within the territories of said jurisdiction or country. As a matter of fact, people who make
          their decisions to access the Site and/or the App from outside from our base country are deemed to have the
          understanding of doing so on their own choices and therefore are legally eligible for compliance with local
          laws, assuming they are applicable to them to some extent.

      </p>
      <p>
          Our product is intended for users who are at least 18 years old. People under the age of 18 are not permitted
          to any form of using our assets including the Site, the App, and the Smart Contract.
          1/ INTELLECTUAL PROPERTY COPYRIGHT
      </p>
      <p>
          Apart from other noted indications, the Site and the App and the Smart Contract are our proprietary
          properties as well as all source codes, databases, functionalities, software, website designs, audios,
          videos, texts, photographs, and graphics on the Site and the Apps (collectively, the “Content”). Trademarks,
          service marks, and logos (the “Marks”) are owned and legally licensed to us, controlled by us, and are
          protected regarding registration of intellectual property copyright. Apart from uses stated and provided
          in these Terms of Use, no part of the Site, the App as well as the Smart Contract and no Content or Marks
          may be copied, reproduced, aggregated, republished, uploaded, posted, publicly displayed, encoded, translated,
          transmitted, distributed, sold, licensed, or otherwise exploited for any commercial purposes whatsoever,
          without asking for our legal approval.
      </p>
      <p>
          Provided that you are eligible to use the Site, the App, and the Smart Contract, you are granted limited
          access to and use of the Site to download or print a copy of any portion of the Content regarding personal,
          non-commercial uses.
      </p>
      <h3>2. USER REPRESENTATIONS</h3>
      <p>By using the Site, the App, and the Smart Contract, you represent yourself and agree that:</p>
      <p>(1) All registration information you submit is fully stated, true, accurate, and up-to-date;</p>
      <p>
          (2) You will maintain the accuracy of such given information and will be asked to promptly update
          whenever necessary;
      </p>
      <p>(3) You have the legal capacity and the thorough understanding of complying with these Terms of Use;</p>
      <p>
          (4) You are not a part of a minority community that practices laws and regulations which have nothing
          in common with ours;
      </p>
      <p>
          (5) You will not access the Site, the App, and the Smart Contract using automated and non-human means,
          whether it is a bot, script, or anything alike;
      </p>
      <p>
          (6) You will not use the Site, the App, and the Smart Contract for committing any illegal
          and unauthorized purpose;
      </p>
      <p>
          (7) Your use of the Site, the App, and the Smart Contract will not violate any existing given law
          or regulation. If you provide any information that is untruthful, inaccurate, not up-to-date, we
          have the right to suspend or terminate your accounts and refuse to approve your any action regarding any
          current or future activities of the Site, the App, and the Smart Contract.
      </p>
      <p>(8) You can only own one account attaching to one device at a time;</p>
      <p>
          (9) You are not on any blacklist of any organization for any reason including committing scams,
          fraud; illegal data exploiting, using third-party software for personal benefits; trying to gain
          access and damage our assets without our approval; and everything alike. If we find out any illegal
          activities, we will make a prompt intervention and resolve to legal means if necessary.
      </p>
      <h3>3. USER REGISTRATION</h3>
      <p>
          You are required to register upon accessing the Site, the App, and the Smart Contract.
          You should have control over your password confidentiality and will be responsible for the
          use of your account and password. We reserve the right to remove, reclaim or change a username
          you select if we determine that such username is inappropriate or appears as irritation to other users.
      </p>
      <h3>4. WALLETS and DIGITAL ASSETS</h3>
      <p>
          Starfall Arena allows its users to access and manage blockchain-based assets (“Digital Assets”).
          Your wallet, Digital Assets, and cryptocurrencies are completely in your own control. You are the
          one and only one responsible for manipulating your fund to perform any transfers of Digital Assets.
          We cannot interfere with any of your Digital Assets storage and transactions from your wallets in any case.

      </p>
      <p>
          Starfall Arena does not manage any blockchain wallets. You hereby acknowledge and agree that
          Starfall Arena has no liability for or control over the safety, suitability, quality, delivery,
          legality, or other aspects of any of your Digital Assets.
      </p>
      <h3>5. PROHIBITED ACTIVITIES</h3>
      <p>
          You may not access or use the Site, the App, and the Smart Contract for any purpose other than those for which
          we make the Site, the App, and the Smart Contract available. The Site, the App, and the Smart Contract may not
          be used in connection with any commercial activities if not approved by our legal teams in Starfall Arena Game.
      </p>
      <p>
          Systematically retrieve data or other content from the Site, the App, and the Smart Contract to create or
          compile, directly or indirectly, a collection, compilation, database, or directory without our approval.
      </p>
      <p>
          Make any unauthorized use of the Site, the App, and the Smart Contract, including collecting usernames and/or
          email addresses of registered users by using third-party software; creating user accounts by automated means
          including using bots or hiding presences by faking IP addresses. Use a buying agent or purchasing agent to
          make purchases on the Site, the App, and the Smart Contracts.
      </p>
      <p>
          Use the Site, the App, and the Smart Contract as assets to advertise and offer to sell personal goods
          and services not relating to Starfall Arena Game.
      </p>
      <p>
          Circumvent, disable, or otherwise interfere with security-related features of the Site, the App, and
          the Smart Contract, including those that prevent or restrict the act of copying Content or enforcing
          limitations on the use of the Site, the App, and the Smart Contract.
      </p>
      <p>
          Trick, defraud, or mislead us and other users with any attempt to get sensitive account information such
          as user passwords.
      </p>
      <p>Making improper use of our support services or submit false reports.</p>
      <p>
          Committing acts of using automated means or AI to interfere with our assets, such as using scripts to send
          comments or messages, or using any data mining tools, robots, data extraction tools, and alike.
      </p>
      <p>
          Interfering with or creating interruption on the Site,
          the App, the Smart Contract, the networks or services related to the product.
      </p>
      <p>Attempting to impersonate another user or person; using the username of another user.</p>
      <p>Selling or transferring your game account.</p>
      <p>
          Using any information obtained from the Site, the App, and the Smart Contract
          in order to harass, abuse, or harm another person.
      </p>
      <p>
          Using the Site, the App, and the Smart Contract as a part of an effort to compete with us; using the Site,
          the App, and the Smart Contract or every product-related things commercial purposes.
      </p>
      <p>

          Deciphering, decompiling, disassembling, or interfering mechanically any of the software parts or assets
          of the Site, the App, and the Smart Contract.
      </p>
      <p>
          Attempting to bypass any measures designed to prevent or restrict access to the Site, the App, and the Smart Contract.
      </p>
      <p>
          Harassing, intimidating, or threatening our employees or agents whose jobs are to provide any portion of
          the Site, the App, and the Smart Contract to you directly.
      </p>
      <p>
          Deleting logos or marks representing the copyright or other proprietary rights from our product. Copying
          and making use of the Site’s frontend codes, including but not limited to Flash, PHP, HTML, JavaScript, and others.
      </p>
      <p>
          Uploading or transmitting viruses, Trojan codes, or anything alike, including excessive use
          of capital letters and spamming (continuous posting of repetitive text), that interfere with
          our product’s intentional original works. Modifying, impairing, disrupting, altering, or interfering
          with the intentional original uses, features, functions, operations, or acts of maintenance of the Site,
          the App, and the Smart Contract.
      </p>
      <p>All types of activities, all forms of action which pose as a law and regulation violation.</p>
      <h3>6. Trading, Purchasing and Fees</h3>
      <p>
          You can purchase our off-chain inconvertible virtual currency, STARCOIN, directly using fiat payments. Xsolla is a global distributor of STARCOINs.
          After purchasing STARCOINs, they can be used to purchase most items available in the game, including heroes, skins and other decorations. Some of these items will be NFTs. No refunds will be allowed for NFT purchases.
          There are two main asset types in Starfall Arena, Heroes and Skins. All HEROES are distinctively different in gameplay, move sets, special skills. There are currently 21 heroes and we will expand this number in the future. Skins are purely cosmetic complements to the Heroes. They give the Heroes a different look and visual effects without interfering with Hero power in any capacity.
          Players will be able to trade NFTs on the web-based website. Please note that wallet that all on-chain transactions are conducted solely through the blockchain via a designated wallet and currency. We have no control over the Blockchain, and in turn will not be able to provide any support or the ability to reverse any transactions. In general, we have no liability to you or to any third party for any alleged problems that arise during the alleged trades and transactions conducted via the Smart Contract, the Blockchain.
      </p>
      <p>
          The Blockchain requires the payment of a transaction fee for every transaction that occurs in the marketplace.
          The fee funds the network of computers that run the decentralized network rather than as profits.
      </p>
      <p>
          As a matter of fact, we (we and users) will be legally responsible for paying any types of cost, duty, and
          tax as your government requests. Such numbers will vary according to your jurisdictions and countries as
          well as the national laws and regulations.
      </p>
      <p>We have no liability and will provide no data, no support to you in such processes.</p>
      <h3>7. SUBMISSION</h3>
      <p>
          You should have a full understanding and an agreement that any question, comment, suggestion, idea,
          feedback or other forms of information regarding using the Site, the App and the Smart Contract (“Submissions”)
          provided by you to us are non-confidential and should eventually become our properties. We are eligible for
          the unrestricted use and of these Submissions to any lawful purposes, commercial activities without notifying
          you of our acknowledgment or compensation. You also should be aware of the discretion of any content-related
          you submit to the Site, the App, and the Smart Contract. Everything that poses a violation against the laws and
          regulations will be completely removed without notification.
      </p>
      <h3>8. THIRD-PARTY WEBSITE AND CONTENT</h3>
      <p>Third-party websites are the Site and/or App that links you to other websites.</p>
      <p>
          Third-party contents are articles, photographs, text, graphics, pictures, designs, music, sound, video,
          information, applications, software, and other content or items belonging to or originating from third parties.
      </p>
      <p>
          We are not responsible for any Terms of Use of any Third-Party Websites you accessed through the Site and/or
          the App, or any Third-Party Content posted on, available through, or installed from the Site and/or the App.
          We are not responsible for any of the following: content, accuracy, offensiveness, opinions, reliability,
          privacy practices, or other policies of or contained in the Third-Party Websites or the Third-Party Contents.
      </p>
      <p>
          If you decide to leave the Site and/or the App and access the Third-Party Websites or to use or install
          any Third-Party Content, you do so at your own risk and you should be aware that these Terms of Use you
          are reading are no longer effective. At this point, the Terms of Use of the Third-Party Websites you are
          using are effective. You should review the applicable terms and policies, including privacy and data
          gathering practices, of any website to which you navigate from the Site and/or the App or relating to
          any applications you use or install from the Site and/or the App.
      </p>
      <p>
          Any purchase you make through Third-Party Websites is no longer within our Terms of Use,
          so we are not responsible for any problem that arises from the purchase. You agree and acknowledge
          that we do not endorse the products and services offered on Third-Party Websites and you should hold
          us harmless from any harm caused by your purchase of such products and services.
      </p>
      <p>
          Additionally, any losses sustained by you or harm caused to you relating to resulting in any way from
          any Third-Party Content or any contact with Third-Party Websites are not our responsibilities.
      </p>
      <h3>9. ADVERTISEMENT</h3>
      <p>
          We provide areas within the Site and the App for advertisement and other information. Advertisers
          are fully responsible for the reliability of the advertisements, including the services and products.
          These advertisements should not violate any rules, and advertisers must prove that they have the following
          rights, but are not limited to, intellectual property rights, publicity rights, and contractual rights. We
          hold no responsibility if there are any violations regarding these rights.
      </p>
      <h3>10. TERMINATION</h3>
      <p>
          These Terms of Use remain in full force and effect while you use the Site, the App, and the Smart Contracts.
          We reserve the right and are able to conduct the following actions as of our own sole discretion and without
          informing you of any notice or liability: deny access to and use of the Site, the App, and the Smart Contracts
          (including blocking IP Addresses) to any person for breach of any presentation, warranty, or covenant contained
          in these Terms of Use. We may terminate your use or participation in the Site, the App, and the Smart Contracts
          or delete your account without warning, at our sole discretion.
      </p>
      <p>
          If we terminate or suspend your account for any reason, you are prohibited from registering and creating
          a new account under your name, a fake or a borrowed name, or the name of any third party, even if you may
          be acting on behalf of the third party. In addition to terminating and suspending your account, we reserve
          the right to take appropriate legal action, including without limitation pursuing civil, criminal,
          and injunctive redress.
      </p>
      <h3>11. DISCLAIMERS</h3>
      <p>
          You expressly understand and agree that your access to and use of the Site, the App, and the
          Smart Contracts are at your sole risk and that the Site, the App, and the Smart Contracts are
          provided “as is” and “as available” without warranties of any kind, whether express or implied.
          To the fullest extent permissible pursuant to applicable law, we, our subsidiaries, affiliates,
          and licensors make no express warranties and hereby disclaim all implied warranties regarding the
          site, the app and the smart contracts and any part of it (including, but not limited to, the site,
          any smart contract, or any external websites), including the implied warranties of merchantability,
          fitness for a particular purpose, non-infringement, correctness, accuracy, or reliability. Without
          limiting the generality of the foregoing, we, our subsidiaries, affiliates, and licensors do not
          represent or warrant to you that:
      </p>
      <p>(i) your access to or use of the Site, the App, and the Smart Contracts will meet your requirements,
      </p>
      <p>
          (ii) your access to or use of the Site, the App, and the Smart Contracts will be uninterrupted, timely, secure or free from error,
      </p>
      <p>(iii) usage data provided through the Site, the App, and the Smart Contracts will be accurate</p>
      <p>
          (iv) the Site, the App and the Smart Contracts or any content, services, or features made available on or through the Site,
          the App and the Smart Contracts are free of viruses or other harmful components, or
      </p>
      <p>
          (v) that any data that you disclose when you use the Site, the App, and the Smart Contracts will be secured.
          Some jurisdictions do not allow the exclusion of implied warranties in contracts with consumers, so some
          or all of the above exclusions may not apply to you.
      </p>
      <p>
          You accept the inherent security risks of providing information and dealing online over the Internet and agree
          that we have no liability or responsibility for any breach of security unless it is due to our gross negligence.
      </p>
      <p>
          We will not be responsible or liable to you for any losses you incur as the result of your use of the
          Blockchain, including but not limited to any losses, damages, or claims arising from:
      </p>
      <p>(a) user error, such as forgotten passwords or incorrectly construed smart contracts or other transactions;</p>
      <p>(b) server failure or data loss;
      </p>
      <p>(c) corrupted wallet files;</p>
      <p>
          (d) unauthorized access or activities by third parties, including but not limited to the use of viruses,
          phishing, brute-forcing, or other means of attack against the app, blockchain network, or crypto wallets.
      </p>
      <p>
          HEROES are intangible digital assets that exist only by virtue of the ownership record maintained on
          the Blockchain. All smart contracts are conducted and occur on the decentralized ledger within the
          Arbitrum Nova network. Starfall Arena Game has no control over and makes no guarantees or promises with
          respect to the smart contract. Starfall Arena is not responsible for losses due to the blockchains or any
          other features of the Blockchain. Including but not limited to late report by developers or representatives
          (or no report at all) of any issues with the blockchain including forks, technical node issues, or any other
          issues having fund losses as a result.
      </p>
      <h3>12. LIMITATION OF LIABILITY</h3>
      <p>
          You understand and agree that we, our subsidiaries, affiliates, and licensors will not be liable to you or
          to any third party for any indirect, incidental, special, consequential, or exemplary damages which you may
          have, including, but not limited to, any loss of profits (both directly and indirectly), loss of goodwill or
          business reputation, loss of data, cost of procurement of substitute goods or services, or any other
          intangible loss, even if we have been advised of the possibility of such damages.
      </p>
      <p>
          You agree and acknowledge that our total, aggregate liability to you for any and all claims arising
          out of or relating to these terms or your access to or use of (or your inability to access or use)
          any portion of the Site, the App, and the Smart Contracts, whether in contract, tort, strict liability,
          or any other legal theory, is limited to:
      </p>
      <p>
          (a) the amounts you actually paid us under these terms in the twelve (12) month period preceding the date the claim arose, or
      </p>
      <p>(b) one hundred (100) US dollars.</p>
      <p>
          You agree and acknowledge that we have made the Site, the App, and the Smart
          Contracts available to you and entered into these terms in reliance upon the
          warranty disclaimers and limitations of liability set forth herein, which reflect a
          reasonable and fair allocation of risk between the parties and form an essential basis of
          the bargain between us. We would not be able to provide the Site, the App, and the Smart
          Contracts to you without these limitations.
      </p>
      <p>
          Some jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, and
          some jurisdictions also limit disclaimers or limitations of liability for personal injury from consumer
          products, so the above limitations may not apply to personal injury claims.
      </p>
      <h3>13. ASSUMPTION OF RISK</h3>
      <p>You accept and acknowledge each of the following:</p>
      <p>
          (1). The prices of blockchain assets are easy to change because the fluctuations in the price of
          other digital assets could materially and adversely affect the value of your HEROES, which may
          also be subject to significant price volatility. We cannot guarantee that any purchasers of
          HEROES will not lose money.
      </p>
      <p>
          (2). You are solely responsible for any taxes apply to your HEROES-related transactions,
          according to your own country’s or region’s tax rules. Starfall Arena is not responsible
          for determining the taxes that apply to your transactions on the App, the Site, or the Smart Contract.
      </p>
      <p>
          (3). The App does not store, send, and receive HEROES. This is because HEROES exist only
          by virtue of the ownership record maintained on the App’s supporting blockchain. Any
          transfer of HEROES occurs only on the Blockchain.
      </p>
      <p>
          (4). There are risks and limitations associated with using an Internet-based currency,
          including, but not limited to, the risk of hardware, software, and Internet connections,
          the risk of malicious software introduction, and the risk that third parties may obtain
          unauthorized access to information stored within your wallet. You accept and acknowledge
          that Starfall Arena will not be responsible for any communication failures, disruptions,
          errors, distortions, or delays you may experience when using the Blockchain.
      </p>
      <p>
          (5). A lack of use or public interest in the creation and development of distributed ecosystems could
          negatively impact the development of the Starfall Arena ecosystem, and therefore the potential
          utility or value of HEROES.
      </p>
      <p>
          (6). The current state of regulations regarding blockchain technologies, cryptocurrencies,
          and tokens is uncertain. When new regulations or policies are introduced, they may materially
          adversely affect the development of the Starfall Arena ecosystem, and therefore the potential
          utility or value of HEROES.
      </p>
      <h3>14. INDEMNIFICATION</h3>
      <p>
          You agree to indemnify and defend us, including our affiliates and respective directors, officers, and personnel
          from and against all losses, costs, damages, expenses, and liabilities (including reasonable legal fees and
          disbursements) that may be suffered or incurred by Third-Party arising out of or as a result of or relating
          in any manner to a claim relating to:
      </p>
      <p>(1) use of the Site,</p>
      <p>(2) breach of these Terms of Use,</p>
      <p>(3) any breach of your representations and warranties set forth in these Terms of Use,</p>
      <p>
          (4) your violation of the rights of a third party, including but not limited to intellectual property rights, or
      </p>
      <p>
        ((5) any overt harmful act toward any other use of the Site, the App, and the Smart Contracts with whom
          you connected via the Site, the App, and the Smart Contracts.
      </p>
      <p>
          Notwithstanding the foregoing, we reserve the right, at your expense, to assume the exclusive
          defense and control of any matter for which you are required to indemnify us, and
          you agree to cooperate, at your expense, with our defense of such claims. We will use reasonable efforts to
          notify you of any such claim, action, or proceeding which is subject to this indemnification upon becoming
          aware of it.
      </p>
      <h3>15. USER DATA</h3>
      <p>
          Certain data, including your data use of the Site, the App, and the Smart Contracts, will be kept for
          us to manage the performance of the Site, the App, and the Smart Contracts. Although we perform regular
          routine backups of data, you are solely responsible for all data that you transmit or that release to
          any activity you have performed using the Site, the App, and the Smart Contracts. You agree that we shall
          have no liability to you for any loss or corruption of any such data, and you hereby waive any right of
          action against us arising from any such loss or corruption of such data.
      </p>
      <h3>16. MISCELLANEOUS</h3>
      <p>
          These Terms of Use and any policies or operating rules posted by us on the Site, the App, and
          the Smart Contracts, or in respect to the Site, the App, and the Smart Contracts constitute the
          entire agreement and understanding between you and us.
      </p>
      <p>
          Our failure to exercise or enforce any right or provision of these Terms of Use shall not operate as
          a waiver of such right or provision.
      </p>
      <p>
          These Terms of Use operate to the fullest extent permissible by law. We may assign any or all
          of our rights and obligations to others at any time. We shall not be responsible or liable for
          any loss, damage, delay, or failure to act caused by any cause beyond our reasonable control.
      </p>
      <p>
          If any provision or part of a provision of these Terms of Use is determined to be unlawful, void,
          and unenforceable, that particular provision is deemed separable from the whole Terms of Use and does
          not affect the validity and enforceability of any remaining provisions.
      </p>
      <p>
          There is no joint venture, partnership, employment, or agency relationship created between you and us
          as a result of these Terms of Use or use of the Site. You agree that these Terms of Use will not be
          construed against us by virtue of having drafted them.
      </p>
      <p>
          You hereby waive any and all defenses you may have based on the electronic form of these Terms of Use
          and the lack of signing by the parties hereto to execute these Terms of Use.
      </p>
    </div>
  );
};
