import { Matrix4, Texture, Vector2, WebGLRenderer } from 'three';

/**
 * Абстрактный класс частичной сцены
 */
export abstract class PartialScene {
  /**
   * Положение сцены
   * @protected
   */
  protected matrix: Matrix4 = new Matrix4().identity();

  protected background: Texture | null = null;

  /**
   * Обновление позиции
   */
  public setBaseMatrix(matrix: Matrix4) {
    this.matrix.copy(matrix);
  }

  public setBackground(background: Texture) {
    this.background = background;
  }

  /**
   * Включение сцены
   */
  public abstract enter(): void;

  /**
   * Выключение сцены
   */
  public abstract leave(): void;

  /**
   * Обновление логики
   * @param {number} delta
   * @param {number} time
   */
  public abstract update(delta: number, time: number): void;

  /**
   * Отрисовка кадра
   * @param {WebGLRenderer} renderer
   */
  public abstract render(renderer: WebGLRenderer): void;

  /**
   * Изменение размера блока
   * @param {Vector2} size
   */
  public abstract resize(size: Vector2): void;

  /**
   * Высвобождение ресурсов
   */
  public abstract dispose(): void;

  /**
   * Calculate zoom for aspect
   * @param {Vector2} size
   * @returns {number}
   * @protected
   */
  protected getZoom(size: Vector2) {
    if (size.x < size.y) {
      return (size.x / size.y - 1) * 0.3 + 1;
    }
    return 1;
  }
}
