import { Vector2, WebGLRenderer } from 'three';

/**
 * Абстрактный класс сцены
 */
export abstract class GraphicsScene {
  /**
   * Обновление логики
   * @param {number} delta
   */
  public abstract update(delta: number): void;

  /**
   * Отрисовка кадра
   * @param {WebGLRenderer} renderer
   */
  public abstract render(renderer: WebGLRenderer): void;

  /**
   * Изменение размера блока
   * @param {Vector2} size
   */
  public abstract resize(size: Vector2): void;

  /**
   * Высвобождение ресурсов
   */
  public abstract dispose(): void;
}
