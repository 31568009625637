import { LoadingOverlay } from 'src/components/visual/LoadingOverlay/LoadingOverlay';
import { Menu } from 'src/components/visual/Menu/Menu';
import { MenuOverlay } from 'src/components/visual/MenuOverlay/MenuOverlay';
import { NavBars } from 'src/components/visual/NavBars/NavBars';
import { NavProgress } from 'src/components/visual/NavProgress/NavProgress';
import { SideLine } from 'src/components/visual/SideLine/SideLine';
import { TurnDeviceOverlay } from 'src/components/visual/TurnDeviceOverlay/TurnDeviceOverlay';

export function Overlay() {
  return (
    <>
      <Menu />
      <NavBars />
      <NavProgress />
      <MenuOverlay />
      <SideLine />
      <SideLine mirror />
      <LoadingOverlay />
      <TurnDeviceOverlay />
    </>
  );
}
