import clsx from 'clsx';
import { ReactNode } from 'react';
import { SlidePage } from 'src/components/base/App/App';
import { useScrollState } from 'src/hooks/common/useScrollState';
import { ScreenRollHandler } from 'src/components/base/ScreenRoller/ScreenRollHandler';
import styles from './NavBars.module.scss';

export function NavBars() {
  const { normalizedScroll } = useScrollState();
  const current = Math.round(normalizedScroll);

  const items: ReactNode[] = [];
  for (let i = 0; i < SlidePage.Count; i++) {
    const onClick = () => {
      if (current !== i) {
        ScreenRollHandler.navigateTo(i);
      }
    };
    items.push(<em key={i} onClick={onClick} className={clsx(i === current && styles.active)} />);
  }

  return <div className={styles.bars}>{items}</div>;
}
