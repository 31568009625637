import { ShardCloud, ShardEnterType, ShardTexture } from 'src/graphics/entities/ShardCloud';
import { framedSlice, timeSlice } from 'src/graphics/helpers/Easings';
import { ShardsTexture } from 'src/resources/ShardsTexture';
import { ShardsVideoTexture } from 'src/resources/ShardsVideoTexture';
import {
  Euler,
  MathUtils,
  PerspectiveCamera,
  Quaternion,
  Scene,
  Texture,
  Vector2,
  Vector3,
  WebGLRenderer
} from 'three';
import { PartialScene } from 'src/graphics/scenes/partials/PartialScene';

interface ShardEntry {
  offset: number;
  position: Vector3;
  displace: Vector3[];
  id: number;
}

/**
 * Class for prologue scene
 */
export class PrologueScene extends PartialScene {
  /**
   * Active slide
   * @type {number}
   * @private
   */
  private static slide: number = 0;

  /**
   * Internal scene
   * @type {Scene}
   * @protected
   */
  private readonly scene: Scene;

  /**
   * Camera for scene
   * @type {PerspectiveCamera}
   * @private
   */
  private readonly camera: PerspectiveCamera;

  /**
   * All shards in scene
   * @type {ShardEntry[]}
   * @private
   */
  private readonly shards: ShardEntry[];

  /**
   * Shard cloud renderer
   * @type {ShardCloud}
   * @protected
   */
  private readonly cloud: ShardCloud;

  /**
   * Internal frame offset for damping
   * @type {number}
   * @private
   */
  private offset: number = 0;

  /**
   * Set active shards slide
   * @param {number} idx
   */
  public static setSlide(idx: number) {
    this.slide = idx;
  }

  /**
   * Scene constructor
   */
  public constructor() {
    super();
    this.scene = new Scene();
    this.camera = new PerspectiveCamera(60, 1, 0.1, 500);
    this.cloud = new ShardCloud();
    this.shards = [];

    const totalShards = 40;
    const positions: Vector3[] = [];
    const offsetX = 5;
    const offsetY = 3.5;

    // Generate texture quota
    const texQuota: { id: number; type: ShardTexture }[] = [];
    for (let i = 0; i < Math.max(ShardsTexture.count, ShardsVideoTexture.count); i++) {
      if (i < ShardsTexture.count) {
        texQuota.push({
          id: i,
          type: ShardTexture.Atlas
        });
      }
      if (i < ShardsVideoTexture.count) {
        texQuota.push({
          id: i,
          type: ShardTexture.Video
        });
      }
    }
    for (let i = texQuota.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [texQuota[i], texQuota[j]] = [texQuota[j], texQuota[i]];
    }

    // Generate shard positions and shuffle them
    for (let i = 0; i < totalShards; i++) {
      const pos = new Vector3(
        Math.sin((i / (totalShards - 1)) * Math.PI * 2) * offsetX, //
        Math.cos((i / (totalShards - 1)) * Math.PI * 2) * offsetY,
        MathUtils.lerp(-4, -9, Math.random())
      );
      positions.push(pos);
    }
    for (let i = positions.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [positions[i], positions[j]] = [positions[j], positions[i]];
    }

    // Create shard instances
    for (const pos of positions) {
      let size = 100;
      for (const otherPos of positions) {
        if (!pos.equals(otherPos)) {
          size = Math.min(size, pos.distanceTo(otherPos));
        }
      }
      const rotation = new Quaternion().setFromEuler(
        new Euler(
          (Math.random() - 0.5) * 2 * 0.5, //
          (Math.random() - 0.5) * 2 * 0.4,
          (Math.random() - 0.5) * 2 * 0.4
        )
      );

      // Take texture from texture lookup
      let tex = 0;
      let texType = ShardTexture.None;
      if (texQuota.length > 0) {
        const ent = texQuota.shift()!;
        tex = ent.id;
        texType = ent.type;
      }

      // Create shard instance
      this.shards.push({
        id: this.cloud.addShard(
          pos,
          rotation,
          size * (Math.random() * 0.2 + 0.8) * 0.7,
          texType,
          tex,
          ShardEnterType.Displace
        ),
        offset: Math.random(),
        position: pos,
        displace: Array(3)
          .fill(0)
          .map(() => new Vector3().randomDirection().multiplyScalar(0.1 + Math.random() * 0.9))
      });
    }

    // After shards generation, compile cloud
    this.cloud.compose();
  }

  /**
   * Enable scene for rendering
   */
  public enter() {
    this.scene.add(this.cloud.entity);
  }

  /**
   * Update scene logic
   * @param {number} delta
   * @param {number} time
   */
  public update(delta: number, time: number): void {
    this.scene.position.set(0, 0, 0);
    this.scene.rotation.set(0, 0, 0);
    this.scene.applyMatrix4(this.matrix);

    this.offset = MathUtils.damp(this.offset, PrologueScene.slide, 0.08, delta);
    const frame = Math.max(Math.floor(this.offset), 1);
    const lerp = this.offset - frame;

    for (const entry of this.shards) {
      const t = Math.max(time, 0) + framedSlice(timeSlice(time, -1, 0), entry.offset, 0.7);
      this.cloud.updateShardPosition(
        entry.id,
        entry.position.clone().add(entry.displace[frame].clone().lerp(entry.displace[frame + 1], lerp))
      );
      this.cloud.updateShardState(entry.id, t);
    }

    this.cloud.update(new Vector3(), delta);
  }

  /**
   * Render scene
   * @param {WebGLRenderer} renderer
   */
  public render(renderer: WebGLRenderer): void {
    renderer.render(this.scene, this.camera);
  }

  /**
   * Disable rendering for this scene
   */
  public leave() {
    this.scene.remove(this.cloud.entity);
  }

  /**
   * Release resources
   */
  public dispose(): void {}

  /**
   * Resize handler
   * @param {Vector2} size
   */
  public resize(size: Vector2): void {
    this.camera.zoom = this.getZoom(size);
    this.camera.aspect = size.x / size.y;
    this.camera.updateProjectionMatrix();
  }

  /**
   * Update scene background texture
   * @param {Texture} background
   */
  public setBackground(background: Texture) {
    super.setBackground(background);
    this.cloud.updateTextures(background, ShardsTexture.texture, ShardsVideoTexture.texture);
  }
}
