import styles from './IndexScreen.module.scss';

export function IndexScreen() {
  return (
    <div className={styles.screen}>
      <div className={styles.screen__text}>
        <em>
          Next
          <span>
            LAUNCHING <br /> SOON
          </span>
        </em>
        <em>Generation</em>
        <em className={styles.accent}>MOBA</em>
      </div>
    </div>
  );
}
