import { useEffect, useRef } from 'react';
import { SceneManager } from 'src/graphics/SceneManager';
import { LandingScene } from 'src/graphics/scenes/LandingScene';
import { SoundSystem } from 'src/graphics/sound/SoundSystem';
import { ShardsTexture } from 'src/resources/ShardsTexture';
import { ShardsVideoTexture } from 'src/resources/ShardsVideoTexture';
import { SpaceTexture } from 'src/resources/SpaceTexture';
import { addLoadingSteps, completeLoadingSteps } from 'src/store/loading';
import { SceneModels } from 'src/resources/SceneModels';
import styles from './Graphics.module.scss';

export function Graphics() {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (canvasRef.current && wrapperRef.current) {
      const manager = new SceneManager<LandingScene>(canvasRef.current, wrapperRef.current);
      addLoadingSteps();

      Promise.all([
        SpaceTexture.load(), //
        ShardsTexture.load(),
        ShardsVideoTexture.load(),
        SoundSystem.load(),
        SceneModels.load()
      ]).then(() => {
        completeLoadingSteps();
        const scene = new LandingScene();
        manager.initScene(scene);
      });

      return () => {
        ShardsVideoTexture.dispose();
        manager.dispose();
      };
    }
  }, []);

  return (
    <div ref={wrapperRef} className={styles.graphics}>
      <canvas ref={canvasRef} />
    </div>
  );
}
