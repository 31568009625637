import { Vector2 } from 'three';

/**
 * Class for camera displacement handling
 */
export class CameraDisplace {
  /**
   * Active position
   * @type {Vector2}
   * @private
   */
  private pos: Vector2;

  /**
   * Flag to ignore mousemove events
   * @type {boolean}
   * @private
   */
  private skipMouse: boolean;

  /**
   * Current orientation
   * @type {number}
   * @private
   */
  private orientation: number;

  /**
   * Constructor
   */
  public constructor() {
    this.pos = new Vector2();
    this.orientation = 0;
    this.skipMouse = false;
  }

  /**
   * Bind all event listeners
   */
  public bind() {
    this.mouseHandler = this.mouseHandler.bind(this);
    this.orientationHandler = this.orientationHandler.bind(this);
    this.modeHandler = this.modeHandler.bind(this);
    if (window.DeviceOrientationEvent) {
      window.addEventListener('deviceorientation', this.orientationHandler, {
        passive: true
      });
    }
    window.addEventListener('mousemove', this.mouseHandler);
    try {
      window.screen.orientation.addEventListener('change', this.modeHandler);
    } catch (ex) {
      //
    }
  }

  /**
   * Remove event listeners
   */
  public unbind() {
    window.removeEventListener('deviceorientation', this.orientationHandler);
    window.removeEventListener('mousemove', this.mouseHandler);
    try {
      window.screen.orientation.removeEventListener('change', this.modeHandler);
    } catch (ex) {
      //
    }
  }

  /**
   * Getter for current position
   * @returns {Vector2}
   */
  public get state() {
    return this.pos.clone();
  }

  /**
   * Wrapper for device motion events
   * @param {number} x
   * @param {number} y
   * @private
   */
  private handleTilt(x: number, y: number) {
    this.skipMouse = true;
    this.pos.set(x * 0.07, y * 0.04).rotateAround(new Vector2(), this.orientation - Math.PI * 0.5);
  }

  /**
   * Mousemove handler
   * @param {MouseEvent} event
   * @private
   */
  private mouseHandler(event: MouseEvent) {
    if (!this.skipMouse) {
      this.pos.set(
        (event.clientX / window.innerWidth) * 2 - 1, //
        (event.clientY / window.innerHeight) * 2 - 1
      );
    }
  }

  /**
   * DeviceOrientation handler
   * @param {DeviceOrientationEvent} event
   * @private
   */
  private orientationHandler(event: DeviceOrientationEvent) {
    this.handleTilt(event.beta!, event.gamma!);
  }

  /**
   * DeviceMotion handler
   * @param {DeviceMotionEvent} event
   * @private
   */
  private modeHandler() {
    this.orientation = (window.screen.orientation.angle / 180) * Math.PI;
    // this.handleTilt(event.acceleration!.x! * 2, event.acceleration!.y! * 2);
  }
}
