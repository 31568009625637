import { easeInOutQuad } from 'src/graphics/helpers/Easings';
import WheelIndicator from 'wheel-indicator';

interface Slide {
  element: HTMLDivElement;
  active: boolean;
  offset: number;
  height: number;
}

interface SlideEvents {
  leave: ScrollBlockEvent[];
  state: ScrollPageEvent[];
}

type ScrollBlockEvent = (direction: number) => boolean;
type ScrollPageEvent = (offset: number) => void;
type ScrollStateEvent = (page: number, scroll: number, offset: number, maxOffset: number, normalized: number) => void;

export class ScreenRollHandler {
  private slides: Slide[] = [];

  private static events: SlideEvents[] = [];

  private static scrollEvents: ScrollStateEvent[] = [];

  private static instance: ScreenRollHandler | null = null;

  private dirty: boolean = false;

  private rectsDirty: boolean = false;

  private enabled: boolean = false;

  private rafHandle: number = 0;

  private rafTime: number = 0;

  private offset: number = 0;

  private maxOffset: number = 0;

  private page: number = 0;

  private pageScroll: number = 0;

  private wheelFactor: number = 0;

  private transition: number = 0;

  private transitionFrom: number = 0;

  private transitionTo: number = 0;

  private transitionFast: boolean = false;

  private wheelIndicator: WheelIndicator | null = null;

  private touch: number | null = null;

  private touchScrollOrigin: number = 0;

  private touchOrigin: number = 0;

  private touchOriginSide: number = 0;

  private touchPosition: number = 0;

  private eventsBlocked: boolean = false;

  private scrollBlocked: boolean = true;

  public static addScrollListener(listener: ScrollStateEvent) {
    if (!this.scrollEvents.includes(listener)) {
      this.scrollEvents.push(listener);
    }
  }

  public static removeScrollListener(listener: ScrollStateEvent) {
    const idx = this.scrollEvents.indexOf(listener);
    if (idx !== -1) {
      this.scrollEvents.splice(idx, 1);
    }
  }

  public static addPageStateListener(page: number, listener: ScrollPageEvent) {
    if (!this.events[page]) {
      this.events[page] = {
        leave: [],
        state: []
      };
    }
    if (!this.events[page].state.includes(listener)) {
      this.events[page].state.push(listener);
    }
  }

  public static removePageStateListener(page: number, listener: ScrollPageEvent) {
    if (this.events[page]) {
      const idx = this.events[page].state.indexOf(listener);
      if (idx !== -1) {
        this.events[page].state.splice(idx, 1);
      }
    }
  }

  public static addPageBlockHandler(page: number, listener: ScrollBlockEvent) {
    if (!this.events[page]) {
      this.events[page] = {
        leave: [],
        state: []
      };
    }
    if (!this.events[page].leave.includes(listener)) {
      this.events[page].leave.push(listener);
    }
  }

  public static removePageBlockHandler(page: number, listener: ScrollBlockEvent) {
    if (this.events[page]) {
      const idx = this.events[page].leave.indexOf(listener);
      if (idx !== -1) {
        this.events[page].leave.splice(idx, 1);
      }
    }
  }

  public static navigateTo(page: number) {
    if (this.instance && this.instance.transition === 0) {
      this.instance.transition = 1;
      this.instance.transitionFrom = this.instance.offset;
      this.instance.transitionTo = this.instance.getOffsetFromPage(page, 0);
      this.instance.scrollBlocked = false;
    }
  }

  public static getNormalizedScroll() {
    if (this.instance) {
      if (this.instance.dirty) {
        this.instance.rebuildRects();
      }
      return (
        (this.instance.offset - this.instance.slides[this.instance.page].offset) /
          this.instance.slides[this.instance.page].height +
        this.instance.page
      );
    }
    return 0;
  }

  public constructor() {
    this.enabled = false;
  }

  public start() {
    if (!this.enabled) {
      ScreenRollHandler.instance = this;

      this.resizeHandler = this.resizeHandler.bind(this);
      window.addEventListener('resize', this.resizeHandler);
      this.resizeHandler();

      this.touchStartHandler = this.touchStartHandler.bind(this);
      this.touchMoveHandler = this.touchMoveHandler.bind(this);
      this.touchEndHandler = this.touchEndHandler.bind(this);
      window.addEventListener('touchstart', this.touchStartHandler);
      window.addEventListener('touchmove', this.touchMoveHandler);
      window.addEventListener('touchend', this.touchEndHandler);
      window.addEventListener('touchcancel', this.touchEndHandler);

      this.wheelHandler = this.wheelHandler.bind(this);
      this.wheelIndicator = new WheelIndicator({
        callback: this.wheelHandler
      });
      this.wheelIndicator.turnOn();

      this.rebuildRects();
      this.updateStyles();
      this.updatePageHandlers([0]);
      this.enabled = true;

      this.rafTime = performance.now();
      this.frameHandler = this.frameHandler.bind(this);
      this.frameHandler(this.rafTime);
      this.transition = 1;
      this.transitionFrom = 0;
      this.transitionTo = 0;
    }
  }

  public detach() {
    if (this.enabled) {
      ScreenRollHandler.instance = null;
      window.removeEventListener('resize', this.resizeHandler);
      window.removeEventListener('wheel', this.wheelHandler);
      cancelAnimationFrame(this.rafHandle);

      window.removeEventListener('touchstart', this.touchStartHandler);
      window.removeEventListener('touchmove', this.touchMoveHandler);
      window.removeEventListener('touchend', this.touchEndHandler);
      window.removeEventListener('touchcancel', this.touchEndHandler);

      if (this.wheelIndicator) {
        this.wheelIndicator.turnOff();
        this.wheelIndicator.destroy();
        this.wheelIndicator = null;
      }

      this.enabled = false;
    }
  }

  public setChild(index: number, child: HTMLDivElement) {
    this.slides[index] = {
      offset: 0,
      active: false,
      element: child,
      height: 0
    };
    this.dirty = true;
    this.rectsDirty = true;
  }

  public setBlocked(blocked: boolean) {
    this.eventsBlocked = blocked;
  }

  private rebuildRects() {
    this.maxOffset = 0;
    for (const slide of this.slides) {
      if (slide) {
        const rect = slide.element.getBoundingClientRect();
        slide.height = rect.height;
        slide.offset = this.maxOffset;
        this.maxOffset += slide.height;
      }
    }
    this.rectsDirty = false;
    this.dirty = true;
  }

  private updateStyles() {
    const max = window.innerHeight;
    let position = -this.offset;
    for (const slide of this.slides) {
      if (slide) {
        const visible = position < max && position > -slide.height;
        if (visible) {
          let delta = 0;
          if (this.offset < slide.offset) {
            delta = Math.max(((slide.offset - this.offset) / max) * -1.5, -1);
          } else if (this.offset > slide.offset + slide.height - max) {
            delta = Math.min(((this.offset - slide.offset - slide.height + max) / max) * 1.5, 1);
          }
          const scale = 1.0 + delta * 0.1;
          slide.element.style.opacity = `${1.0 - Math.abs(delta)}`;
          slide.element.style.transform = `scale3d(${scale}, ${scale}, ${scale})`;
          slide.element.style.pointerEvents = 'initial';
          slide.element.style.visibility = 'visible';
        } else {
          slide.element.style.transform = 'translateY(200vh)';
          slide.element.style.visibility = 'hidden';
          slide.element.style.pointerEvents = 'none';
        }
        position += slide.height;
      }
    }
    this.dirty = false;
  }

  private frameHandler(time: number) {
    this.rafHandle = requestAnimationFrame(this.frameHandler);
    const delta = (time - this.rafTime) / 16.666;
    this.rafTime = time;
    const screenH = window.innerHeight;

    if (this.rectsDirty) {
      this.rebuildRects();
    }

    if (this.wheelFactor != 0) {
      this.pageScroll += this.wheelFactor;
      this.wheelFactor = 0;
    }
    if (this.touch !== null) {
      this.pageScroll = this.touchScrollOrigin - (this.touchPosition - this.touchOrigin);
    }

    if (this.transition === 0) {
      let swapSlide = -1;
      if (this.pageScroll < 0) {
        this.pageScroll = 0;
        if (!this.isScrollBlocked(this.page, -1)) {
          if (this.page > 0) {
            swapSlide = this.page - 1;
          }
        } else {
          this.touch = null;
        }
      } else if (this.pageScroll + screenH > this.slides[this.page].height) {
        this.pageScroll = this.slides[this.page].height - screenH;
        if (!this.isScrollBlocked(this.page, 1)) {
          if (this.page < this.slides.length - 1) {
            swapSlide = this.page + 1;
          }
        } else {
          this.touch = null;
        }
      }
      if (swapSlide !== -1) {
        this.touch = null;
        this.transition = 1;
        this.transitionFrom = this.offset;
        this.transitionTo = this.slides[swapSlide].offset;
        this.transitionFast = false;
        if (this.page > swapSlide) {
          this.transitionTo += this.slides[swapSlide].height - window.innerHeight;
        }
      }
    }
    if (this.scrollBlocked) {
      this.page = 0;
      this.pageScroll = 0;
    }

    let off = this.slides[this.page].offset + this.pageScroll;
    if (this.transition > 0) {
      this.transition = Math.max(this.transition - (this.transitionFast ? 0.1 : 0.015) * delta, 0.0);
      off = this.transitionFrom + (this.transitionTo - this.transitionFrom) * easeInOutQuad(1.0 - this.transition);
      this.setPageFromOffset(off);
    }

    off = Math.min(Math.max(off, 0), this.maxOffset - window.innerHeight);
    if (this.offset !== off) {
      this.offset = off;
      this.dirty = true;
      const normalized = (this.offset - this.slides[this.page].offset) / this.slides[this.page].height + this.page;

      for (const handler of ScreenRollHandler.scrollEvents) {
        handler(this.page, this.pageScroll, this.offset, this.maxOffset - screenH, normalized);
      }

      const pagesToUpdate = [this.page];
      if (this.pageScroll > this.slides[this.page].height - screenH) {
        if (this.page + 1 < this.slides.length - 1) {
          pagesToUpdate.push(this.page + 1);
        }
      }
      this.updatePageHandlers(pagesToUpdate);
    }

    if (this.dirty) {
      this.updateStyles();
    }
  }

  private getOffsetFromPage(page: number, scroll: number) {
    return this.slides[page].offset + scroll;
  }

  private getCurrentOffset() {
    return this.slides[this.page].offset + this.pageScroll;
  }

  private setPageFromOffset(off: number) {
    for (let i = this.slides.length - 1; i >= 0; i--) {
      if (off >= this.slides[i].offset) {
        this.page = i;
        this.pageScroll = off - this.slides[i].offset;
        break;
      }
    }
  }

  private isScrollBlocked(page: number, dir: number) {
    const state: boolean[] = [];
    if (ScreenRollHandler.events[page]) {
      for (const handler of ScreenRollHandler.events[page].leave) {
        state.push(handler(dir));
      }
    }
    return state.includes(true);
  }

  private updatePageHandlers(pages: number[]) {
    for (const idx of pages) {
      if (ScreenRollHandler.events[idx]) {
        const state = (this.offset - this.slides[idx].offset) / this.slides[idx].height;
        for (const handler of ScreenRollHandler.events[idx].state) {
          handler(state);
        }
      }
    }
    for (let i = 0; i < this.slides.length; i++) {
      if (!pages.includes(i) && ScreenRollHandler.events[i]) {
        const state = this.slides[i].offset > this.offset ? -1 : 1;
        for (const handler of ScreenRollHandler.events[i].state) {
          handler(state);
        }
      }
    }
  }

  private resizeHandler() {
    this.dirty = true;
    this.rectsDirty = true;
  }

  private wheelHandler(event: WheelEvent) {
    if (this.transition > 0 || this.eventsBlocked) return;
    this.wheelFactor = event.deltaY;
    this.dirty = true;
    this.scrollBlocked = false;
  }

  private touchStartHandler(event: TouchEvent) {
    if (this.eventsBlocked) return;
    if (this.touch === null && this.transition === 0) {
      const t = this.findTouch(event);
      if (t) {
        this.touch = t.identifier;
        this.touchOriginSide = t.clientX;
        this.touchOrigin = this.touchPosition = t.clientY;
        this.touchScrollOrigin = this.pageScroll;
        this.scrollBlocked = false;
      }
    }
  }

  private touchMoveHandler(event: TouchEvent) {
    if (this.touch !== null) {
      const t = this.findTouch(event, this.touch);
      if (t) {
        if (Math.abs(t.clientX - this.touchOriginSide) < Math.abs(t.clientY - this.touchOrigin)) {
          this.touchPosition = t.clientY;
        }
      }
    }
  }

  private touchEndHandler(event: TouchEvent) {
    if (this.touch !== null) {
      const t = this.findTouch(event, this.touch);
      if (t) {
        this.touchPosition = t.clientY;
        this.touch = null;
      }
    }
  }

  private findTouch(event: TouchEvent, id: number | null = null) {
    for (let i = 0; i < event.changedTouches.length; i++) {
      if (id === null || event.changedTouches[i].identifier === id) {
        return event.changedTouches[i];
      }
    }
    return null;
  }
}
