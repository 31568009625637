import React from 'react';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import 'src/styles/index.scss';
import { Ads } from './components/base/Ads/Ads';
import { App } from './components/base/App/App';
import { Auction } from "./components/base/Auction/Auction";
import { Privacy } from './components/base/Privacy/Privacy';
import { Terms } from './components/base/Terms/Terms';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
if (window.location.host.toLowerCase().split('.').includes('mint')) {
  root.render(<Auction />);
} else {
  const router = createBrowserRouter([
    {
      path: '/',
      element: <App />
    },
    {
      path: '/privacy',
      element: <Privacy />
    },
    {
      path: '/tos',
      element: <Terms />
    },
    {
      path: '/app-ads.txt',
      element: <Ads />
    }
  ]);
  root.render(<RouterProvider router={router} />);
}
