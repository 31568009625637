import { MouseEvent } from 'react';
import { SlidePage } from 'src/components/base/App/App';
import { Logo } from 'src/components/base/Logo/Logo';
import { ScreenRollHandler } from 'src/components/base/ScreenRoller/ScreenRollHandler';
import { SoundIcon } from 'src/components/visual/SoundIcon/SoundIcon';
import { ScreenSize, useViewport } from 'src/hooks/common/useViewport';
import styles from './Menu.module.scss';

export function Menu() {
  const screen = useViewport();
  let leftMenu = null;
  let rightMenu = null;

  const goToDiscord = (e: MouseEvent) => {
    e.preventDefault();
    e.stopPropagation();
    ScreenRollHandler.navigateTo(SlidePage.Outro);
  };

  if (screen === ScreenSize.Desktop) {
    leftMenu = <></>;

    rightMenu = (
      <>
        {/*<a href="https://market.playstarfall.com/" target="_blank" className={styles.icon}>*/}
        {/*  <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
        {/*    <path*/}
        {/* eslint-disable-next-line max-len */}
        {/*      d="M10.9049 16.6318H23.9225C23.9225 16.6318 24.1434 17.9336 24.1434 19.8003C24.1434 21.667 23.9225 23.2634 23.9225 23.2634L22.4487 23.2634V17.3691H19.2557V23.2634L10.831 23.2634C10.831 23.2634 10.684 21.6668 10.684 19.8002C10.684 17.9335 10.9049 16.6318 10.9049 16.6318ZM17.5364 17.3691H12.6242V20.0709H17.5364V17.3691Z"*/}
        {/*      fill="white"*/}
        {/*    />*/}
        {/*    <path*/}
        {/* eslint-disable-next-line max-len */}
        {/*      d="M11.5456 10.123L11.518 10.1729C11.478 10.2455 11.4209 10.3509 11.3522 10.4819C11.2149 10.744 11.0318 11.108 10.8489 11.5155C10.6659 11.9234 10.4837 12.3732 10.3475 12.8073C10.2107 13.2432 10.1228 13.6554 10.1228 13.9903C10.1228 15.0741 10.4602 15.7852 10.6009 16.0347C10.6162 16.0618 10.6474 16.0811 10.6882 16.0811H13.0625C13.1188 16.0811 13.1679 16.0428 13.1816 15.9881L13.6272 14.2058L13.8654 14.2054L14.3167 15.9885C14.3305 16.043 14.3796 16.0811 14.4358 16.0811H16.7467C16.803 16.0811 16.8522 16.0428 16.8658 15.9881L17.3114 14.2058L17.5496 14.2054L18.001 15.9885C18.0148 16.043 18.0638 16.0811 18.12 16.0811H20.4309C20.4872 16.0811 20.5364 16.0428 20.55 15.9881L20.9956 14.2058L21.2338 14.2054L21.6852 15.9885C21.699 16.043 21.748 16.0811 21.8042 16.0811H24.1781C24.2192 16.0811 24.2507 16.0615 24.2659 16.0342C24.4053 15.7835 24.7377 15.0727 24.7377 13.9896C24.7377 13.6545 24.6498 13.2423 24.513 12.8065C24.3768 12.3723 24.1946 11.9226 24.0116 11.5149C23.8287 11.1075 23.6456 10.7437 23.5083 10.4817C23.4396 10.3508 23.3825 10.2454 23.3425 10.1729L23.3149 10.123H11.5456Z"*/}
        {/*      fill="white"*/}
        {/*    />*/}
        {/*  </svg>*/}
        {/*</a>*/}
        <a href="https://twitter.com/starfallarena" target="_blank" className={styles.icon}>
          <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M25 13.302C24.4111 13.5233 23.7785 13.6722 23.1145 13.7394C23.7927 13.3958 24.3126 12.8517 24.5576 12.203C23.9237 12.5211 23.221 12.7523 22.4727 12.8772C21.874 12.3374 21.0211 12 20.0771 12C18.2643 12 16.7948 13.2437 16.7948 14.7769C16.7948 14.9946 16.8243 15.207 16.8797 15.4095C14.1518 15.2939 11.7334 14.1881 10.1138 12.5076C9.83197 12.9178 9.67013 13.3947 9.67013 13.9044C9.67013 14.8675 10.2492 15.7177 11.1298 16.2154C10.5919 16.2008 10.0855 16.0759 9.64305 15.8681C9.64305 15.8801 9.64305 15.891 9.64305 15.903C9.64305 17.2488 10.7741 18.3712 12.2762 18.6258C12.0011 18.6893 11.7107 18.7232 11.411 18.7232C11.1999 18.7232 10.9938 18.7055 10.7938 18.6737C11.2116 19.7769 12.4239 20.5802 13.8601 20.6026C12.7371 21.3476 11.3218 21.7917 9.78335 21.7917C9.51875 21.7917 9.25722 21.7786 9 21.7526C10.4529 22.5403 12.1777 23 14.0318 23C20.0697 23 23.3705 18.7685 23.3705 15.0987C23.3705 14.9784 23.3674 14.8587 23.3613 14.7395C24.0031 14.3474 24.56 13.8586 25 13.302Z"
              fill="white"
            />
          </svg>
        </a>
        <a href="#" className={styles.icon} onClick={goToDiscord}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 34 34">
            <path
              fill="#fff"
              d="M22.5645 12.0188c-1.043-.4771-2.1508-.8247-3.307-1.0188-.1456.2507-.3073.5903-.4205.8571-1.2298-.1779-2.4508-.1779-3.6636 0-.1132-.2668-.283-.6064-.4205-.8571-1.1643.1941-2.2721.5417-3.3078 1.0188-2.09422 3.0887-2.66022 6.1047-2.37722 9.0802 1.39072 1.0107 2.73302 1.6252 4.05172 2.0295.3235-.4366.6145-.9056.8652-1.3988-.4771-.1779-.9299-.3962-1.3665-.655.1132-.0808.2264-.1697.3315-.2587 2.636 1.2048 5.491 1.2048 8.0946 0 .1132.089.2183.1779.3315.2587-.4366.2588-.8894.4771-1.3665.655.2507.4932.5418.9622.8652 1.3988 1.3179-.4043 2.6683-1.0188 4.0517-2.0295.3477-3.4445-.5506-6.4362-2.3618-9.0802Zm-8.2159 7.2447c-.7924 0-1.4392-.7196-1.4392-1.6009s.6307-1.601 1.4392-1.601c.8005 0 1.4554.7197 1.4393 1.601 0 .8813-.6388 1.6009-1.4393 1.6009Zm5.3132 0c-.7925 0-1.4401-.7196-1.4401-1.6009s.6315-1.601 1.4401-1.601c.8004 0 1.4554.7197 1.4392 1.601 0 .8813-.6307 1.6009-1.4392 1.6009Z"
            />
          </svg>
        </a>
        <a
          href="#"
          className={styles.icon}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <SoundIcon />
        </a>
      </>
    );
  } else {
    leftMenu = (
      <>
        <a
          href="#"
          className={styles.icon}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
          }}
        >
          <SoundIcon />
        </a>
        {/*<a href="https://market.playstarfall.com/" target="_blank" className={styles.icon}>*/}
        {/*  <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">*/}
        {/*    <path*/}
        {/* eslint-disable-next-line max-len */}
        {/*      d="M10.9049 16.6318H23.9225C23.9225 16.6318 24.1434 17.9336 24.1434 19.8003C24.1434 21.667 23.9225 23.2634 23.9225 23.2634L22.4487 23.2634V17.3691H19.2557V23.2634L10.831 23.2634C10.831 23.2634 10.684 21.6668 10.684 19.8002C10.684 17.9335 10.9049 16.6318 10.9049 16.6318ZM17.5364 17.3691H12.6242V20.0709H17.5364V17.3691Z"*/}
        {/*      fill="white"*/}
        {/*    />*/}
        {/*    <path*/}
        {/* eslint-disable-next-line max-len */}
        {/*      d="M11.5456 10.123L11.518 10.1729C11.478 10.2455 11.4209 10.3509 11.3522 10.4819C11.2149 10.744 11.0318 11.108 10.8489 11.5155C10.6659 11.9234 10.4837 12.3732 10.3475 12.8073C10.2107 13.2432 10.1228 13.6554 10.1228 13.9903C10.1228 15.0741 10.4602 15.7852 10.6009 16.0347C10.6162 16.0618 10.6474 16.0811 10.6882 16.0811H13.0625C13.1188 16.0811 13.1679 16.0428 13.1816 15.9881L13.6272 14.2058L13.8654 14.2054L14.3167 15.9885C14.3305 16.043 14.3796 16.0811 14.4358 16.0811H16.7467C16.803 16.0811 16.8522 16.0428 16.8658 15.9881L17.3114 14.2058L17.5496 14.2054L18.001 15.9885C18.0148 16.043 18.0638 16.0811 18.12 16.0811H20.4309C20.4872 16.0811 20.5364 16.0428 20.55 15.9881L20.9956 14.2058L21.2338 14.2054L21.6852 15.9885C21.699 16.043 21.748 16.0811 21.8042 16.0811H24.1781C24.2192 16.0811 24.2507 16.0615 24.2659 16.0342C24.4053 15.7835 24.7377 15.0727 24.7377 13.9896C24.7377 13.6545 24.6498 13.2423 24.513 12.8065C24.3768 12.3723 24.1946 11.9226 24.0116 11.5149C23.8287 11.1075 23.6456 10.7437 23.5083 10.4817C23.4396 10.3508 23.3825 10.2454 23.3425 10.1729L23.3149 10.123H11.5456Z"*/}
        {/*      fill="white"*/}
        {/*    />*/}
        {/*  </svg>*/}
        {/*</a>*/}
      </>
    );
    rightMenu = (
      <>
        <a href="https://twitter.com/starfallarena" target="_blank" className={styles.icon}>
          <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M25 13.302C24.4111 13.5233 23.7785 13.6722 23.1145 13.7394C23.7927 13.3958 24.3126 12.8517 24.5576 12.203C23.9237 12.5211 23.221 12.7523 22.4727 12.8772C21.874 12.3374 21.0211 12 20.0771 12C18.2643 12 16.7948 13.2437 16.7948 14.7769C16.7948 14.9946 16.8243 15.207 16.8797 15.4095C14.1518 15.2939 11.7334 14.1881 10.1138 12.5076C9.83197 12.9178 9.67013 13.3947 9.67013 13.9044C9.67013 14.8675 10.2492 15.7177 11.1298 16.2154C10.5919 16.2008 10.0855 16.0759 9.64305 15.8681C9.64305 15.8801 9.64305 15.891 9.64305 15.903C9.64305 17.2488 10.7741 18.3712 12.2762 18.6258C12.0011 18.6893 11.7107 18.7232 11.411 18.7232C11.1999 18.7232 10.9938 18.7055 10.7938 18.6737C11.2116 19.7769 12.4239 20.5802 13.8601 20.6026C12.7371 21.3476 11.3218 21.7917 9.78335 21.7917C9.51875 21.7917 9.25722 21.7786 9 21.7526C10.4529 22.5403 12.1777 23 14.0318 23C20.0697 23 23.3705 18.7685 23.3705 15.0987C23.3705 14.9784 23.3674 14.8587 23.3613 14.7395C24.0031 14.3474 24.56 13.8586 25 13.302Z"
              fill="white"
            />
          </svg>
        </a>
        <a href="#" className={styles.icon} onClick={goToDiscord}>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 34 34">
            <path
              fill="#fff"
              d="M22.5645 12.0188c-1.043-.4771-2.1508-.8247-3.307-1.0188-.1456.2507-.3073.5903-.4205.8571-1.2298-.1779-2.4508-.1779-3.6636 0-.1132-.2668-.283-.6064-.4205-.8571-1.1643.1941-2.2721.5417-3.3078 1.0188-2.09422 3.0887-2.66022 6.1047-2.37722 9.0802 1.39072 1.0107 2.73302 1.6252 4.05172 2.0295.3235-.4366.6145-.9056.8652-1.3988-.4771-.1779-.9299-.3962-1.3665-.655.1132-.0808.2264-.1697.3315-.2587 2.636 1.2048 5.491 1.2048 8.0946 0 .1132.089.2183.1779.3315.2587-.4366.2588-.8894.4771-1.3665.655.2507.4932.5418.9622.8652 1.3988 1.3179-.4043 2.6683-1.0188 4.0517-2.0295.3477-3.4445-.5506-6.4362-2.3618-9.0802Zm-8.2159 7.2447c-.7924 0-1.4392-.7196-1.4392-1.6009s.6307-1.601 1.4392-1.601c.8005 0 1.4554.7197 1.4393 1.601 0 .8813-.6388 1.6009-1.4393 1.6009Zm5.3132 0c-.7925 0-1.4401-.7196-1.4401-1.6009s.6315-1.601 1.4401-1.601c.8004 0 1.4554.7197 1.4392 1.601 0 .8813-.6307 1.6009-1.4392 1.6009Z"
            />
          </svg>
        </a>
      </>
    );
  }

  return (
    <div className={styles.menu}>
      <div className={styles.menu__left}>{leftMenu}</div>
      <div className={styles.menu__logo}>
        <Logo />
      </div>
      <div className={styles.menu__right}>{rightMenu}</div>
    </div>
  );
}
