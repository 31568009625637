import { ShardCloud, ShardEnterType, ShardTexture } from 'src/graphics/entities/ShardCloud';
import { framedSlice, timeSlice } from 'src/graphics/helpers/Easings';
import { ShardsTexture } from 'src/resources/ShardsTexture';
import { ShardsVideoTexture } from 'src/resources/ShardsVideoTexture';
import { Euler, PerspectiveCamera, Quaternion, Scene, Texture, Vector2, Vector3, WebGLRenderer } from 'three';
import { PartialScene } from './PartialScene';

interface ShardEntry {
  offset: number;
  position: Vector3;
  displace: Vector3[];
  id: number;
}

interface PositionEntry {
  pos: Vector3;
  rot: Euler;
  size: number;
  accent?: boolean;
}

const DESKTOP_POSITIONS: PositionEntry[] = [
  {
    pos: new Vector3(2, 3.3, -10),
    rot: new Euler(0, 0, 0.5),
    size: 4,
    accent: true
  },
  {
    pos: new Vector3(-4, 1, -6),
    rot: new Euler(0, 0, -0.2),
    size: 4
  },
  {
    pos: new Vector3(2, -2, -5),
    rot: new Euler(-0.8, 0, 0.6),
    size: 1,
    accent: false
  }
];

const MOBILE_POSITIONS: PositionEntry[] = [
  {
    pos: new Vector3(2.4, 2.9, -12),
    rot: new Euler(0, 0, 0.5),
    size: 2,
    accent: true
  },
  {
    pos: new Vector3(-2.2, 3.3, -8),
    rot: new Euler(0, 0, -0.2),
    size: 4
  },
  {
    pos: new Vector3(1, -2.5, -7),
    rot: new Euler(-0.8, 0, 0.6),
    size: 3,
    accent: false
  }
];

/**
 * Class for prologue scene
 */
export class PrologueScene extends PartialScene {
  /**
   * Internal scene
   * @type {Scene}
   * @protected
   */
  private readonly scene: Scene;

  /**
   * Camera for scene
   * @type {PerspectiveCamera}
   * @private
   */
  private readonly camera: PerspectiveCamera;

  /**
   * All shards in scene
   * @type {ShardEntry[]}
   * @private
   */
  private readonly shards: ShardEntry[];

  /**
   * Shard cloud renderer
   * @type {ShardCloud}
   * @protected
   */
  private readonly cloud: ShardCloud;

  /**
   * Scene constructor
   */
  public constructor() {
    super();
    this.scene = new Scene();
    this.camera = new PerspectiveCamera(60, 1, 0.1, 500);
    this.cloud = new ShardCloud();
    this.shards = [];

    // Generate texture quota
    const activeVideo = Math.floor(Math.random() * 3);
    const texQuota: { id: number; type: ShardTexture }[] = [];
    for (let i = 0; i < Math.max(ShardsTexture.count, ShardsVideoTexture.count); i++) {
      if (i < ShardsTexture.count) {
        texQuota.push({
          id: i,
          type: ShardTexture.Atlas
        });
      }
      if (i < ShardsVideoTexture.count && i !== activeVideo) {
        texQuota.push({
          id: i,
          type: ShardTexture.Video
        });
      }
    }
    for (let i = texQuota.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [texQuota[i], texQuota[j]] = [texQuota[j], texQuota[i]];
    }

    // Create shard instances
    const positions = DESKTOP_POSITIONS;
    for (const en of positions) {
      // Take texture from texture lookup
      let tex = 0;
      let texType = ShardTexture.None;
      if (positions.indexOf(en) === 1) {
        tex = activeVideo;
        texType = ShardTexture.Video;
      } else if (en.accent && texQuota.length > 0) {
        const ent = texQuota.shift()!;
        tex = ent.id;
        texType = ent.type;
      }

      // Create shard instance
      en.rot.order = 'ZXY';
      this.shards.push({
        id: this.cloud.addShard(
          en.pos,
          new Quaternion().setFromEuler(en.rot),
          en.size,
          texType,
          tex,
          ShardEnterType.Outline
        ),
        offset: Math.random(),
        position: en.pos,
        displace: Array(3)
          .fill(0)
          .map(() => new Vector3().randomDirection().multiplyScalar(0.1 + Math.random() * 0.9))
      });
    }

    // After shards generation, compile cloud
    this.cloud.compose();
  }

  /**
   * Enable scene for rendering
   */
  public enter() {
    this.scene.add(this.cloud.entity);
  }

  /**
   * Update scene logic
   * @param {number} delta
   * @param {number} time
   */
  public update(delta: number, time: number): void {
    this.scene.position.set(0, 0, 0);
    this.scene.rotation.set(0, 0, 0);
    this.scene.applyMatrix4(this.matrix);

    for (const entry of this.shards) {
      const t = Math.max(time, 0) + framedSlice(timeSlice(time, -1, 0), entry.offset, 0.7);
      this.cloud.updateShardState(entry.id, t);
    }
    this.cloud.update(new Vector3(), delta);
  }

  /**
   * Render scene
   * @param {WebGLRenderer} renderer
   */
  public render(renderer: WebGLRenderer): void {
    renderer.render(this.scene, this.camera);
  }

  /**
   * Disable rendering for this scene
   */
  public leave() {
    this.scene.remove(this.cloud.entity);
  }

  /**
   * Release resources
   */
  public dispose(): void {}

  /**
   * Resize handler
   * @param {Vector2} size
   */
  public resize(size: Vector2): void {
    this.camera.zoom = this.getZoom(size);
    this.camera.aspect = size.x / size.y;
    this.camera.updateProjectionMatrix();

    // Reposition shards
    const positions = this.camera.aspect < 1 ? MOBILE_POSITIONS : DESKTOP_POSITIONS;
    for (let i = 0; i < positions.length; i++) {
      positions[i].rot.order = 'ZXY';
      this.cloud.updateShardPosition(i, positions[i].pos, new Quaternion().setFromEuler(positions[i].rot));
    }
  }

  /**
   * Update scene background texture
   * @param {Texture} background
   */
  public setBackground(background: Texture) {
    super.setBackground(background);
    this.cloud.updateTextures(background, ShardsTexture.texture, ShardsVideoTexture.texture);
  }
}
