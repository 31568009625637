import StarsFragCode from 'src/graphics/shaders/moving_stars.frag.glsl';
import StarsVertCode from 'src/graphics/shaders/moving_stars.vert.glsl';
import {
  AdditiveBlending,
  BufferGeometry,
  Float32BufferAttribute,
  PerspectiveCamera,
  Points,
  Scene,
  ShaderMaterial,
  Vector3,
  WebGLRenderer
} from 'three';

/**
 * Moving stars for transition
 */
export class MovingStars {
  /**
   * Single block size in units
   * @type {number}
   * @private
   */
  private readonly BLOCK_SIZE = 8;

  /**
   * Number of stars in single block
   * @type {number}
   * @private
   */
  private readonly STARS_COUNT = 60;

  /**
   * Points meshes array
   * @type {Points[]}
   * @private
   */
  private readonly meshes: Points[];

  /**
   * Shader material
   * @type {ShaderMaterial}
   * @private
   */
  private readonly material: ShaderMaterial;

  /**
   * Points buffer geometry
   * @type {BufferGeometry}
   * @private
   */
  private readonly geometry: BufferGeometry;

  /**
   * Main scene
   * @type {Scene}
   * @private
   */
  private scene: Scene;

  /**
   * Initialize renderer
   */
  public constructor() {
    this.material = new ShaderMaterial({
      fragmentShader: StarsFragCode,
      vertexShader: StarsVertCode,
      depthTest: true,
      depthWrite: false,
      blending: AdditiveBlending,
      uniforms: {
        appear: {
          value: 1
        }
      }
    });
    this.geometry = this.buildGeometry();
    this.scene = new Scene();

    this.meshes = [];
    for (let i = 0; i < 27; i++) {
      const m = new Points(this.geometry, this.material);
      this.meshes.push(m);
      this.scene.add(m);
    }
  }

  /**
   * Update logic with camera position
   * @param {number} value
   * @param {Vector3} position
   */
  public update(value: number, position: Vector3) {
    this.material.uniforms.appear.value = Math.sin(value * Math.PI) * 0.7;
    const sx = Math.floor(position.x / this.BLOCK_SIZE) - 1.5;
    const sy = Math.floor(position.y / this.BLOCK_SIZE) - 1.5;
    const sz = Math.floor(position.z / this.BLOCK_SIZE) - 1.5;
    let idx = 0;
    for (let z = 0; z < 3; z++) {
      for (let y = 0; y < 3; y++) {
        for (let x = 0; x < 3; x++) {
          const m = this.meshes[idx];
          m.position.set(
            (sx + x) * this.BLOCK_SIZE, //
            (sy + y) * this.BLOCK_SIZE, //
            (sz + z) * this.BLOCK_SIZE //
          );
          m.updateMatrix();
          idx++;
        }
      }
    }
  }

  /**
   * Render scene
   * @param {WebGLRenderer} renderer
   * @param {PerspectiveCamera} camera
   */
  public render(renderer: WebGLRenderer, camera: PerspectiveCamera) {
    renderer.render(this.scene, camera);
  }

  /**
   * Build points geometry
   * @returns {BufferGeometry}
   * @private
   */
  private buildGeometry() {
    const positions: number[] = [];
    const scale: number[] = [];
    for (let i = 0; i < this.STARS_COUNT; i++) {
      positions.push(
        Math.random() * this.BLOCK_SIZE, //
        Math.random() * this.BLOCK_SIZE,
        Math.random() * this.BLOCK_SIZE
      );
      scale.push((4 + Math.random() * 4) * window.devicePixelRatio);
    }

    const geom = new BufferGeometry();
    geom.setAttribute('position', new Float32BufferAttribute(positions, 3));
    geom.setAttribute('scale', new Float32BufferAttribute(scale, 1));
    return geom;
  }
}
