import { forwardRef, useEffect, useRef, useState } from 'react';
import { CompetitionPhotosHandler } from './CompetitionPhotosHandler';
import styles from './CompetitionScreen.module.scss';
import { SLIDE_IMAGES, AUTOPLAY_VIDEOS, SlideEntry } from './PhotosAssets';

const sliceStart = Math.floor(((SLIDE_IMAGES.length - 4) * Math.random()) / 3) * 3;
const IMAGE_LINKS = SLIDE_IMAGES.slice(sliceStart, sliceStart + 4);
for (let i = IMAGE_LINKS.length - 1; i > 0; i--) {
  const j = Math.floor(Math.random() * (i + 1));
  [IMAGE_LINKS[i], IMAGE_LINKS[j]] = [IMAGE_LINKS[j], IMAGE_LINKS[i]];
}
const videoIndex = Math.floor(Math.random() * AUTOPLAY_VIDEOS.length);
// @ts-ignore
IMAGE_LINKS.splice(2, 0, { ...SLIDE_IMAGES[15], ...AUTOPLAY_VIDEOS[videoIndex] });

interface EntryProps {
  entry: SlideEntry;
  updateVideo: (visible: boolean) => void;
}

const ImageEntry = forwardRef<HTMLDivElement, EntryProps>(({ entry, updateVideo }, ref) => {
  const [active, setActive] = useState(false);

  useEffect(() => {
    updateVideo(active);
  }, [active]);

  const onEnter = () => {
    setActive(true);
  };

  const onLeave = () => {
    setActive(false);
  };

  return (
    <div ref={ref} className={styles.screen__entry} onMouseEnter={onEnter} onMouseLeave={onLeave}>
      <img src={entry.image} alt={''} />
      {entry.video && (
        <video src={entry.video} playsInline={true} muted={true} autoPlay={true} controls={false} loop={true} />
      )}
    </div>
  );
});

export function CompetitionScreen() {
  const handlerRef = useRef<CompetitionPhotosHandler | null>(null);

  useEffect(() => {
    const resizeHandler = () => {
      handlerRef.current?.buildLayout();
    };
    window.addEventListener('resize', resizeHandler);
    resizeHandler();
    handlerRef.current?.start();

    return () => {
      window.removeEventListener('resize', resizeHandler);
      handlerRef.current?.dispose();
      handlerRef.current = null;
    };
  }, []);

  const setImageRef = (element: HTMLDivElement | null, index: number) => {
    if (!handlerRef.current) {
      handlerRef.current = new CompetitionPhotosHandler(IMAGE_LINKS.length);
    }
    handlerRef.current!.setElement(index, element);
    handlerRef.current!.setIsOnlyVideo(2);
  };

  const updateActive = (index: number, active: boolean) => {
    handlerRef.current?.setActive(index, active);
  };

  return (
    <div className={styles.screen}>
      {IMAGE_LINKS.map((entry, index) => (
        <ImageEntry
          entry={entry}
          key={`${index}_${entry.image}`}
          ref={(elem) => setImageRef(elem, index)}
          updateVideo={(active: boolean) => {
            updateActive(index, active);
          }}
        />
      ))}
    </div>
  );
}
