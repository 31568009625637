import { useStore } from 'effector-react';
import React, { useEffect } from 'react';
import { Overlay } from 'src/components/base/Overlay/Overlay';
import { ScreenRoller } from 'src/components/base/ScreenRoller/ScreenRoller';
import { CompetitionScreen } from 'src/components/screens/CompetitionScreen/CompetitionScreen';
import { GameplayScreen } from 'src/components/screens/GameplayScreen/GameplayScreen';
import { IndexScreen } from 'src/components/screens/IndexScreen/IndexScreen';
import { LoreScreen } from 'src/components/screens/LoreScreen/LoreScreen';
import { OutroScreen } from 'src/components/screens/OutroScreen/OutroScreen';
import { PrologueScreen } from 'src/components/screens/PrologueScreen/PrologueScreen';
import { Graphics } from 'src/components/visual/Graphics/Graphics';
import { IndexScene } from 'src/graphics/scenes/partials/IndexScene';
import { useViewportUpdate } from 'src/hooks/common/useViewportUpdate';
import { $loadedStore } from 'src/store/loading';
import { $menuActiveStore } from 'src/store/menu';
import { $soundSelectedStore } from 'src/store/sound';

export enum SlidePage {
  Index,
  Prologue,
  Gameplay,
  Lore,
  Competition,
  Outro,
  Count
}

export const SlideIndexes: { name: string; index: SlidePage }[] = [
  {
    name: 'Gameplay',
    index: SlidePage.Gameplay
  },
  {
    name: 'Lore',
    index: SlidePage.Lore
  },
  {
    name: 'Competition',
    index: SlidePage.Competition
  }
];

export function App() {
  const loaded = useStore($loadedStore);
  const sound = useStore($soundSelectedStore);
  const menu = useStore($menuActiveStore);

  useViewportUpdate();
  useEffect(() => {
    if (loaded && sound) {
      setTimeout(() => {
        IndexScene.toggleAnimation(true);
      }, 1000);
    }
  }, [loaded, sound]);

  return (
    <>
      <Graphics />
      <ScreenRoller blocked={!loaded || !sound || menu.isOpen}>
        <IndexScreen />
        <PrologueScreen />
        <GameplayScreen />
        <LoreScreen />
        <CompetitionScreen />
        <OutroScreen />
      </ScreenRoller>
      <Overlay />
    </>
  );
}
