import { useMemo, useState } from "react";
import { Button } from "../../base/Button/Button";
import styles from './BidTable.module.scss';

interface BidTableRow {
  rank: number,
  bid: string,
  name: string,
  boxes: number,
  refund: string,
}

const TEST_DATA = Array(32).fill(0).map((_, idx) => {
  return {
    rank: idx + 1,
    bid: Math.random().toFixed(3),
    name: 'testbid'+idx+'.eth',
    boxes: Math.ceil(Math.random() * 100),
    refund: Math.random().toFixed(3)
  }
})

const PAGE_SIZE = 10;

export const BidTable = () => {
  const losingBids = 534; // TODO: Store
  const winningBids = TEST_DATA; // TODO: Store

  const [visiblePages, setVisiblePages] = useState(1);
  const items = useMemo(() => {
    return winningBids.slice(0, PAGE_SIZE * visiblePages);
  }, [visiblePages, winningBids]);

  const showMoreBids = () => {
    if (visiblePages * PAGE_SIZE < winningBids.length) {
      setVisiblePages(visiblePages + 1);
    }
  };

  return <div className={styles.block}>
    <div className={styles.block__table}>
      <div className={styles.block__head}>
        <div>Rank</div>
        <div>Eth Bid</div>
        <div>Bidder</div>
        <div>Boxes</div>
        <div>Refund</div>
      </div>
      <div className={styles.block__rows}>
        {items.map(item => <div key={item.name} className={styles.row}>
            <div className={styles.gray}>{item.rank.toString().padStart(3, '0')}</div>
            <div>{item.bid}</div>
            <div>{item.name}</div>
            <div className={styles.green}>{item.boxes}</div>
            <div>{item.refund}</div>
          </div>
        )}
      </div>
    </div>
    <div className={styles.block__footer}>
      { visiblePages * PAGE_SIZE < winningBids.length && <Button onClick={showMoreBids}>+ {winningBids.length - visiblePages * PAGE_SIZE} more bids winning</Button> }
      { losingBids > 0 && <span>{losingBids} not winning</span> }
    </div>
  </div>;
};
