import { easeOutCubic, framedSlice } from 'src/graphics/helpers/Easings';
import { FrameHandler } from 'src/graphics/helpers/FrameHandler';
import { MathUtils } from 'three';

interface ShardPath {
  path: Path2D;
  centerX: number;
  centerY: number;
  time: number;
  angle: number;
  offsetX: number;
  offsetY: number;
}

interface Point {
  x: number;
  y: number;
}

export class SideSliderHandler {
  private readonly CHANGE_DELAY = 1500;

  private canvas: HTMLCanvasElement;

  private images: HTMLImageElement[];

  private index: number;

  private transition: number;

  private transitionTo: number;

  private cycle: boolean;

  private cycleTimer: number;

  private handler: FrameHandler;

  private needPaint: boolean;

  private shards: ShardPath[] | null = null;

  public constructor(canvas: HTMLCanvasElement, images: string[]) {
    this.canvas = canvas;
    this.index = 0;
    this.transition = 0;
    this.transitionTo = 0;
    this.cycle = false;
    this.cycleTimer = 0;
    this.needPaint = true;
    this.images = images.map((src) => {
      const img = new Image();
      img.onload = () => {
        this.needPaint = true;
      };
      img.src = src;
      return img;
    });
    this.handler = new FrameHandler(this.frameHandle.bind(this));
    this.handler.start();
  }

  public setActive(state: boolean) {
    this.cycle = state;
    this.cycleTimer = this.CHANGE_DELAY;
  }

  public dispose() {
    this.handler.stop();
  }

  private frameHandle(delta: number) {
    const width = Math.floor(window.innerWidth * window.devicePixelRatio);
    const height = Math.floor(window.innerHeight * window.devicePixelRatio);
    let needRepaint = this.needPaint;
    if (this.shards === null || this.canvas.width !== width || this.canvas.height !== height) {
      this.rebuildShards(width, height);
      this.canvas.width = width;
      this.canvas.height = height;
      needRepaint = true;
    }

    if (this.cycle && this.transition === 0) {
      this.cycleTimer = Math.max(this.cycleTimer - delta * 16.66, 0);
      if (this.cycleTimer === 0) {
        this.transitionTo = (this.index + 1) % this.images.length;
        this.transition = 1;
        this.rebuildShards(width, height);
      }
    }

    if (this.transition > 0) {
      needRepaint = true;
      this.transition = Math.max(this.transition - 0.013 * delta, 0);
      if (this.transition === 0) {
        this.index = this.transitionTo;
        this.cycleTimer = this.CHANGE_DELAY;
      }
    }

    this.needPaint = false;
    if (needRepaint) {
      const g = this.canvas.getContext('2d')!;
      g.resetTransform();
      this.drawFrame(g, this.index, width, height);

      if (this.transition > 0) {
        const time = 1.0 - this.transition;
        if (this.shards) {
          for (const shard of this.shards) {
            const stime = easeOutCubic(framedSlice(time, shard.time, 0.2));
            const invTime = 1.0 - stime;
            const scale = MathUtils.lerp(0.5, 1, stime);
            if (stime <= 0) {
              continue;
            }
            g.save();
            g.resetTransform();
            g.translate(shard.centerX, shard.centerY);
            g.rotate(shard.angle * invTime);
            g.scale(scale, scale);
            g.translate(-shard.centerX + shard.offsetX * invTime, -shard.centerY + shard.offsetY * invTime);
            // g.translate(-shard.centerX, -shard.centerY);

            g.clip(shard.path);

            this.drawFrame(g, this.transitionTo, width, height, stime);

            g.restore();
          }
        }
      }
    }
  }

  private drawFrame(g: CanvasRenderingContext2D, index: number, width: number, height: number, alpha: number = 1) {
    const img = this.images[index];
    if (!img.complete) return;

    const scale = Math.max(width / img.width, height / img.height);
    const imageWidth = img.width * scale;
    const imageHeight = img.height * scale;

    g.globalAlpha = alpha;
    g.drawImage(img, width / 2 - imageWidth / 2, height / 2 - imageHeight / 2, imageWidth, imageHeight);
  }

  private rebuildShards(width: number, height: number) {
    const shardsY = 8;
    const shardsX = Math.floor((width / height) * shardsY);
    const sizeX = width / shardsX;
    const sizeY = height / shardsY;
    const grid: Point[][] = [];
    const wh = width / 2;
    const hh = height / 2;

    for (let y = 0; y <= shardsY; y++) {
      const row: Point[] = [];
      for (let x = 0; x <= shardsX; x++) {
        const point: Point = {
          x: x * sizeX,
          y: y * sizeY
        };
        if (x !== 0 && x !== shardsX) {
          point.x += (Math.random() * 2 - 1) * sizeX * 0.3;
        }
        if (y !== 0 && y !== shardsY) {
          point.y += (Math.random() * 2 - 1) * sizeY * 0.3;
        }
        row.push(point);
      }
      grid.push(row);
    }

    const shards: ShardPath[] = [];
    for (let y = 0; y < shardsY; y++) {
      for (let x = 0; x < shardsX; x++) {
        const p1 = grid[y][x];
        const p2 = grid[y][x + 1];
        const p3 = grid[y + 1][x];
        const p4 = grid[y + 1][x + 1];

        const centerX = (p1.x + p2.x + p3.x + p4.x) / 4;
        const centerY = (p1.y + p2.y + p3.y + p4.y) / 4;
        const time = MathUtils.clamp(
          1.0 - Math.hypot((centerX - wh) / wh, (centerY - hh) / hh) * 0.8 + Math.random() * 0.2,
          0,
          1
        );

        const path = new Path2D(
          [
            `M ${p1.x - 1} ${p1.y - 1}`, //
            `L ${p2.x + 1} ${p2.y - 1}`,
            `L ${p4.x + 1} ${p4.y + 1}`,
            `L ${p3.x - 1} ${p3.y + 1}`,
            `Z`
          ].join(' ')
        );
        shards.push({
          path,
          centerX,
          centerY,
          time: time,
          angle: Math.random() - 0.5,
          offsetX: (Math.random() - 0.5) * sizeX,
          offsetY: (Math.random() - 0.5) * sizeY
        });
      }
    }
    this.shards = shards;
  }
}
