import { Material, Mesh, MeshBasicMaterial, OrthographicCamera, PlaneGeometry, Texture, WebGLRenderer } from 'three';

let mesh: Mesh | null = null;
let material: MeshBasicMaterial | null = null;
const camera = new OrthographicCamera(-0.5, 0.5, 0.5, -0.5, -1, 1);

export function quadPass(renderer: WebGLRenderer, overlay: Texture | Material) {
  if (!material) {
    material = new MeshBasicMaterial({
      map: null,
      depthTest: false,
      depthWrite: false,
      transparent: true
    });
  }
  if (!mesh) {
    mesh = new Mesh(new PlaneGeometry());
  }
  if ('isMaterial' in overlay && overlay.isMaterial) {
    mesh.material = overlay as Material;
  } else {
    mesh.material = material;
    material.map = overlay as Texture;
  }
  renderer.render(mesh, camera);
}
