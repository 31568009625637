import { BidTable } from "../BidTable/BidTable";
import styles from './AuctionRightPanel.module.scss';

export const AuctionRightPanel = () => {
  return <div className={styles.block}>

    <div className={styles.block__live}>
      <em>
        <i />
        <i />
        <i />
      </em>
      LIVE: UPD Today at 11:39 AM
    </div>
    <BidTable />

  </div>;
};
