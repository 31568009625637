import clsx from "clsx";
import { ButtonHTMLAttributes, ReactNode } from "react";
import styles from './Button.module.scss';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode
  compact?: boolean
}

export const Button = ({ children, className, compact, ...rest }: ButtonProps) => {
  return <button className={clsx(styles.button, compact && styles.compact, className)} {...rest}>
    {children}
  </button>;
};
