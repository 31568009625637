import AtlasConfig from 'src/assets/textures/shards/atlas.json';
import AtlasBigFallback from 'src/assets/textures/shards/shards.jpg';
import AtlasBig from 'src/assets/textures/shards/shards.ktx2';
import AtlasSmallFallback from 'src/assets/textures/shards/shards_small.jpg';
import AtlasSmall from 'src/assets/textures/shards/shards_small.ktx2';
import { SceneManager } from 'src/graphics/SceneManager';
import { TextureLoader } from 'src/graphics/TextureLoader';
import { Texture, Vector2 } from 'three';
import { addLoadingSteps, completeLoadingSteps } from 'src/store/loading';

const ATLAS_SIZE = 4096;

interface AtlasCoord {
  start: Vector2;
  size: Vector2;
}

/**
 * Shard texture manager
 */
export class ShardsTexture {
  /**
   * Internal loaded texture
   * @type {Texture}
   * @private
   */
  private static tex: Texture;

  /**
   * Atlas frames coordinates
   * @type {AtlasCoord[]}
   * @private
   */
  private static coords: AtlasCoord[];

  /**
   * Loading texture depending on device capabilities
   * @returns {Promise<void>}
   */
  public static async load() {
    let fallback = false;
    const renderer = SceneManager.getRenderer();
    if (
      (renderer && renderer.capabilities.maxTextureSize < 4096) ||
      window.innerWidth * window.devicePixelRatio < 500
    ) {
      fallback = true;
    }
    addLoadingSteps();
    if (fallback) {
      this.tex = await TextureLoader.load(AtlasSmall, AtlasSmallFallback);
    } else {
      this.tex = await TextureLoader.load(AtlasBig, AtlasBigFallback);
    }
    if (renderer) {
      renderer.initTexture(this.tex);
    }

    // Calculating texture offsets
    this.coords = AtlasConfig.map((raw): AtlasCoord => {
      return {
        start: new Vector2(raw.x / ATLAS_SIZE, raw.y / ATLAS_SIZE),
        size: new Vector2(raw.width / ATLAS_SIZE, raw.height / ATLAS_SIZE)
      };
    });
    completeLoadingSteps();
  }

  /**
   * Get atlas texture
   * @returns {Texture}
   */
  public static get texture() {
    return this.tex;
  }

  /**
   * Total number of textures
   * @returns {number}
   */
  public static get count() {
    return this.coords.length;
  }

  /**
   * Fetch texture offsets from index
   * @param {number} index
   * @returns {readonly [Vector2, Vector2]}
   */
  public static getOffset(index: number) {
    return [this.coords[index].start, this.coords[index].size] as const;
  }
}
