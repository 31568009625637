import { BufferGeometry, Float32BufferAttribute, Uint16BufferAttribute, Vector3 } from 'three';

/**
 * Quantize position into bounds
 * @param {number} min
 * @param {number} max
 * @param {number} value
 * @returns {number}
 */
function compressPosition(min: number, max: number, value: number) {
  return Math.round(((value - min) / (max - min)) * 65535.0);
}

/**
 * Compress normal into lat-lon coords
 * @param {number} x
 * @param {number} y
 * @param {number} z
 * @returns {readonly [number, number]}
 */
function compressNormal(x: number, y: number, z: number) {
  return [((Math.atan2(z, x) * 255) / (Math.PI * 2)) & 255, ((Math.acos(y) * 255) / (Math.PI * 2)) & 255] as const;
}

/**
 * Loaded buffers
 */
export interface GeometryBuffers {
  position: Float32BufferAttribute | null;
  normal: Float32BufferAttribute | null;
  uv: Float32BufferAttribute | null;
  indices: Uint16BufferAttribute | null;
}

/**
 * Load encoded geometry
 * @param {string} path
 * @returns {Promise<GeometryBuffers>}
 */
export async function loadCompressedGeometry(path: string): Promise<GeometryBuffers> {
  const response = await fetch(path);
  const buffer = await response.arrayBuffer();
  const f = new DataView(buffer);

  const positions: number[] = [];
  const normals: number[] = [];
  const uv: number[] = [];
  const indices: number[] = [];

  const flags = f.getUint32(0, true);
  const hasPositions = (flags & 1) > 0;
  const hasNormals = (flags & 2) > 0;
  const hasUV = (flags & 4) > 0;
  const hasIndices = (flags & 8) > 0;

  let pos = 4;
  const vertCount = f.getUint16(pos, true);
  pos += 2;
  const bounds = [];
  for (let idx = 0; idx < 6; idx++) {
    bounds.push(f.getFloat32(pos, true));
    pos += 4;
  }

  // Position decoding
  if (hasPositions) {
    for (let idx = 0; idx < vertCount * 3; idx++) {
      const min = bounds[idx % 3];
      const max = bounds[(idx % 3) + 3];
      positions.push(min + (max - min) * (f.getUint16(pos, true) / 65535.0));
      pos += 2;
    }
  }

  // Decoding normals
  if (hasNormals) {
    for (let idx = 0; idx < vertCount; idx++) {
      const lat = (f.getUint8(pos) * Math.PI * 2) / 255.0;
      const lng = (f.getUint8(pos + 1) * Math.PI * 2) / 255.0;
      pos += 2;
      normals.push(Math.cos(lat) * Math.sin(lng), Math.cos(lng), Math.sin(lat) * Math.sin(lng));
    }
  }

  // Decoding UV
  if (hasUV) {
    for (let idx = 0; idx < vertCount; idx++) {
      uv.push(f.getFloat32(pos, true), f.getFloat32(pos + 4, true));
      pos += 8;
    }
  }

  // Decode indices
  if (hasIndices) {
    const idxCount = f.getUint16(pos, true);
    pos += 2;
    for (let idx = 0; idx < idxCount; idx++) {
      indices.push(f.getUint16(pos, true));
      pos += 2;
    }
  }

  // Выдача буфферов
  return {
    position: hasPositions ? new Float32BufferAttribute(positions, 3, false) : null,
    normal: hasNormals ? new Float32BufferAttribute(normals, 3, true) : null,
    uv: hasUV ? new Float32BufferAttribute(uv, 2, false) : null,
    indices: hasIndices ? new Uint16BufferAttribute(indices, 1, false) : null
  };
}

/**
 * Compress geometry
 * @param {BufferGeometry} source
 * @param {boolean} save
 * @returns {ArrayBuffer}
 */
export function compressGeometry(source: BufferGeometry, save: boolean = false): ArrayBuffer {
  const positionAttrib = source.getAttribute('position');
  const normalAttrib = source.getAttribute('normal');
  const uvAttrib = source.getAttribute('uv');
  const indexAttrib = source.getIndex();

  const rawPositions = positionAttrib.array!;
  const rawUV = uvAttrib ? uvAttrib.array : null;
  const rawNormals = normalAttrib ? normalAttrib.array : null;
  const rawIndices = indexAttrib ? indexAttrib.array : null;

  const positions: number[] = [];
  const normals: number[] = [];
  const uvs: number[] = [];
  const indices: number[] = [];

  // Compute bounding box
  const minBox = new Vector3(99999, 99999, 99999);
  const maxBox = new Vector3(-99999, -99999, -99999);
  for (let idx = 0; idx < rawPositions.length; idx += 3) {
    minBox.x = Math.min(minBox.x, rawPositions[idx]);
    minBox.y = Math.min(minBox.y, rawPositions[idx + 1]);
    minBox.z = Math.min(minBox.z, rawPositions[idx + 2]);
    maxBox.x = Math.max(maxBox.x, rawPositions[idx]);
    maxBox.y = Math.max(maxBox.y, rawPositions[idx + 1]);
    maxBox.z = Math.max(maxBox.z, rawPositions[idx + 2]);
  }

  // Transform vertices
  for (let idx = 0; idx < rawPositions.length; idx += 3) {
    positions.push(
      compressPosition(minBox.x, maxBox.x, rawPositions[idx]),
      compressPosition(minBox.y, maxBox.y, rawPositions[idx + 1]),
      compressPosition(minBox.z, maxBox.z, rawPositions[idx + 2])
    );
  }

  // Transform normals
  if (rawNormals) {
    for (let idx = 0; idx < rawNormals.length; idx += 3) {
      normals.push(...compressNormal(rawNormals[idx], rawNormals[idx + 1], rawNormals[idx + 2]));
    }
  }

  // Transform UV
  if (rawUV) {
    for (let idx = 0; idx < rawUV.length; idx++) {
      uvs.push(rawUV[idx]);
    }
  }

  // Transforming indices
  if (rawIndices) {
    for (let idx = 0; idx < rawIndices.length; idx++) {
      indices.push(rawIndices[idx]);
    }
  }

  // Saving mesh
  const hasPositions = true;
  const hasNormals = normals.length > 0;
  const hasUV = uvs.length > 0;
  const hasIndices = indices.length > 0;
  let bufferSize =
    4 + // Flags buffer
    2 + // Vertex count
    4 * 6 + // Bounding box dimensions Float x6
    positions.length * 2; // Positions in Uint16

  if (hasNormals) {
    bufferSize += normals.length; // Normals count in UInt8
  }
  if (hasUV) {
    bufferSize += uvs.length * 4; // UV count in Float
  }
  if (hasIndices) {
    bufferSize +=
      2 + // Index count
      indices.length * 2; // Each index in UInt16
  }

  const buffer = new ArrayBuffer(bufferSize);
  const f = new DataView(buffer);
  let pos = 30;

  f.setUint32(
    0, //
    (hasPositions ? 1 : 0) + //
      (hasNormals ? 2 : 0) +
      (hasUV ? 4 : 0) +
      (hasIndices ? 8 : 0),
    true
  );
  f.setUint16(4, positions.length / 3, true);
  f.setFloat32(6, minBox.x, true);
  f.setFloat32(10, minBox.y, true);
  f.setFloat32(14, minBox.z, true);
  f.setFloat32(18, maxBox.x, true);
  f.setFloat32(22, maxBox.y, true);
  f.setFloat32(26, maxBox.z, true);
  for (let idx = 0; idx < positions.length; idx++) {
    f.setUint16(pos, positions[idx], true);
    pos += 2;
  }

  if (hasNormals) {
    for (let idx = 0; idx < normals.length; idx++) {
      f.setUint8(pos, normals[idx]);
      pos++;
    }
  }

  if (hasUV) {
    for (let idx = 0; idx < uvs.length; idx += 2) {
      f.setFloat32(pos, uvs[idx], true);
      f.setFloat32(pos + 4, uvs[idx + 1], true);
      pos += 8;
    }
  }

  if (hasIndices) {
    f.setUint16(pos, indices.length, true);
    pos += 2;
    for (let idx = 0; idx < indices.length; idx++) {
      f.setUint16(pos, indices[idx], true);
      pos += 2;
    }
  }

  if (save) {
    const link = document.createElement('a');
    link.style.display = 'none';
    document.body.appendChild(link);

    const blob = new Blob([buffer], { type: 'application/octet-stream' });
    link.href = URL.createObjectURL(blob);
    link.download = 'model.msh';
    link.click();
  }
  return buffer;
}
