import { colorToVec4 } from 'src/graphics/helpers/Colors';
import GradientFragCode from 'src/graphics/shaders/space.frag.glsl';
import GradientVertCode from 'src/graphics/shaders/space.vert.glsl';
import { SpaceTexture } from 'src/resources/SpaceTexture';
import {
  BackSide,
  BufferGeometry,
  Float32BufferAttribute,
  MathUtils,
  Mesh,
  ShaderMaterial,
  Vector2,
  Vector4
} from 'three';

interface Gradient {
  coords: [Vector2, Vector2];
  colors: [Vector4, Vector4];
  alpha: number;
  overlay?: boolean;
}

interface GradientUniform {
  start: Vector2;
  end: Vector2;
  startColor: Vector4;
  endColor: Vector4;
  alpha: number;
  overlay: number;
}

const generateGradientScreen = (opacity: number): Gradient[] => {
  return [
    {
      colors: [colorToVec4(0x0047ff, 0), colorToVec4(0xff9a03)],
      coords: [new Vector2(0.5, 0), new Vector2(1, 1)],
      alpha: 0.15
    },
    {
      colors: [colorToVec4(0, 0), colorToVec4(0)],
      coords: [new Vector2(0, 0), new Vector2(1, 1)],
      alpha: opacity
    },
    {
      colors: [colorToVec4(0x0578ff, 0), colorToVec4(0xf400f9)],
      coords: [new Vector2(0, 0), new Vector2(1, 1)],
      alpha: 0.3,
      overlay: true
    }
  ];
};

const SCREEN_GRADIENTS: Gradient[][] = [
  generateGradientScreen(0.1),
  generateGradientScreen(0.5),
  generateGradientScreen(0.8),
  generateGradientScreen(0.8),
  generateGradientScreen(0.5),
  generateGradientScreen(0.1)
];

/**
 * Skybox mesh
 */
export class Skybox {
  /**
   * Internal mesh
   * @type {Mesh}
   * @private
   */
  private readonly mesh: Mesh;

  /**
   * Half-cube geometry
   * @type {BufferGeometry}
   * @private
   */
  private readonly geometry: BufferGeometry;

  /**
   * Material for cube
   * @type {ShaderMaterial}
   * @private
   */
  private readonly material: ShaderMaterial;

  /**
   * Constructor
   */
  public constructor() {
    const maxGradients = SCREEN_GRADIENTS.reduce((val, screen) => Math.max(val, screen.length), 0);
    for (const screen of SCREEN_GRADIENTS) {
      for (let i = screen.length; i < maxGradients; i++) {
        screen.push({
          coords: [new Vector2(), new Vector2(1.0, 1.0)],
          colors: [new Vector4(), new Vector4()],
          alpha: 0
        });
      }
    }

    this.material = new ShaderMaterial({
      fragmentShader: GradientFragCode,
      vertexShader: GradientVertCode,
      depthWrite: false,
      depthTest: false,
      side: BackSide,
      defines: {
        MAX_GRADIENTS: maxGradients
      },
      uniforms: {
        map: {
          value: SpaceTexture.texture
        },
        tint: {
          value: new Vector4(0, 0, 0, 0)
        },
        gradients: {
          value: Array(maxGradients)
            .fill(0)
            .map(
              () =>
                ({
                  start: new Vector2(),
                  end: new Vector2(1, 0),
                  startColor: new Vector4(0, 0, 0, 0),
                  endColor: new Vector4(0, 0, 0, 0),
                  alpha: 0,
                  overlay: 0
                } as GradientUniform)
            )
        }
      }
    });

    this.geometry = this.buildGeometry();
    this.mesh = new Mesh(this.geometry, this.material);
  }

  /**
   * Getter for mesh
   * @returns {Mesh}
   */
  public get entity() {
    return this.mesh;
  }

  /**
   * Update space gradients
   * @param {number} screen
   */
  public update(screen: number) {
    const firstIdx = MathUtils.clamp(Math.floor(screen), 0, SCREEN_GRADIENTS.length - 1);
    const secondIdx = MathUtils.clamp(Math.floor(screen) + 1, 0, SCREEN_GRADIENTS.length - 1);
    const first = SCREEN_GRADIENTS[firstIdx];
    const second = SCREEN_GRADIENTS[secondIdx];
    const delta = screen % 1.0;

    for (let i = 0; i < first.length; i++) {
      const g = (this.material.uniforms.gradients.value as GradientUniform[])[i];
      g.start.lerpVectors(first[i].coords[0], second[i].coords[0], delta);
      g.end.lerpVectors(first[i].coords[1], second[i].coords[1], delta);
      g.startColor.lerpVectors(first[i].colors[0], second[i].colors[0], delta);
      g.endColor.lerpVectors(first[i].colors[1], second[i].colors[1], delta);
      g.alpha = MathUtils.lerp(first[i].alpha, second[i].alpha, delta);
      g.overlay = MathUtils.lerp(first[i].overlay ? 1 : 0, second[i].overlay ? 1 : 0, delta);
    }
  }

  /**
   * Build cube geometry
   * @returns {BufferGeometry}
   * @private
   */
  private buildGeometry() {
    const geom = new BufferGeometry();
    geom.setAttribute(
      'position',
      new Float32BufferAttribute([-1, 1, -1, 1, 1, -1, -1, -1, -1, 1, -1, -1, 1, 1, 1, 1, -1, 1], 3, false)
    );
    geom.setAttribute('uv', new Float32BufferAttribute([0, 1, 0.5, 1, 0, 0, 0.5, 0, 1, 1, 1, 0], 2, false));
    geom.setIndex([0, 1, 2, 1, 3, 2, 1, 4, 3, 4, 5, 3]);
    return geom;
  }
}
