import { useStore } from 'effector-react';
import { useEffect, useState } from 'react';
import { $soundActiveStore, setSoundEnabled } from 'src/store/sound';
import styles from './SoundIcon.module.scss';

const BAR_COUNT = 4;

export function SoundIcon() {
  const [bars, setBars] = useState<number[]>(Array(BAR_COUNT).fill(0));
  const soundActive = useStore($soundActiveStore);

  useEffect(() => {
    if (soundActive) {
      setBars(
        Array(BAR_COUNT)
          .fill(0)
          .map(() => Math.random())
      );
      const interval = setInterval(() => {
        setBars((prev) => [Math.random(), ...prev].slice(0, BAR_COUNT));
      }, 200);
      return () => {
        clearInterval(interval);
      };
    } else {
      setBars(Array(BAR_COUNT).fill(0));
    }
  }, [soundActive]);

  const handleClick = () => {
    setSoundEnabled(!soundActive);
  };

  return (
    <div className={styles.bars} onClick={handleClick}>
      {bars.map((item, idx) => (
        <em key={idx} style={{ height: `${Math.floor(2 + item * 13)}px` }} />
      ))}
    </div>
  );
}
