import Comet from 'src/assets/textures/space/comet.ktx2';
import CometFallback from 'src/assets/textures/space/comet.png';
import SpaceBigFallback from 'src/assets/textures/space/space.jpg';
import SpaceBig from 'src/assets/textures/space/space.ktx2';
import SpaceSmallFallback from 'src/assets/textures/space/space_small.jpg';
import SpaceSmall from 'src/assets/textures/space/space_small.ktx2';
import { SceneManager } from 'src/graphics/SceneManager';
import { TextureLoader } from 'src/graphics/TextureLoader';
import { addLoadingSteps, completeLoadingSteps } from 'src/store/loading';
import { Texture } from 'three';

/**
 * Space texture manager
 */
export class SpaceTexture {
  /**
   * Internal loaded texture
   * @type {Texture}
   * @private
   */
  private static tex: Texture;

  /**
   * Comet texture
   * @type {Texture}
   * @private
   */
  private static cometTex: Texture;

  /**
   * Loading texture depending on device capabilities
   * @returns {Promise<void>}
   */
  public static async load() {
    let fallback = false;
    const renderer = SceneManager.getRenderer();
    if (
      (renderer && renderer.capabilities.maxTextureSize < 4096) ||
      window.innerWidth * window.devicePixelRatio < 500
    ) {
      fallback = true;
    }
    addLoadingSteps(2);
    if (fallback) {
      this.tex = await TextureLoader.load(SpaceSmall, SpaceSmallFallback);
    } else {
      this.tex = await TextureLoader.load(SpaceBig, SpaceBigFallback);
    }
    this.cometTex = await TextureLoader.load(Comet, CometFallback);
    if (renderer) {
      renderer.initTexture(this.tex);
    }
    completeLoadingSteps(2);
  }

  public static get texture() {
    return this.tex;
  }

  public static get comet() {
    return this.cometTex;
  }
}
