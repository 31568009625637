import { useEffect, useRef } from 'react';
import { SceneManager } from 'src/graphics/SceneManager';
import { SpaceTexture } from 'src/resources/SpaceTexture';
import { addLoadingSteps, completeLoadingSteps } from 'src/store/loading';
import { AuctionScene } from "../../../graphics/scenes/AuctionScene";
import styles from './AuctionGraphics.module.scss';

export function AuctionGraphics() {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const wrapperRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (canvasRef.current && wrapperRef.current) {
      const manager = new SceneManager<AuctionScene>(canvasRef.current, wrapperRef.current);
      addLoadingSteps();

      Promise.all([
        SpaceTexture.load(), //
      ]).then(() => {
        completeLoadingSteps();
        const scene = new AuctionScene();
        manager.initScene(scene);
      });

      return () => {
        manager.dispose();
      };
    }
  }, []);

  return (
    <div ref={wrapperRef} className={styles.graphics}>
      <canvas ref={canvasRef} />
    </div>
  );
}
