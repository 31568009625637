import clsx from 'clsx';
import { useStore } from 'effector-react';
import { CSSProperties, MouseEvent, useEffect } from 'react';
import { useAdaptedValue } from 'src/hooks/common/useAdaptedValue';
import { $loadedStore, $loadingProgressStore } from 'src/store/loading';
import { $soundSelectedStore, setSoundEnabled } from 'src/store/sound';
import styles from './LoadingOverlay.module.scss';

export function LoadingOverlay() {
  const progress = useStore($loadingProgressStore);
  const loaded = useStore($loadedStore);
  const soundSelected = useStore($soundSelectedStore);
  const hidden = loaded && soundSelected;

  useEffect(() => {
    if (loaded && process.env.REACT_APP_DISABLE_SOUND_SELECT) {
      setSoundEnabled(false);
    }
  }, [loaded]);

  const ringSize = useAdaptedValue(240, 240, 240);
  const strokeLength = (ringSize - 4) * Math.PI;

  const onClickEnter = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setSoundEnabled(true);
  };

  const onClickNoSound = (event: MouseEvent) => {
    event.preventDefault();
    event.stopPropagation();
    setSoundEnabled(false);
  };

  return (
    <div className={clsx(styles.overlay, hidden && styles.hidden)}>
      <div
        className={clsx(styles.overlay__center, loaded && styles.active)}
        onClick={onClickEnter}
        style={
          {
            '--ring-size': `${ringSize}px`
          } as CSSProperties
        }
      >
        <svg className={clsx(styles.progress, loaded && styles.hidden)}>
          <circle cx={ringSize / 2} cy={ringSize / 2} r={ringSize / 2 - 2} stroke={'rgba(255, 255, 255, 0.4)'} />
          <circle
            className={styles.progress__bar}
            cx={ringSize / 2}
            cy={ringSize / 2}
            r={ringSize / 2 - 2}
            stroke={'var(--progress-stroke)'}
            strokeDasharray={`${strokeLength * progress} ${strokeLength}`}
          />
        </svg>
        <div className={clsx(styles.logo, loaded && styles.hidden)}>
          <svg>
            <path
              d="M17 13V1c-1.973 11.1506-5.4751 14.2859-16 16h32c-10.5597 1.8589-13.8397 5.5133-16 16V21"
              stroke={'#fff'}
              strokeWidth={2}
            />
          </svg>
        </div>
        <div className={clsx(styles.title, !loaded && styles.hidden)}>
          <span>Enter</span> Starfall Arena
        </div>
      </div>
      <div className={clsx(styles.overlay__footer, !loaded && styles.hidden)}>
        <svg width="34" height="34" viewBox="0 0 34 34" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.3">
            <path
              d="M33 27V17C33 8.16344 25.8366 1 17 1V1C8.16344 1 1 8.16344 1 17V27"
              stroke="white"
              strokeLinecap="round"
            />
            <path
              d="M1 24C1 20.6863 3.68629 18 7 18V18C8.10457 18 9 18.8954 9 20V31C9 32.1046 8.10457 33 7 33V33C3.68629 33 1 30.3137 1 27V24Z"
              stroke="white"
            />
            <path
              d="M25 20C25 18.8954 25.8954 18 27 18V18C30.3137 18 33 20.6863 33 24V27C33 30.3137 30.3137 33 27 33V33C25.8954 33 25 32.1046 25 31V20Z"
              stroke="white"
            />
          </g>
        </svg>
        <p>
          use headphones for a better experience <br />
          or{' '}
          <a href="#" onClick={onClickNoSound}>
            continue without sound
          </a>
        </p>
      </div>
    </div>
  );
}
