import { createEvent, createStore } from 'effector';

export type MenuTypes = 'menu' | 'coming' | 'form';

type menuProps = {
  isOpen: boolean;
  type: MenuTypes;
};
const $menuActiveStore = createStore<menuProps>({
  isOpen: false,
  type: 'menu'
});

const setMenuActive = createEvent<menuProps>();

$menuActiveStore.on(setMenuActive, (_, value) => value);

export {
  $menuActiveStore, //
  setMenuActive
};
