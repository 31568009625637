import { SlidePage } from 'src/components/base/App/App';
import { ScreenRollHandler } from 'src/components/base/ScreenRoller/ScreenRollHandler';
import { CameraDisplace } from 'src/graphics/helpers/CameraDisplace';
import { easeInOutQuad } from 'src/graphics/helpers/Easings';
import { FrameHandler } from 'src/graphics/helpers/FrameHandler';
import { MathUtils, Vector2 } from 'three';

interface PhotoElement {
  element: HTMLDivElement | null;
  video?: HTMLVideoElement;
  width: number;
  height: number;
  aspect: number;
  active: boolean;
  isOnlyVideo?: boolean;
  activeState: number;
}

interface Block {
  image: PhotoElement;
  scale: number;
  displace: Vector2;
  shiftPower: number;
}

interface DoubleBlock {
  blocks: [Block, Block];
  split: number;
}

export class CompetitionPhotosHandler {
  private readonly elements: PhotoElement[];

  private readonly total: number;

  private blocks: (Block | DoubleBlock)[] = [];

  private offset: number = 0;

  private targetOffset: number = 0;

  private active: boolean = false;

  private inputsActive: boolean = false;

  private frameHandler: FrameHandler;

  private maxOffset: number = 0;

  private touch: number | null = null;

  private touchOffsetOrigin: number = 0;

  private touchOrigin: number = 0;

  private touchPosition: number = 0;

  private touchInertia: number = 0;

  private displacePos: Vector2;

  private displaceHandler: CameraDisplace;

  public constructor(total: number) {
    this.frameUpdate = this.frameUpdate.bind(this);
    this.blockHandler = this.blockHandler.bind(this);
    this.stateListener = this.stateListener.bind(this);
    this.wheelHandler = this.wheelHandler.bind(this);
    this.touchStartHandler = this.touchStartHandler.bind(this);
    this.touchMoveHandler = this.touchMoveHandler.bind(this);
    this.touchEndHandler = this.touchEndHandler.bind(this);

    this.displacePos = new Vector2(0, 0);
    this.displaceHandler = new CameraDisplace();
    this.frameHandler = new FrameHandler(this.frameUpdate);
    ScreenRollHandler.addPageBlockHandler(SlidePage.Competition, this.blockHandler);
    ScreenRollHandler.addScrollListener(this.stateListener);
    this.total = total;
    this.elements = Array(total)
      .fill(0)
      .map((): PhotoElement => {
        return {
          element: null,
          width: 1,
          height: 1,
          aspect: 1,
          active: false,
          activeState: 0
        };
      });
  }

  public buildLayout() {
    let index = 0;
    let doubleBlock = true;
    this.blocks.length = 0;

    const { canMakeDoubleBlock, blockHeight, verticalPadding } = this.computeSizes();
    let offset = verticalPadding;

    const makeBlock = (image: PhotoElement, minScale = 0.7, maxScale = 1.0): Block => {
      const shiftPower = MathUtils.lerp(0.1, 0.9, Math.random());
      const scale = MathUtils.lerp(minScale, maxScale, Math.random());
      const displace = new Vector2() //
        .random()
        .subScalar(0.5);

      return {
        image,
        scale,
        displace,
        shiftPower
      };
    };

    const MIN_SINGLE = canMakeDoubleBlock ? 0.7 : 1.0;
    const MAX_SINGLE = canMakeDoubleBlock ? 0.9 : 1.0;
    const MIN_DOUBLE = 0.8;
    const MAX_DOUBLE = 1.0;
    while (index < this.elements.length) {
      if (doubleBlock && this.elements.length - index > 1 && canMakeDoubleBlock) {
        // Double-framed image
        this.blocks.push({
          blocks: [
            makeBlock(this.elements[index], MIN_DOUBLE, MAX_DOUBLE), //
            makeBlock(this.elements[index + 1], MIN_DOUBLE, MAX_DOUBLE)
          ],
          split: MathUtils.lerp(0.3, 0.7, Math.random()) // Slightly shift center line
        });

        // Increment indices
        index += 2;
      } else {
        // Single-frame image
        this.blocks.push(makeBlock(this.elements[index], MIN_SINGLE, MAX_SINGLE));

        // Increment indices
        index++;
      }
      doubleBlock = !doubleBlock;
      offset += blockHeight;
    }
    this.maxOffset = offset + verticalPadding * 2;
  }

  public setElement(index: number, element: HTMLDivElement | null) {
    if (element !== this.elements[index].element) {
      this.elements[index].element = null;
      if (element) {
        const child = element.getElementsByTagName('img')[0]!;
        this.elements[index].element = element;
        this.elements[index].video = element.getElementsByTagName('video')[0];
        const loadHandler = () => {
          if (this.elements[index].element === element) {
            this.elements[index].width = child.width;
            this.elements[index].height = child.height;
            this.elements[index].aspect = this.elements[index].width / this.elements[index].aspect;

            const cornerPercentY = 10;
            const cornerPercentX = (cornerPercentY * child.width) / child.height;
            const corners = [
              `${Math.random() * cornerPercentX}% ${Math.random() * cornerPercentY}%`,
              `${100 - Math.random() * cornerPercentX}% ${Math.random() * cornerPercentY}%`,
              `${100 - Math.random() * cornerPercentX}% ${100 - Math.random() * cornerPercentY}%`,
              `${Math.random() * cornerPercentX}% ${100 - Math.random() * cornerPercentY}%`
            ].join(', ');
            element.style.clipPath = `polygon(${corners})`;
          }
          child.removeEventListener('load', loadHandler);
        };
        child.addEventListener('load', loadHandler);
      }
    }
  }

  public setActive(index: number, active: boolean) {
    this.elements[index].active = active;
  }

  public setIsOnlyVideo(index: number) {
    this.elements[index].isOnlyVideo = true;
  }

  public start() {
    this.frameHandler.start();
    window.addEventListener('wheel', this.wheelHandler);
    window.addEventListener('touchstart', this.touchStartHandler);
    window.addEventListener('touchmove', this.touchMoveHandler);
    window.addEventListener('touchend', this.touchEndHandler);
    window.addEventListener('touchcancel', this.touchEndHandler);

    this.displaceHandler.bind();
  }

  public dispose() {
    this.frameHandler.stop();
    window.removeEventListener('wheel', this.wheelHandler);
    window.removeEventListener('touchstart', this.touchStartHandler);
    window.removeEventListener('touchmove', this.touchMoveHandler);
    window.removeEventListener('touchend', this.touchEndHandler);
    window.removeEventListener('touchcancel', this.touchEndHandler);
    this.displaceHandler.unbind();
  }

  private frameUpdate(delta: number) {
    // Handling inertia
    if (this.touchInertia !== 0) {
      if (this.touch === null) {
        this.targetOffset = this.offset = MathUtils.clamp(
          this.targetOffset - this.touchInertia,
          0,
          this.maxOffset - window.innerHeight
        );
      }

      this.touchInertia *= Math.pow(0.95, delta);
      if (Math.abs(this.touchInertia) < 0.001) {
        this.touchInertia = 0;
      }
    }
    if (!this.active) {
      return;
    }

    // Computing active offset
    const targetDisplace = this.displaceHandler.state;
    this.offset = MathUtils.damp(this.offset, this.targetOffset, 0.1, delta);
    this.displacePos.set(
      MathUtils.damp(this.displacePos.x, targetDisplace.x, 0.1, delta),
      MathUtils.damp(this.displacePos.y, targetDisplace.y, 0.1, delta)
    );
    const rotate = MathUtils.clamp((this.targetOffset - this.offset) / -40, -4, 4);

    const sw = window.innerWidth;
    const sh = window.innerHeight;
    const horizontalShift = sw / 10;
    const verticalShift = sh / 10;
    const { sidePadding, verticalPadding, blockHeight, width } = this.computeSizes();
    let offset = verticalPadding - this.offset;

    // Update active states
    for (const item of this.elements) {
      if (item.active) {
        item.activeState = Math.min(item.activeState + 0.05 * delta, 1);
        if (item.video && item.video.paused) {
          item.video.play();
        }
      } else {
        item.activeState = Math.max(item.activeState - 0.05 * delta, 0);
        if (item.video && item.activeState === 0 && !item.video.paused && !item.isOnlyVideo) {
          item.video.pause();
        }
      }
    }

    // Single block handling func
    const fitImage = (x: number, y: number, width: number, height: number, block: Block) => {
      // Compute block box from passed frame
      const active = easeInOutQuad(block.image.activeState);
      const activeScale = MathUtils.lerp(1, 1.2, active);
      let cx = x + width / 2;
      let cy = y + height / 2;
      let iw = block.image.width;
      let ih = block.image.height;
      const scaleFactor = Math.min(width / iw, height / ih) * block.scale;
      iw *= scaleFactor;
      ih *= scaleFactor;
      cx += block.displace.x * (width - iw) * 0.5;
      cy += block.displace.y * (height - ih) * 0.9;
      ih *= activeScale;
      const ih2 = ih / 2;

      // Compute vertical shift, based on scroll
      const scrollShift = (cy - sh / 2) / (sh / 2);
      cx -= this.displacePos.x * horizontalShift * block.shiftPower * 0.5;
      cy -= this.displacePos.y * verticalShift * block.shiftPower * 0.5;
      cy += verticalShift * scrollShift * block.shiftPower;

      // Check that block is in the viewport
      const culled =
        cy + ih2 < 0 || // If too far up
        cy - ih2 > sh; // Or lower than screen height

      let transform: string = 'translateY(200vh)';
      if (!culled) {
        const blockScale = ih / block.image.height;
        transform = [
          `translate(-50%, -50%)`, // Make (0, 0) centered
          `translate(${cx}px, ${cy}px)`, // Move to computed coords
          `rotateX(${-rotate * 3 + scrollShift * 15}deg)`, // Tilt block on scroll
          `scale(${blockScale})` // Scale block accordingly
        ].join(' ');
        if (block.image.video && !block.image.isOnlyVideo) {
          block.image.video.style.opacity = active.toString();
          block.image.video.style.visibility = active > 0 ? 'visible' : 'hidden';
        }
      }
      if (block.image.element) {
        block.image.element.style.transform = transform;
        block.image.element.style.visibility = !culled ? 'visible' : 'hidden';
      }
    };

    // Reposition block by computed sizes
    for (const block of this.blocks) {
      if ('blocks' in block) {
        const sizeLeft = width * block.split;
        const sizeRight = width - sizeLeft;
        fitImage(sidePadding, offset, sizeLeft, blockHeight, block.blocks[0]);
        fitImage(sidePadding + sizeLeft, offset, sizeRight, blockHeight, block.blocks[1]);
      } else {
        fitImage(sidePadding, offset, width, blockHeight, block);
      }
      offset += blockHeight;
    }
  }

  private wheelHandler(event: WheelEvent) {
    if (this.inputsActive) {
      this.targetOffset = MathUtils.clamp(this.targetOffset + event.deltaY, 0, this.maxOffset - window.innerHeight);
    }
  }

  private touchStartHandler(event: TouchEvent) {
    if (!this.inputsActive) return;
    if (this.touch === null) {
      const t = this.findTouch(event);
      if (t) {
        this.touchInertia = 0;
        this.touch = t.identifier;
        this.touchOrigin = this.touchPosition = t.clientY;
        this.touchOffsetOrigin = this.targetOffset;
      }
    }
  }

  private touchMoveHandler(event: TouchEvent) {
    if (this.touch !== null) {
      const t = this.findTouch(event, this.touch);
      if (t) {
        this.updateTouchOffset(t.clientY - this.touchOrigin);
      }
    }
  }

  private touchEndHandler(event: TouchEvent) {
    if (this.touch !== null) {
      const t = this.findTouch(event, this.touch);
      if (t) {
        this.updateTouchOffset(t.clientY - this.touchOrigin, true);
        this.touch = null;
      }
    }
  }

  private updateTouchOffset(pos: number, ignoreInertia: boolean = false) {
    if (!ignoreInertia) {
      const diff = pos - this.touchPosition;
      this.touchPosition = pos;
      if (Math.sign(diff) !== Math.sign(this.touchInertia)) this.touchInertia = 0;
      this.touchInertia += diff * 0.1;
    }

    this.targetOffset = this.offset = MathUtils.clamp(
      this.touchOffsetOrigin - pos,
      0,
      this.maxOffset - window.innerHeight
    );
  }

  private findTouch(event: TouchEvent, id: number | null = null) {
    for (let i = 0; i < event.changedTouches.length; i++) {
      if (id === null || event.changedTouches[i].identifier === id) {
        return event.changedTouches[i];
      }
    }
    return null;
  }

  private blockHandler(direction: number) {
    if (direction < 0 && this.targetOffset > 0) return true;
    return direction > 0 && this.targetOffset < this.maxOffset - window.innerHeight;
  }

  private stateListener(page: number, scroll: number, offset: number, maxOffset: number, normalized: number) {
    const visible = normalized > SlidePage.Competition - 1 && normalized < SlidePage.Competition + 1;
    const active = normalized > SlidePage.Competition - 0.1 && normalized < SlidePage.Competition + 0.1;
    this.active = visible;
    if (this.inputsActive !== active) {
      if (this.inputsActive) {
        this.touch = null;
      }
      this.inputsActive = active;
    }
  }

  private computeSizes() {
    const sw = window.innerWidth;
    const deviceSidePad = sw > 500 ? 200 : 50;
    const width = Math.min(sw - deviceSidePad, 1400);
    const sidePadding = (sw - width) / 2;
    const blockHeight = sw > 500 ? width * 0.4 : width * 0.6;
    const verticalPadding = sw > 500 ? 150 : 120;
    const canMakeDoubleBlock = sw > 550;

    return {
      width,
      blockHeight,
      sidePadding,
      verticalPadding,
      canMakeDoubleBlock
    };
  }
}
