import { MathUtils, Vector2, WebGLRenderer } from 'three';
import { GraphicsScene } from './scenes/GraphicsScene';

/**
 * Scene manager with quality control
 */
export class SceneManager<SceneType extends GraphicsScene> {
  /**
   * Max FPS samples
   * @type {number}
   * @private
   */
  private readonly MAX_FPS_SAMPLES = 60;

  /**
   * Quality check frame delay
   * @type {number}
   * @private
   */
  private readonly QUALITY_DELAY = 128;

  /**
   * Current active renderer
   * @type {WebGLRenderer}
   * @private
   */
  private static rendererInstance: WebGLRenderer;

  /**
   * Current active renderer
   * @type {WebGLRenderer}
   * @private
   */
  private static managerInstance: SceneManager<GraphicsScene> | null;

  /**
   * Canvas wrapper element
   * @type {HTMLDivElement}
   * @private
   */
  private readonly wrapper: HTMLDivElement;

  /**
   * Target canvas
   * @type {HTMLCanvasElement}
   * @private
   */
  private readonly canvas: HTMLCanvasElement;

  /**
   * ThreeJS renderer instance
   * @type {WebGLRenderer}
   * @private
   */
  private readonly renderer: WebGLRenderer;

  /**
   * Main scene
   * @private
   */
  private scene: SceneType | null = null;

  /**
   * RAF call ID
   * @type {number}
   * @private
   */
  private rafQuery: number = -1;

  /**
   * Time of last RAF call
   * @type {number}
   * @private
   */
  private rafTime: number = 0;

  /**
   * Disable renderer only
   * @type {boolean}
   * @private
   */
  private readonly debugDisableRender: boolean = false;

  /**
   * Disable all rendering and updates
   * @type {boolean}
   * @private
   */
  private readonly debugDisableAll: boolean = false;

  /**
   * Delay before quality sampling
   * @type {number}
   * @private
   */
  private qualitySampleDelay: number = 256;

  /**
   * Render resolutions based on quality
   * @type {number[]}
   * @private
   */
  private readonly qualityTiers: number[] = [];

  /**
   * FPS samples over frames
   * @type {number[]}
   * @private
   */
  private fpsSamples: number[] = [];

  /**
   * Smoothed FPS value
   * @type {number}
   * @private
   */
  private fps: number = 0;

  /**
   * Current quality index
   * @type {number}
   * @private
   */
  private qualityIndex: number = 0;

  /**
   * Last registered screen size
   * @type {Vector2}
   * @private
   */
  private lastSize: Vector2;

  /**
   * Get active renderer
   */
  public static getRenderer() {
    return this.rendererInstance;
  }

  public static getCurrentDPI() {
    if (this.managerInstance) {
      return this.managerInstance.qualityTiers[this.managerInstance.qualityIndex];
    }
    return window.devicePixelRatio;
  }

  /**
   * Manager constructor
   * @param {HTMLCanvasElement} canvas
   * @param {HTMLDivElement} wrapper
   */
  public constructor(canvas: HTMLCanvasElement, wrapper: HTMLDivElement) {
    this.canvas = canvas;
    this.wrapper = wrapper;
    this.renderer = new WebGLRenderer({
      antialias: false, // TODO: Баг на сафари, пока неизвестно как фиксить
      canvas: this.canvas
    });
    this.scene = null;
    SceneManager.rendererInstance = this.renderer;

    this.qualityTiers = [0.5, 0.75, 1.0, 1.5, 2.0, 3.0, 4.0].filter((q) => q < window.devicePixelRatio);
    this.qualityTiers.push(window.devicePixelRatio);
    this.qualityIndex = this.qualityTiers.indexOf(window.devicePixelRatio);

    this.lastSize = new Vector2(1, 1);
    this.resize();

    this.render = this.render.bind(this);
    this.rafTime = performance.now();
    this.render(this.rafTime);

    const param = new URLSearchParams(window.location.search);
    this.debugDisableRender = param.get('disableRender') !== null;
    this.debugDisableAll = param.get('disableAll') !== null;
  }

  /**
   * Scene creation
   * @param {SceneType} scene
   */
  public initScene(scene: SceneType) {
    if (!this.scene) {
      this.scene = scene;
      this.resize();

      // @ts-ignore
      if (this.scene.timedScenes) {
        // @ts-ignore
        this.scene.timedScenes[0].scene.compile(this.renderer);
      }
    }
  }

  /**
   * Renderer scene disposal
   */
  public dispose() {
    cancelAnimationFrame(this.rafQuery);
    window.removeEventListener('resize', this.resize);
    this.rafQuery = 0;
    this.scene?.dispose();
  }

  /**
   * Frame render callback
   * @param {number} elapsedTime
   * @private
   */
  private render(elapsedTime: number) {
    this.rafQuery = requestAnimationFrame(this.render);
    const delta = (elapsedTime - this.rafTime) / 16.666;
    this.rafTime = elapsedTime;
    this.updateFPS(delta);
    this.updateQuality();
    this.resize();
    SceneManager.managerInstance = this;

    if (this.scene && !this.debugDisableAll) {
      this.scene.update(Math.min(delta, 2));
      if (!this.debugDisableRender) {
        this.scene.render(this.renderer);
      } else {
        this.renderer.setClearColor(0x0, 1.0);
        this.renderer.clearColor();
      }
    }
  }

  /**
   * Screen resize handler
   * @private
   */
  private resize() {
    const dpi = this.qualityTiers[this.qualityIndex];
    const w = Math.ceil(this.wrapper.clientWidth * dpi);
    const h = Math.ceil(this.wrapper.clientHeight * dpi);
    if (this.lastSize.x !== w || this.lastSize.y !== h) {
      this.canvas.width = w;
      this.canvas.height = h;
      this.renderer.setSize(w, h, false);
      if (this.scene) {
        this.scene.resize(new Vector2(w, h));
      }
    }
  }

  /**
   * Update FPS counter samples
   * @param {number} delta
   * @private
   */
  private updateFPS(delta: number) {
    const fpsNow = Math.ceil((1.0 / Math.max(delta, 0.1)) * 60.0);
    this.fpsSamples.push(fpsNow);
    if (this.fpsSamples.length > this.MAX_FPS_SAMPLES) {
      this.fpsSamples = this.fpsSamples.slice(this.fpsSamples.length - this.MAX_FPS_SAMPLES);
    }
    this.fps = this.fpsSamples.reduce((sum, val) => sum + val, 0) / this.fpsSamples.length;
  }

  /**
   * Quality update
   * @private
   */
  private updateQuality() {
    if (this.fpsSamples.length === this.MAX_FPS_SAMPLES) {
      this.qualitySampleDelay -= 1;
      if (this.qualitySampleDelay <= 0) {
        this.qualitySampleDelay = this.QUALITY_DELAY;
        let idx = this.qualityIndex;
        if (this.fps >= 60) {
          idx++;
        } else if (this.fps <= 30) {
          idx--;
        }
        idx = MathUtils.clamp(idx, 0, this.qualityTiers.length - 1);
        if (idx !== this.qualityIndex) {
          this.qualityIndex = idx;
          this.resize();
        }
      }
    }
  }
}
