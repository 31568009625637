import { BidInfo, BidInfoDef } from "../../BidInfo/BidInfo";
import { ControlBid } from "../../ControlBid/ControlBid";

export const ActiveBidScreen = () => {

  // TODO: Bind from store
  const bidInfo: BidInfoDef = {
    hash: '0x40bd001563085fc35165329ea1ff5c5ecbdbbeef',
    rank: 132,
    bid: '0.003',
    refund: '0.001',
    boxes: 3,
  };

  return <>
    <h2>My current bid</h2>
    <BidInfo def={bidInfo} />
    <ControlBid />
  </>
}
