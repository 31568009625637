import { SceneManager } from 'src/graphics/SceneManager';
import StarsFragCode from 'src/graphics/shaders/stars.frag.glsl';
import StarsVertCode from 'src/graphics/shaders/stars.vert.glsl';
import {
  AdditiveBlending,
  BufferGeometry,
  Float32BufferAttribute,
  MathUtils,
  Object3D,
  Points,
  ShaderMaterial
} from 'three';

export class Stars {
  private time: number;

  private mesh: Points;

  private material: ShaderMaterial;

  public constructor(parent: Object3D) {
    this.time = 0;

    this.material = new ShaderMaterial({
      fragmentShader: StarsFragCode,
      vertexShader: StarsVertCode,
      depthTest: false,
      depthWrite: false,
      blending: AdditiveBlending,
      uniforms: {
        time: {
          value: 0
        },
        dpi: {
          value: 1
        }
      }
    });
    const geom = this.buildGeometry();

    this.mesh = new Points(geom, this.material);
    // this.mesh.position.set(0, 0, -5);
    parent.add(this.mesh);
  }

  public update(delta: number) {
    this.time = (this.time + delta * 0.001) % 1.0;
    this.material.uniforms.time.value = this.time;
    this.material.uniforms.dpi.value = SceneManager.getCurrentDPI();
  }

  private buildGeometry() {
    const MAX_STARS = 35000;
    const positions: number[] = [];
    const timeOffset: number[] = [];
    const multiplier: number[] = [];
    const alpha: number[] = [];
    const scale: number[] = [];
    for (let i = 0; i < MAX_STARS; i++) {
      const lat = Math.random() * Math.PI;
      const lon = Math.random() * Math.PI * 2;
      positions.push(
        Math.cos(lat) * Math.sin(lon), //
        Math.cos(lon),
        Math.sin(lat) * Math.sin(lon)
      );
      timeOffset.push(Math.random());
      multiplier.push(Math.ceil(Math.random() * 3));
      alpha.push(MathUtils.lerp(0.6, 1, Math.random()));
      scale.push(MathUtils.lerp(1, 3, Math.random()));
    }

    const geom = new BufferGeometry();
    geom.setAttribute('position', new Float32BufferAttribute(positions, 3));
    geom.setAttribute('timeOffset', new Float32BufferAttribute(timeOffset, 1));
    geom.setAttribute('multiplier', new Float32BufferAttribute(multiplier, 1));
    geom.setAttribute('scale', new Float32BufferAttribute(scale, 1));
    geom.setAttribute('alpha', new Float32BufferAttribute(alpha, 1));
    return geom;
  }
}
