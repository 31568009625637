import LogoFragCode from 'src/graphics/shaders/logo.frag.glsl';
import LogoVertCode from 'src/graphics/shaders/logo.vert.glsl';
import { SceneModels } from 'src/resources/SceneModels';
import { MathUtils, Mesh, PerspectiveCamera, Scene, ShaderMaterial, Texture, Vector2, WebGLRenderer } from 'three';
import { PartialScene } from './PartialScene';

/**
 * Class for prologue scene
 */
export class OutroScene extends PartialScene {
  /**
   * Internal scene
   * @type {Scene}
   * @protected
   */
  private readonly scene: Scene;

  /**
   * Camera for scene
   * @type {PerspectiveCamera}
   * @private
   */
  private readonly camera: PerspectiveCamera;

  /**
   * Logo mesh
   * @type {Mesh}
   * @private
   */
  private readonly logo: Mesh;

  private readonly material: ShaderMaterial;

  private time: number = 0;

  /**
   * Scene constructor
   */
  public constructor() {
    super();
    this.scene = new Scene();
    this.camera = new PerspectiveCamera(60, 1, 0.1, 500);

    this.material = new ShaderMaterial({
      fragmentShader: LogoFragCode,
      vertexShader: LogoVertCode,
      uniforms: {
        backgroundMap: {
          value: null
        },
        appear: {
          value: 0.5
        }
      }
    });
    this.logo = new Mesh(SceneModels.logo, this.material);
    this.logo.frustumCulled = false;
    this.logo.position.set(0, 0, -80);
    this.logo.scale.set(0.6, 0.6, 0.1);
  }

  /**
   * Enable scene for rendering
   */
  public enter() {
    this.scene.add(this.logo);
  }

  /**
   * Update scene logic
   * @param {number} delta
   * @param {number} time
   */
  public update(delta: number, time: number): void {
    this.scene.position.set(0, 0, 0);
    this.scene.rotation.set(0, 0, 0);
    this.scene.applyMatrix4(this.matrix);

    this.time = (this.time + 0.003 * delta) % (Math.PI * 2);

    this.material.uniforms.appear.value = MathUtils.clamp(time + 1, 0, 1);
    this.logo.rotation.set(Math.sin(this.time * 2) * 0.02, Math.sin(this.time) * 0.02, -0.2, 'YXZ');
  }

  /**
   * Render scene
   * @param {WebGLRenderer} renderer
   */
  public render(renderer: WebGLRenderer): void {
    renderer.render(this.scene, this.camera);
  }

  /**
   * Disable rendering for this scene
   */
  public leave() {
    this.scene.remove(this.logo);
  }

  /**
   * Release resources
   */
  public dispose(): void {}

  /**
   * Resize handler
   * @param {Vector2} size
   */
  public resize(size: Vector2): void {
    this.camera.zoom = this.getZoom(size);
    this.camera.aspect = size.x / size.y;
    this.camera.updateProjectionMatrix();
  }

  /**
   * Update scene background texture
   * @param {Texture} background
   */
  public setBackground(background: Texture) {
    super.setBackground(background);
    this.material.uniforms.backgroundMap.value = background;
  }
}
