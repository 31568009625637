import { Vector4 } from 'three';

export function colorToVec4(hex: number, alpha: number = 1.0) {
  return new Vector4(
    ((hex >> 16) & 255) / 255.0, //
    ((hex >> 8) & 255) / 255.0,
    ((hex >> 0) & 255) / 255.0,
    alpha
  );
}
