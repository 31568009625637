import { CompressedTexture, Texture, TextureLoader as DefaultTextureLoader } from 'three';
import { KTX2Loader } from 'three/examples/jsm/loaders/KTX2Loader';
import { SceneManager } from './SceneManager';

/**
 * Общий загрузчик текстур
 */
export class TextureLoader {
  /**
   * Флаг проверки совместимости
   * @type {boolean}
   * @private
   */
  private static supportChecked: boolean = false;

  /**
   * Флаг поддержки сжатых текстур
   * @type {boolean}
   * @private
   */
  private static basisSupported: boolean = false;

  /**
   * Легаси-загрузчик
   * @type {TextureLoader}
   * @private
   */
  private static fallbackLoader: DefaultTextureLoader;

  /**
   * Загрузчик KTX2
   * @type {KTX2Loader}
   * @private
   */
  private static compressedLoader: KTX2Loader;

  /**
   * Загрузка текстуры
   * @param {string} compressedPath
   * @param {string} fallbackPath
   * @returns {Promise<Texture | CompressedTexture>}
   */
  public static async load(compressedPath: string, fallbackPath: string): Promise<Texture | CompressedTexture> {
    await this.checkCaps();
    if (this.basisSupported) {
      return this.compressedLoader.loadAsync(compressedPath);
    }
    return this.fallbackLoader.loadAsync(fallbackPath);
  }

  /**
   * Проверка поддержки KTX2
   * @private
   */
  private static checkCaps() {
    if (this.supportChecked) return;
    if (!SceneManager.getRenderer()) throw new Error('No renderer found');

    // Проверка на поддержку WASM
    this.basisSupported = false;
    let wasmSupport = false;
    try {
      if (typeof WebAssembly === 'object' && typeof WebAssembly.instantiate === 'function') {
        const wasm = new WebAssembly.Module(Uint8Array.of(0x0, 0x61, 0x73, 0x6d, 0x01, 0x00, 0x00, 0x00));
        if (wasm instanceof WebAssembly.Module) {
          if (new WebAssembly.Instance(wasm) instanceof WebAssembly.Instance) {
            wasmSupport = true;
          }
        }
      }
    } catch (e) {
      wasmSupport = false;
    }

    // Грузим транскодер и определяем совместимость
    if (wasmSupport) {
      this.compressedLoader = new KTX2Loader();
      this.compressedLoader.setTranscoderPath('/basis/');
      this.compressedLoader.detectSupport(SceneManager.getRenderer());
      this.basisSupported = true;
    } else {
      this.fallbackLoader = new DefaultTextureLoader();
    }
    this.supportChecked = true;
  }
}
