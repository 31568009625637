import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { SlidePage } from 'src/components/base/App/App';
import { GameplayScene } from 'src/graphics/scenes/partials/GameplayScene';
import { useScreenState } from 'src/hooks/common/useScreenState';
import styles from './GamplayScreen.module.scss';

const SLIDES: string[][] = [
  ['Join the fight'], //
  ['Team up with', 'friends'],
  ['Compete and', 'rank up']
];
const SLIDE_TEXTS: string[] = [
  'Queue up anywhere, anytime for\xa0fun\xa0and\xa0fast-paced\xa0matches.',
  'Assemble your squad and\xa0battle\xa0to\xa0become\xa0legends.',
  'When you’re ready, begin your\xa0ladder\xa0climb\xa0for\xa0real\xa0rewards. '
];

export function GameplayScreen() {
  const [slide, setSlide] = useState(0);
  const [prevSlide, setPrevSlide] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [animStep, setAnimStep] = useState(false);

  const handleScroll = (dir: number) => {
    if (!animating) {
      if (slide + dir >= 0 && slide + dir < SLIDES.length) {
        setPrevSlide(slide);
        setSlide(slide + dir);
        setAnimating(true);
        setAnimStep(false);
        return true;
      }
    } else {
      return true;
    }
    return false;
  };

  const { active, scroll } = useScreenState(SlidePage.Gameplay, handleScroll);

  useEffect(() => {
    if (animating && active) {
      let tm2: ReturnType<typeof setTimeout> | null = null;
      const tm1 = setTimeout(() => {
        setAnimStep(true);
        tm2 = setTimeout(() => {
          setAnimating(false);
          setAnimStep(false);
        }, 500);
      }, 500);

      return () => {
        clearTimeout(tm1);
        if (tm2) {
          clearTimeout(tm2);
        }
      };
    }
  }, [animating, active, slide]);

  useEffect(() => {
    if (!active) {
      setAnimating(false);
      setSlide(scroll > 0 ? SLIDES.length - 1 : 0);
    }
  }, [active]);

  useEffect(() => {
    GameplayScene.setSlide(slide);
  }, [slide]);

  const slideIndex = animating && !animStep ? prevSlide : slide;
  return (
    <div className={styles.screen}>
      <div className={clsx(styles.screen__text, animating && !animStep && styles.hidden)}>
        <div className={styles.accent}>{(slideIndex + 1).toString().padStart(2, '0')}</div>
        {SLIDES[slideIndex].map((l) => (
          <div key={l}>{l}</div>
        ))}
        <p>{SLIDE_TEXTS[slideIndex]}</p>
      </div>
    </div>
  );
}
