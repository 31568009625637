import { ScreenRollHandler } from 'src/components/base/ScreenRoller/ScreenRollHandler';
import { Comets } from 'src/graphics/entities/Comets';
import { MovingStars } from 'src/graphics/entities/MovingStars';
import { Skybox } from 'src/graphics/entities/Skybox';
import { Stars } from 'src/graphics/entities/Stars';
import { CameraDisplace } from 'src/graphics/helpers/CameraDisplace';
import { quadPass } from 'src/graphics/helpers/QuadPass';
import {
  ClampToEdgeWrapping,
  Euler,
  LinearFilter,
  MathUtils,
  PerspectiveCamera,
  Quaternion,
  RGBAFormat,
  Vector2,
  Vector3,
  WebGLRenderer,
  WebGLRenderTarget
} from 'three';
import { GraphicsScene } from './GraphicsScene';

/**
 * Main scene container
 */
export class AuctionScene extends GraphicsScene {
  /**
   * Scene base camera
   * @type {PerspectiveCamera}
   * @private
   */
  private readonly camera: PerspectiveCamera;

  /**
   * Skybox
   * @type {Skybox}
   * @private
   */
  private readonly space: Skybox;

  /**
   * Star points container
   * @type {Stars}
   * @private
   */
  private readonly stars: Stars;

  /**
   * Star batch container
   * @type {MovingStars}
   * @private
   */
  private readonly movingStars: MovingStars;

  /**
   * Flying comets
   * @type {Comets}
   * @private
   */
  private readonly comets: Comets;

  /**
   * Offset, damped from scroll
   * @type {number}
   * @private
   */
  private offset: number = 0;

  /**
   * Currently active scene IDs
   * @type {number[]}
   * @private
   */
  private activeScenes: number[] = [];

  /**
   * Buffer for space render
   * @type {WebGLRenderTarget}
   * @private
   */
  private readonly spaceBuffer: WebGLRenderTarget;

  /**
   * Buffer for next scene
   * @type {WebGLRenderTarget}
   * @private
   */
  private readonly nextSceneBuffer: WebGLRenderTarget;

  /**
   * Current mouse position, damped from CameraDisplace
   * @type {Vector2}
   * @private
   */
  private mousePos: Vector2 = new Vector2(0, 0);

  /**
   * Class that handles mouse movement and gyroscope events
   * @type {CameraDisplace}
   * @private
   */
  private cameraDisplace: CameraDisplace;

  /**
   * Scene initialization
   */
  public constructor() {
    super();
    this.activeScenes = [];
    this.camera = new PerspectiveCamera(50, 1, 0.1, 500);

    this.space = new Skybox();
    this.stars = new Stars(this.space.entity);
    this.movingStars = new MovingStars();
    this.comets = new Comets(this.space.entity);

    this.spaceBuffer = new WebGLRenderTarget(100, 100, {
      wrapS: ClampToEdgeWrapping,
      wrapT: ClampToEdgeWrapping,
      magFilter: LinearFilter,
      minFilter: LinearFilter,
      generateMipmaps: false,
      format: RGBAFormat,
      depthBuffer: false,
      stencilBuffer: false
    });
    this.nextSceneBuffer = new WebGLRenderTarget(100, 100, {
      wrapS: ClampToEdgeWrapping,
      wrapT: ClampToEdgeWrapping,
      magFilter: LinearFilter,
      minFilter: LinearFilter,
      generateMipmaps: false,
      format: RGBAFormat,
      depthBuffer: true,
      stencilBuffer: false
    });

    this.mousePos.set(0, 0);
    this.cameraDisplace = new CameraDisplace();
    this.cameraDisplace.bind();
  }

  /**
   * Scene logic update
   * @param {number} delta
   */
  public update(delta: number): void {

    // Damp offset and "mouse" coords
    const targetOffset = ScreenRollHandler.getNormalizedScroll();
    const targetMouse = this.cameraDisplace.state;
    this.offset = MathUtils.damp(this.offset, targetOffset, 0.1, delta);
    if (Math.abs(this.offset - targetOffset) < 0.0001) {
      this.offset = targetOffset;
    }
    this.mousePos.set(
      MathUtils.damp(this.mousePos.x, targetMouse.x, 0.05, delta),
      MathUtils.damp(this.mousePos.y, targetMouse.y, 0.05, delta)
    );

    // Calculate current camera position from path
    const basePosition = new Vector3();
    const baseRotation = new Quaternion();
    baseRotation.multiply(new Quaternion().setFromEuler(new Euler(this.mousePos.y * 0.005, this.mousePos.x * 0.005)));
    basePosition.add(new Vector3(this.mousePos.x * 0.1, -this.mousePos.y * 0.1, 0).applyQuaternion(baseRotation));

    // Update camera entity
    this.camera.position.copy(basePosition);
    this.camera.quaternion.copy(baseRotation);
    const cameraMat = this.camera.matrix.clone().invert();

    // Position space onto camera coords
    this.space.entity.position.copy(this.camera.position);
    this.space.entity.updateMatrix();
    this.stars.update(delta);
    this.movingStars.update(this.offset % 1.0, this.camera.position);
    this.comets.update(delta);

    // Update gradients
    this.space.update(this.offset);
  }

  /**
   * Scene rendering
   * @param {WebGLRenderer} renderer
   */
  public render(renderer: WebGLRenderer): void {
    const DEBUG_SPACE = false;
    const [currentScene, nextScene] = [...this.activeScenes].sort();

    if (DEBUG_SPACE) {
      renderer.setViewport(0, 0, this.nextSceneBuffer.width, this.nextSceneBuffer.height);
      renderer.setRenderTarget(null);
      renderer.setClearColor(0x0, 1);
      renderer.clear(true, true, false);
      renderer.render(this.space.entity, this.camera);
      return;
    }

    // Save space into buffer
    renderer.info.autoReset = false;
    renderer.info.reset();
    renderer.autoClear = false;
    renderer.setViewport(0, 0, this.nextSceneBuffer.width, this.nextSceneBuffer.height);
    renderer.setRenderTarget(this.spaceBuffer);
    renderer.setClearColor(0x0, 1);
    renderer.clear(true, true, false);
    renderer.render(this.space.entity, this.camera);
    this.movingStars.render(renderer, this.camera);
    renderer.setRenderTarget(null);

    // Render active scene directly to screen
    const background = nextScene !== undefined ? this.nextSceneBuffer.texture : this.spaceBuffer.texture;
    renderer.setRenderTarget(null);
    renderer.setClearColor(0x0, 1);
    renderer.clear(true, true, false);
    quadPass(renderer, background);
  }

  /**
   * Resize event handler
   * @param {Vector2} size
   */
  public resize(size: Vector2): void {
    this.camera.aspect = size.x / size.y;
    this.camera.zoom = 1;
    this.camera.updateProjectionMatrix();
    this.spaceBuffer.setSize(size.x, size.y);
    this.nextSceneBuffer.setSize(size.x, size.y);
  }

  /**
   * Resources disposal
   */
  public dispose(): void {
    this.cameraDisplace.unbind();
  }

}
