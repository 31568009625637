import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { SlidePage } from 'src/components/base/App/App';
import { PrologueScene } from 'src/graphics/scenes/partials/obsolette/PrologueScene';
import { useScreenState } from 'src/hooks/common/useScreenState';
import styles from './PrologueScreen.module.scss';

const SLIDES: string[][] = [
  ['In a distant future,', 'In a universe we thought we conquered,', 'Lurks an enormous mystery.'],
  ['Energy is running out.', 'We have one chance to escape.', 'But not everyone is for it.'],
  ['Decide the fate of humanity', 'as the age of starlight', 'comes to an end']
];

export function PrologueScreen() {
  const [slide] = useState(2);
  const [prevSlide] = useState(0);
  const [animating, setAnimating] = useState(false);
  const [animStep, setAnimStep] = useState(false);

  // const handleScroll = (dir: number) => {
  //   if (!animating) {
  //     if (slide + dir >= 0 && slide + dir < SLIDES.length) {
  //       setPrevSlide(slide);
  //       setSlide(slide + dir);
  //       setAnimating(true);
  //       setAnimStep(false);
  //       return true;
  //     }
  //   } else {
  //     return true;
  //   }
  //   return false;
  // };

  const { active } = useScreenState(SlidePage.Prologue);

  useEffect(() => {
    if (animating && active) {
      let tm2: ReturnType<typeof setTimeout> | null = null;
      const tm1 = setTimeout(() => {
        setAnimStep(true);
        tm2 = setTimeout(() => {
          setAnimating(false);
          setAnimStep(false);
        }, 500);
      }, 500);

      return () => {
        clearTimeout(tm1);
        if (tm2) {
          clearTimeout(tm2);
        }
      };
    }
  }, [animating, active, slide]);

  useEffect(() => {
    if (!active) {
      setAnimating(false);
      // setSlide(scroll > 0 ? SLIDES.length - 1 : 0);
    }
  }, [active]);

  useEffect(() => {
    PrologueScene.setSlide(slide);
  }, [slide]);

  const activeIndex = animating && !animStep ? prevSlide : slide;

  return (
    <div className={styles.screen}>
      <div className={styles.screen__container}>
        <div
          className={clsx(
            styles.screen__text,
            animating && !animStep && styles.hidden,
            activeIndex === 2 && styles.bold
          )}
        >
          {SLIDES[activeIndex].map((l) => (
            <div key={l}>{l}</div>
          ))}
        </div>
      </div>
    </div>
  );
}
