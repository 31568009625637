import VideoSlide1 from 'src/assets/textures/videos/slide_1.mp4';
import VideoSlide2 from 'src/assets/textures/videos/slide_2.mp4';
import VideoSlide3 from 'src/assets/textures/videos/slide_3.mp4';
import { addLoadingSteps, completeLoadingSteps } from 'src/store/loading';
import {
  ClampToEdgeWrapping,
  DoubleSide,
  LinearFilter,
  Mesh,
  MeshBasicMaterial,
  NearestFilter,
  OrthographicCamera,
  PlaneGeometry,
  Scene,
  Texture,
  Vector2,
  VideoTexture,
  WebGLRenderer,
  WebGLRenderTarget
} from 'three';

const VIDEO_LINKS = [
  VideoSlide1, //
  VideoSlide2,
  VideoSlide3
];

/**
 * Video atlas entry
 */
interface VideoFrame {
  mesh: Mesh;
  material: MeshBasicMaterial;
  tag: HTMLVideoElement;
  texture: VideoTexture;
}

/**
 * Class for video texture handling
 */
export class ShardsVideoTexture {
  /**
   * Texture FBO
   * @type {WebGLRenderTarget}
   * @private
   */
  private static buffer: WebGLRenderTarget;

  /**
   * Scene for quads
   * @type {Scene}
   * @private
   */
  private static scene: Scene;

  /**
   * Camera for quads
   * @type {OrthographicCamera}
   * @private
   */
  private static camera: OrthographicCamera;

  /**
   * Total videos vertically
   * @type {number}
   * @private
   */
  private static rows: number = 1;

  /**
   * Total videos horizontally
   * @type {number}
   * @private
   */
  private static columns: number = 1;

  /**
   * Internal video atlas items
   * @type {VideoFrame[]}
   * @private
   */
  private static entries: VideoFrame[];

  /**
   * Create video textures
   * @returns {Promise<void>}
   */
  public static async load() {
    // Init video tags
    this.scene = new Scene();
    this.entries = [];
    addLoadingSteps();
    for (const src of VIDEO_LINKS) {
      // Create video tag
      const tag = document.createElement('video');
      tag.autoplay = true;
      tag.muted = true;
      tag.controls = false;
      tag.tabIndex = -1;
      tag.preload = 'auto';
      tag.loop = true;
      tag.playsInline = true;
      tag.src = src;
      tag.style.position = 'fixed';
      tag.style.top = '0';
      tag.style.left = '0';
      tag.style.opacity = '0';
      tag.style.pointerEvents = 'none';
      tag.style.width = '1px';
      tag.style.height = '1px';
      document.body.append(tag);

      // Create mesh
      const tex = new VideoTexture(
        tag,
        Texture.DEFAULT_MAPPING,
        ClampToEdgeWrapping,
        ClampToEdgeWrapping,
        NearestFilter,
        LinearFilter
      );
      const mat = new MeshBasicMaterial({
        depthWrite: false,
        depthTest: false,
        side: DoubleSide,
        map: tex
      });
      const geom = new PlaneGeometry(1, 1, 10, 10);
      geom.translate(0.5, 0.5, 0);
      const mesh = new Mesh(geom, mat);
      mesh.frustumCulled = false;
      this.scene.add(mesh);
      this.entries.push({
        mesh,
        material: mat,
        tag,
        texture: tex
      });
      tex.needsUpdate = true;
      tex.flipY = false;
    }

    // Reposition all videos in buffer
    this.columns = Math.ceil(VIDEO_LINKS.length / 2);
    this.rows = Math.ceil(VIDEO_LINKS.length / this.columns);
    for (let i = 0; i < this.entries.length; i++) {
      const y = Math.floor(i / this.columns);
      const x = i % this.columns;
      this.entries[i].mesh.position.set(x, y, 0);
    }

    // Resize buffer
    this.buffer = new WebGLRenderTarget(2048, 2048, {
      depthBuffer: false,
      stencilBuffer: false
    });
    this.camera = new OrthographicCamera(0, this.columns, this.rows, 0, -1, 1);
    completeLoadingSteps();
  }

  /**
   * Draw all videos to canvas texture and update it
   */
  public static update(renderer: WebGLRenderer) {
    renderer.setRenderTarget(this.buffer);
    renderer.autoClear = false;
    renderer.setClearColor(0xff00ff);
    renderer.setViewport(0, 0, this.buffer.width, this.buffer.height);
    renderer.clear();
    try {
      // Sometimes Safari will crash on glTexImage2D
      // because video is not loaded yet
      renderer.render(this.scene, this.camera);
    } catch (ex) {
      //
    }
    renderer.setRenderTarget(null);
  }

  /**
   * Getter for texture
   * @returns {CanvasTexture}
   */
  public static get texture() {
    return this.buffer.texture;
  }

  /**
   * Video tags removal
   */
  public static dispose() {
    for (const en of this.entries) {
      en.material.dispose();
      en.texture.dispose();
      en.mesh.geometry.dispose();
      en.tag.remove();
    }
    this.entries.length = 0;
    this.buffer.dispose();
  }

  /**
   * Total number of textures
   * @returns {number}
   */
  public static get count() {
    return VIDEO_LINKS.length;
  }

  /**
   * Calculate offset in atlas
   * @param {number} index
   * @returns {readonly [Vector2, Vector2]}
   */
  public static getOffset(index: number) {
    const py = Math.floor(index / this.columns);
    const px = index - py * this.columns;

    return [
      new Vector2(px / this.columns, py / this.columns), //
      new Vector2(1 / this.columns, 1 / this.rows)
    ] as const;
  }
}
