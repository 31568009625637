import React, { useEffect } from 'react';
import css from './Privacy.module.scss';

export const Privacy = () => {
  const root = document.getElementById('root');

  useEffect(() => {
    if (root) {
      root.style.overflow = 'auto';

      return () => {
        root.style.overflow = 'hidden';
      };
    }
  }, []);

  return (
    <div className={css.wrapper}>
      <h1>Privacy Policy</h1>
      <h3>User Privacy Policy</h3>
      <p>
        This Privacy Policy of Starfall Arena describes the information we collect, use, and disclose about you when you
        access our online gaming services and interactive games using our Starfall Arena gaming platform (“Services”),
        visit our websites located at <a href="https://playstarfall.com/">https://playstarfall.com/</a> or any other
        site or mobile app where this Privacy Policy is posted (“Sites”), communicate with us, or otherwise engage with
        us. This Privacy Policy covers all of our services and all methods by which you may access our Sites and
        Services.
      </p>
      <p>
        We understand that privacy is an important issue to you and we respect the privacy of our users and aim to
        protect the security and confidentiality of information about you that we acquire. Under applicable law you may
        have the right to limit some, but not all, sharing of the information we collect about you. The law may also
        require us to disclose to you how we collect, share and protect that information.
      </p>
      <h3>What We Collect</h3>
      <p>
        We collect information about you when you provide it to us and when you visit our Sites, sign up for and use our
        Services, request information or otherwise interact with us or our Services.
      </p>
      <h3>I. Personal Information</h3>
      <p>The personal information we may collect includes:</p>
      <br />
      <p>Username and avatar;</p>
      <p>Email address;</p>
      <p>Country (provided by you);</p>
      <p>Any Ethereum network addresses (publicly available on the blockchain) you provide to us;</p>
      <p>Any blockchain network address associated with your digital assets or gaming tokens (your “Wallet”);</p>
      <p>Any information you provide in response to a survey or questionnaire;</p>
      <p>
        Feedback and correspondence, such as any emails, chat messages, or other communications you send to us via email
        or third-party social media websites;
      </p>
      <p>Information which may be received from other sources with your consent;</p>
      <p>Content that you create, share or submit in connection with your participation in the Creators Program;</p>
      <p>
        Information about the device you use to access the Services and Sites, including device model, device ID, IP
        address, browser type, operating system, platform (Android, iOS, web), referring website; and
      </p>
      <p>
        Information about your use of the Services including the pages of our Services which you browsed or the features
        you used, the time spent on those pages or features, the links on our Services that you click on, as well as
        actions you take during gameplay, <span style={{ textDecoration: 'underline' }}>your</span> in-game user
        settings and preferences and your in-app purchases.
      </p>
      <h3>II. Information from Third-Party Sources</h3>
      <p>
        When linking to a third-party service, we may retrieve your email. Such linkage may include interactions with
        our social media sites. We use this information for marketing and communications purposes. Third-party sites are
        governed by their own privacy policies, and you should review those privacy policies before using those sites.
      </p>
      <h3>How We Use Personal Information</h3>
      <p>
        We use, collect and process your personal information in connection with our provision of Services and, where
        applicable, to meet our legitimate interests, as set out below:
      </p>
      <br />
      <p>
        We use your username for identification purposes within the game, for example, to announce your standing in
        Conquests, or on the Leaderboard ranking.
      </p>
      <p>
        We use your email address to authenticate you, communicate with you to provide technical and customer support
        and to send updates regarding your participation in any waitlists, Contests and Surveys. We may use your email
        address to send promotional and other information to you with your permission. With your permission, we may also
        send you push notifications with game updates and promotions through our App. To learn how to opt-out of the use
        of your information for such purposes, please see “Your Choices” below.
      </p>
      <p>
        If you sign-up to receive our marketing emails, we may also use your email address to display targeted
        advertisements to you on third-party social media platforms such as Facebook, Twitter, Google or LinkedIn and we
        may provide a hashed version of your email address or other information to the platform provider for such
        purposes. To learn how to opt-out of the use of your information for such purposes, please see “Your Choices”
        below.;
      </p>
      <p>
        If you sign-up to participate in our Creators Program we may post, publish, share or otherwise promote your
        content on our webpages and social media sites, or use your name, picture, image, likeness, voice, signature or
        other biographical information in connection with the Creators Program or our campaigns;
      </p>
      <p>
        We use the information regarding your country (if provided) to update the flag icon by your username. You can
        remove this information at any time.
      </p>
      <p>
        We use information about your device and your use of the Services to better understand who is using our Services
        and how, and to improve our Services.
      </p>
      <p>
        We use your IP address to provide you with appropriate content and to block access to the Services from
        locations where we do not provide Services.
      </p>
      <p>
        We use your network address associated with your Wallet to allow you to make transactions on the platform,
        including buying and receiving digital assets such as gaming tokens.
      </p>
      <p>
        We use your feedback and correspondence to respond to your requests, feedback, or inquiries, and to continuously
        improve our products and services.
      </p>
      <p>
        We use your payment information to process payments for the digital assets you buy and sell on the platform.
      </p>
      <p>
        We will not use your personal information for purposes other than those purposes we have disclosed to you,
        without your permission.
      </p>
      <br />
      <h3>What Personal Information We Share</h3>
      <p>
        We do not sell, license, lease or otherwise disclose personal information (including personal information
        relating to current or former customers) to any third party without consent for any reason other than the
        purposes described below:
      </p>
      <p>
        We may transfer or make available personal information to our service providers who require access to the data
        in order to perform their tasks and duties in the course of providing services on our behalf (such as payment
        processing, hosting data, providing advertising, marketing and analytics services). We may also share personal
        information with third parties who have a legitimate purpose for accessing it (where permitted by applicable
        law). Our service providers may have access to or store personal information outside of the jurisdiction in
        which you reside, including in the United States.
      </p>
      <p>
        We may share your information with customer data and behavioral analytic platforms who help us to understand how
        you use and interact with our Services.
      </p>
      <p>
        We may share your information with payment processing services with which we partner to process payments for
        digital assets, at your request.
      </p>
      <p>
        We may disclose information to third parties about your account or the transactions you make if you give us your
        written permission.
      </p>
      <p>
        We may transfer any information we have about you as an asset in connection with a proposed or completed merger,
        acquisition or sale (including transfers made as part of insolvency or bankruptcy proceedings) involving all or
        part of us., or as part of a corporate reorganization or other change in corporate control.
      </p>
      <p>
        We and our Canadian, US and other foreign service providers may share your information with law enforcement, and
        officials, or other third parties when we are compelled to do so by a subpoena, court order, or similar legal
        procedure, where required or permitted by applicable law, or when we believe in good faith that the disclosure
        of personal information is necessary to prevent physical harm or financial loss, to report suspected illegal
        activity or to investigate violation of any of our terms and conditions or any other applicable policies. We
        also may disclose your personal information in order to comply, as necessary, with applicable laws and
        regulatory requirements, as well as legal process; respond to mandatory legal or governmental requests or
        demands for information (which may include a lawful access request by United States or other courts, law
        enforcement or governmental agencies); enforce our agreements, policies, procedures and terms of use; and
        protect ourselves, our users, Account Holders, or the general public from illegal activities.
      </p>
      <p>
        We may disclose personal information to another organization for the purposes of investigating a breach of an
        agreement or contravention of law or detecting, suppressing or preventing fraud, or as otherwise may be required
        or permitted by applicable Canadian, U.S. or other law or legal process, which may include lawful access by US
        or foreign courts, law enforcement or other government authorities.
      </p>
      <h3>Third-Party Websites</h3>
      <p>
        We may have links to third-party websites on our website. Third-party websites have their own privacy policies,
        which are not under our control; we encourage you to review the privacy policies of all third-party websites you
        visit.
      </p>
      <h3>Use of Cookies and Other Website Information</h3>
      <p>
        We collect the IP addresses of all visitors to our Sites and other related information such as page requests,
        browser type, operating system and average time spent on our Sites. We use this information to help us
        understand our website activity and to monitor and improve our Sites and Services.
      </p>
      <p>
        Starfall Arena uses cookies in order to provide better service, to facilitate our customers’ use of our Sites,
        to track usage of the Sites, to collect data and to address certain security issues. When you access our Sites,
        we send the cookies to your computer, phone or mobile device. Your computer, phone or mobile device stores the
        cookie in a file located inside your web browser. The cookies help Starfall Arena keep track of your visits to
        our Sites and your activity on our Sites and to understand how you interact with us.
      </p>
      <p>
        We may link the information collected by cookies with other information we collect from you pursuant to this
        Privacy Policy and use the combined information to better understand user behavior to improve our products.
        Similarly, the third parties who serve cookies on our Sites may link your name or email address to other
        information they collect, which may include past purchases made online, or your online usage information.
      </p>
      <p>
        You can generally activate or later deactivate the use of cookies through a functionality built into your web
        browser. If you want to learn more about cookies, or how to control, disable or delete them, please visit this
        site for detailed guidance. In addition, certain third-party advertising networks, including Google, permit
        users to opt out of or customize preferences associated with your internet browsing. Please note that if you
        decide not to accept cookies from our Sites, you may not be able to take advantage of all of the features
        available on our Sites.
      </p>
      <h3>App Usage Information & Analytics</h3>
      <p>
        As with many applications, certain limited data is required for the App to function on your device. This data
        includes the type of device hardware and operating system, unique device identifier, IP address, language
        settings, and the date and time the App accesses our servers. We use this information to help us understand the
        activity on our App, to monitor and improve our App, and to tailor your in-App experience. In addition, we may
        use third party service providers to collect analytical information about your use of the App, such as the App
        features used and time spent on the App, to help us tailor your in-app experience, improve our products and the
        quality of our App, and to manage and analyze data in order to better understand our users.
      </p>
      <h3>Security</h3>
      <p>
        Starfall Arena strives to ensure that our systems are secure and that they meet industry standards. We seek to
        protect the personal information in our custody and control against theft, loss and unauthorized access, use,
        modification and disclosure by implementing physical, administrative and electronic safeguards. Starfall Arena
        restricts access to personal information on a need-to-know basis to employees and authorized service providers
        who require access to fulfil their job requirements. Starfall Arena endeavors to engage third-party service
        providers that have security and confidentiality policies, if such third-party service providers have access to
        our customer’s personal information and such service providers are not authorized to use the personal
        information for their own purposes.{' '}
      </p>
      <p>
        Despite our efforts to protect the security of your information, no security system is always effective and we
        cannot guarantee that our systems will be completely secure.
      </p>
      <h3>Your Rights</h3>
      <p>Subject to applicable law, you may have the following data protection rights:</p>
      <br />
      <p>
        If you wish to access, correct, update, or request deletion of your personal information, you can do so at any
        time by emailing privacy@playstarfall.com. If you delete your Account, only your username will remain on the
        website for the purposes of the Ranking, and your personal information will not be used by us for any further
        purposes, nor sold or shared with third parties, except as necessary to prevent fraud and assist law
        enforcement, as required by law, or in accordance with this Privacy Policy.
      </p>
      <p>
        In addition, you can object to the processing of your personal information, ask us to restrict the processing of
        your personal information, or request portability of your personal information. Again, you can exercise these
        rights by emailing privacy@playstarfall.com.
      </p>
      <p>
        Similarly, if we have collected and processed your personal information with your consent, then you can withdraw
        your consent at any time. Withdrawing your consent will not affect the lawfulness of any processing we conducted
        prior to your withdrawal, nor will it affect the processing of your personal information conducted in reliance
        on lawful processing grounds other than consent <span style={{ textDecoration: 'underline' }}>where</span>
        permitted under applicable law.
      </p>
      <p>
        You have the right to complain to a data protection authority about our collection and use of your personal
        information. For more information, please contact your local data protection authority. We respond to all
        requests we receive from individuals wishing to exercise their data protection rights in accordance with
        applicable data protection laws.
      </p>
      <h3>
        You have the right to complain to a data protection authority about our collection and use of your personal
        information. For more information, please contact your local data protection authority. We respond to all
        requests we receive from individuals wishing to exercise their data protection rights in accordance with
        applicable data protection laws.
      </h3>
      <p>
        You can unsubscribe at any time by clicking the “unsubscribe” link included at the bottom of each email.
        Alternatively, you can opt-out of receiving email marketing communications by contacting us at the contact
        information provided in the “Contact Us” section below or adjusting your preferences in your account settings.
        Please note that you may continue to receive transactional or account-related communications from us.
      </p>
      <br />
      <p>
        You can turn off push notifications for the App within the
        <span style={{ textDecoration: 'underline' }}> notifications </span> settings of the App or in the settings of
        your mobile device.
      </p>
      <br />
      <p>
        To opt-out of the use of your information for such targeted advertising on social media platforms, please
        contact us at privacy@playstarfall.com. You can also manage your privacy settings directly through your account
        on these third-party platforms, including to opt-out of such targeted advertising.
      </p>
      <br />
      <p>You may also contact us by emailing privacy@playstarfall.com.</p>
      <p>
        Legal Basis for Processing Personal Information (EU Residents) Our legal basis for collecting and using the
        personal information described above will depend on the personal information concerned and the specific context
        in which we collect it.
      </p>
      <br />
      <p>
        However, we will normally collect personal information from you only (i) where we need the personal information
        to perform a contract with you; (ii) where the processing is in our legitimate interests and not overridden by
        your rights; or (iii) where we have your consent to do so. We have a legitimate interest in operating our
        Services and communicating with you as necessary to provide these Services, for example when responding to your
        queries, improving our Platform, undertaking marketing, or for the purposes of detecting or preventing illegal
        activities.
      </p>
      <br />
      <p>
        In some cases, we may also have a legal obligation to collect personal information from you or may otherwise
        need the personal information to protect your vital interests or those of another person.
      </p>
      <br />
      <p>
        If we ask you to provide personal information to comply with a legal requirement or to perform a contract with
        you, we will make this clear at the relevant time and advise you whether the provision of your personal
        information is mandatory or not (as well as of the possible consequences if you do not provide your personal
        information).
      </p>
      <h3>Retention of Personal Information</h3>
      <p>
        We will retain your personal information as long as you continue to use the Services, or for as long as is
        necessary to fulfill the purposes outlined in this Privacy Policy. Even after you delete your Account, we may
        retain copies of information about you for a period of time that is consistent with applicable law, applicable
        statute of limitations or as we believe is reasonably necessary to comply with applicable law, regulation, legal
        process, or governmental request, to collect fees owed, to resolve disputes, to address problems with our
        services, to assist with investigations, to enforce our Terms or other applicable agreements or policies, or to
        take any other actions consistent with applicable law. For non-EEA residents and
        <span style={{ textDecoration: 'underline' }}> where </span> permitted by law, we may continue to index
        information regarding your public network address from the Ethereum blockchain for account resurrection
        purposes.
      </p>
      <h3>Minors Under 13</h3>
      <p>
        Individuals under the age of 13 (“Children”) are not allowed to use the Services, and we do not knowingly
        collect personal information from Children in violation of the Children's Online Privacy Protection Act. If you
        believe that your child under 13 has gained access to the Services without your permission, you may request the
        deletion of their personal information by contacting us at privacy@playstarfall.com If we learn that we have
        collected personal information about a child under age 13, we will delete that data as quickly as possible. We
        have taken commercially reasonable steps to restrict use of the Services to those who are at least 13 years old.
        We do not market products or services for purchase by Children.
      </p>
      <h3>“Do Not Track” Policy</h3>
      <p>
        California law requires us to inform you how we respond to web browser Do Not Track (“DNT”) signals. Because no
        industry or legal standard exists for recognizing or honoring DNT signals, we do not respond to them at this
        time. This policy is subject to change as the privacy community and industry develop best practices for
        responding to DNT signals.
      </p>
      <br />
      <p>
        NOTICE TO CALIFORNIA RESIDENTS - YOUR CALIFORNIA PRIVACY RIGHTS (AS PROVIDED BY CALIFORNIA CIVIL CODE SECTION
        1798.83)
      </p>
      <br />
      <p>
        A CALIFORNIA RESIDENT WHO HAS PROVIDED PERSONAL DATA TO A BUSINESS WITH WHOM HE/SHE HAS ESTABLISHED A BUSINESS
        RELATIONSHIP FOR PERSONAL, FAMILY, OR HOUSEHOLD PURPOSES (A “CALIFORNIA CUSTOMER”) MAY REQUEST INFORMATION ABOUT
        WHETHER THE BUSINESS HAS DISCLOSED PERSONAL INFORMATION TO ANY THIRD PARTIES FOR THE THIRD PARTIES’ DIRECT
        MARKETING PURPOSES. IN GENERAL, IF THE BUSINESS HAS MADE SUCH A DISCLOSURE OF PERSONAL DATA, UPON RECEIPT OF A
        REQUEST BY A CALIFORNIA CUSTOMER, THE BUSINESS IS REQUIRED TO PROVIDE A LIST OF ALL THIRD PARTIES TO WHOM
        PERSONAL DATA WAS DISCLOSED IN THE PRECEDING CALENDAR YEAR, AS WELL AS A LIST OF THE CATEGORIES OF PERSONAL DATA
        THAT WERE DISCLOSED. CALIFORNIA CUSTOMERS MAY REQUEST FURTHER INFORMATION ABOUT OUR COMPLIANCE WITH THIS LAW BY
        E-MAILING privacy@playstarfall.com. PLEASE NOTE THAT WE ARE REQUIRED TO RESPOND TO ONE REQUEST PER CALIFORNIA
        CUSTOMER EACH YEAR AND WE ARE NOT REQUIRED TO RESPOND TO REQUESTS MADE BY MEANS OTHER THAN THROUGH THIS E-MAIL
        ADDRESS.
      </p>
      <h3>International Data Transfers</h3>
      <p>
        Starfall Arena does not currently restrict the Service to certain jurisdictions. We may transfer personal
        information to countries other than the country in which the data was originally collected. Personal information
        may be stored on servers located in the United States or Canada and accessed from jurisdictions in which we or
        our service providers are located. These countries may not have the same data protection laws as the country in
        which you initially provided the information. When we transfer your personal information to other countries, we
        will protect that information as described in this Privacy Policy.
      </p>
      <h3>Changes to This Policy</h3>
      <p>
        Starfall Arena reserves the right to make changes to this Privacy Policy. You should review our Privacy Policy
        frequently. If we make material changes to our Privacy Policy, our revised Privacy Policy will be posted at
        www.fod.games/privacy, and it will either be noted on our Sites that material changes have been made or we will
        notify our users by email. The date of the most recent update to our Privacy Policy will be set forth in the
        header to the Privacy Policy.
      </p>
      <br />
      <p>
        How to Contact Us About This Policy If you have questions or concerns regarding this Privacy Policy or the
        manner in which we or our service providers treat your personal information, or if you have a complaint, you
        should first contact us by emailing our Privacy Officer at privacy@playstarfall.com.
      </p>
      <br />
      <p>
        If you would like to submit a complaint about our use of your personal information or response to your requests
        regarding your personal information, you may contact us at privacy@playstarfall.com or submit a complaint to the
        data protection regulator in your jurisdiction.
      </p>
    </div>
  );
};
