import Photo1 from 'src/assets/photos/photo_1.jpg';
import Photo2 from 'src/assets/photos/photo_2.jpg';
import Photo3 from 'src/assets/photos/photo_3.jpg';
import Photo5 from 'src/assets/photos/photo_5.jpg';
// import Photo6 from 'src/assets/photos/photo_6.jpg';
import Photo7 from 'src/assets/photos/photo_7.jpg';
import Photo8 from 'src/assets/photos/photo_8.jpg';
import Photo9 from 'src/assets/photos/photo_9.jpg';
import Photo11 from 'src/assets/photos/photo_11.jpg';
import Photo12 from 'src/assets/photos/photo_12.jpg';
import Photo13 from 'src/assets/photos/photo_13.jpg';
import Photo15 from 'src/assets/photos/photo_15.jpg';
import Photo16 from 'src/assets/photos/photo_16.png';
import Photo17 from 'src/assets/photos/photo_17.png';
import Photo18 from 'src/assets/photos/photo_18.png';
import Photo19 from 'src/assets/photos/photo_19.png';
import Photo20 from 'src/assets/photos/photo_20.png';
import Photo21 from 'src/assets/photos/photo_21.png';
import Photo22 from 'src/assets/photos/photo_22.png';
// import Photo23 from 'src/assets/photos/photo_23.png';
import Photo24 from 'src/assets/photos/photo_24.png';
import Photo25 from 'src/assets/photos/photo_25.png';
import Photo26 from 'src/assets/photos/photo_26.png';
import Photo27 from 'src/assets/photos/photo_27.jpg';
import Photo28 from 'src/assets/photos/photo_28.jpg';
import Photo29 from 'src/assets/photos/photo_29.jpg';
import Photo30 from 'src/assets/photos/photo_30.jpg';

import Photo1Small from 'src/assets/photos/photo_small_1.jpg';
import Photo11Small from 'src/assets/photos/photo_small_11.jpg';
import Photo12Small from 'src/assets/photos/photo_small_12.jpg';
import Photo13Small from 'src/assets/photos/photo_small_13.jpg';
import Photo15Small from 'src/assets/photos/photo_small_15.jpg';
import Photo2Small from 'src/assets/photos/photo_small_2.jpg';
import Photo3Small from 'src/assets/photos/photo_small_3.jpg';
import Photo5Small from 'src/assets/photos/photo_small_5.jpg';
// import Photo6Small from 'src/assets/photos/photo_small_6.jpg';
import Photo7Small from 'src/assets/photos/photo_small_7.jpg';
import Photo8Small from 'src/assets/photos/photo_small_8.jpg';
import Photo9Small from 'src/assets/photos/photo_small_9.jpg';
import Video3 from 'src/assets/photos/video_3.mp4';
import Video11 from 'src/assets/photos/video_11.mp4';
import Video15 from 'src/assets/photos/video_15.mp4';

import AutoVideo1 from 'src/assets/photos/video_2.mp4';
import AutoVideo2 from 'src/assets/photos/video_4.mp4';
import AutoVideo3 from 'src/assets/photos/video_6.mp4';
import AutoVideo4 from 'src/assets/photos/video_8.mp4';
import AutoVideo5 from 'src/assets/photos/video_12.mp4';
import AutoVideo6 from 'src/assets/photos/video_13.mp4';

export type AutoplayVideo = Omit<SlideEntry, 'image'>;
export interface SlideEntry {
  image?: string;
  video?: string;
}

const desktop = window.innerWidth >= 500;

export const SLIDE_IMAGES: SlideEntry[] = [
  {
    image: desktop ? Photo1 : Photo1Small
  },
  {
    image: desktop ? Photo2 : Photo2Small
  },
  {
    image: desktop ? Photo3 : Photo3Small,
    video: Video3
  },
  // {
  //   image: desktop ? Photo4 : Photo4Small
  // },
  {
    image: desktop ? Photo5 : Photo5Small
  },
  // {
  //   image: desktop ? Photo6 : Photo6Small
  // },
  {
    image: desktop ? Photo7 : Photo7Small
  },
  {
    image: desktop ? Photo8 : Photo8Small
  },
  {
    image: desktop ? Photo9 : Photo9Small
  },
  // {
  //   image: desktop ? Photo10 : Photo10Small
  // },
  {
    image: desktop ? Photo11 : Photo11Small,
    video: Video11
  },
  {
    image: desktop ? Photo12 : Photo12Small
  },
  {
    image: desktop ? Photo13 : Photo13Small
  },
  // {
  //   image: desktop ? Photo14 : Photo14Small
  // },
  {
    image: desktop ? Photo15 : Photo15Small,
    video: Video15
  },
  {
    image: Photo16
  },
  {
    image: Photo17
  },
  {
    image: Photo18
  },
  {
    image: Photo19
  },
  {
    image: Photo20
  },
  {
    image: Photo21
  },
  {
    image: Photo22
  },
  // {
  //   image: Photo23
  // },
  {
    image: Photo24
  },
  {
    image: Photo25
  },
  {
    image: Photo26
  },
  {
    image: Photo27
  },
  {
    image: Photo28
  },
  {
    image: Photo29
  },
  {
    image: Photo30
  }
];

export const AUTOPLAY_VIDEOS: AutoplayVideo[] = [
  {
    video: AutoVideo1
  },
  {
    video: AutoVideo2
  },
  {
    video: AutoVideo3
  },
  {
    video: AutoVideo4
  },
  {
    video: AutoVideo5
  },
  {
    video: AutoVideo6
  }
];
