import { MathUtils } from 'three';

export function easeInOutQuad(x: number): number {
  return x < 0.5 ? 2 * x * x : 1 - Math.pow(-2 * x + 2, 2) / 2;
}

export function easeOutCubic(x: number): number {
  return 1 - Math.pow(1 - x, 3);
}

export function timeSlice(t: number, start: number, end: number) {
  return MathUtils.clamp((t - start) / (end - start), 0.0, 1.0);
}

export function framedSlice(t: number, offset: number, frame: number) {
  const gap = (1.0 - frame) * offset;
  return timeSlice(t, gap, gap + frame);
}
